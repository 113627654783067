import types from './types';


export const getDataExportStatusNxcalsAction = () => ({
  type: types.GET_DATA_EXPORT_NXCALS,
  params: {}

});


export const getDataExportStatusInfluxAction = () => ({
  type: types.GET_DATA_EXPORT_INFLUX,
  params: {}

});

export const getDataExportStatusByTypeAction = (dataTypeName, dataExportTarget) => ({
  type: types.GET_DATA_BY_TYPE,
  params: {dataTypeName,dataExportTarget}

});

export default {
  getDataExportStatusNxcalsAction,
  getDataExportStatusInfluxAction,
  getDataExportStatusByTypeAction,
};
