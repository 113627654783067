import { takeQuery } from 'react-lib/dist/agent-es'
import Connector from '../../api/agent';
import types from './types';
import {NotificationList} from '../../routes';

const { getAPI, getCurrentPlatform } = Connector;

export const NotificationsAPI = {
  searchNotifications: ({ showAllNotifications }) =>
    getAPI().get(`/notifications${showAllNotifications ? '/admin' : ''}`),
  getNotificationsFilters: () => getAPI().get(`/notifications/sources/filters`, false, {}),

  searchNotificationById: ({ id }) => getAPI().get(`/notifications/${id}`, false),
  searchNotificationsByIds: ({ ids }) => getAPI().get(`/notifications/byIds/${ids}`, false),

  getNotificationSources: ({ filters, pagination }) =>
    getAPI().post(`/notifications/sources`, filters, false, pagination),
  deleteNotification: ({ notificationId }) =>
    getAPI().delete(`/notifications/${notificationId}`, false),
  updateNotificationEnabled: ({ notificationId, enable }) =>
    getAPI().patch(`/notifications/${notificationId}/enable`, null, false, {
      enable,
    }),
  updateNotificationById: ({ notification }) =>
    getAPI().patch(`/notifications`, notification, false, false),
  createNotification: ({ notification }) => getAPI().put(`/notifications`, notification),
};

const notificationsSagas = [
  takeQuery(types.GET_NOTIFICATIONS, {
    api: NotificationsAPI.searchNotifications,
  }),
  takeQuery(types.NOTIFICATION_BY_ID, {
    api: NotificationsAPI.searchNotificationById,
    notifyFailure: [
      ({ request: { params } }) => {
        // See comment below
        window.location = NotificationList.getLink({ platform: params.platform })
      }
    ],
  }),

  takeQuery(types.NOTIFICATIONS_BY_IDS, {
    api: NotificationsAPI.searchNotificationsByIds,
  }),

  takeQuery(types.DELETE_NOTIFICATION, {
    api: NotificationsAPI.deleteNotification,
  }),
  takeQuery(types.UPDATE_NOTIFICATION_ENABLE, {
    api: NotificationsAPI.updateNotificationEnabled,
  }),
  takeQuery(types.UPDATE_NOTIFICATION_BY_ID, {
    api: NotificationsAPI.updateNotificationById,
    notify: [
      ({ request: { params }, data }) => {
        const { notification } = params;
        const platform = getCurrentPlatform();
        // See comment below
        window.location =
          NotificationList.getLink({
            platform,
          })
      },
    ],
  }),
  takeQuery(types.CREATE_NOTIFICATION, {
    api: NotificationsAPI.createNotification,
    notify: [
      ({ request: { params }, data }) => {
        const { notification } = params;
        const platform = getCurrentPlatform();
        // See comment below
        window.location =
          NotificationList.getLink({
            platform,
          })
      },
    ],
  }),
];

/*
* TODO: better redirecting
* The project used https://github.com/supasate/connected-react-router/issues
* to manage the router in the redux state, but the project is dead. As we upgraded
* to react router v6.22 and with v6.4 in the making, we had to remove this dependency.
* Currently the router is therefore not managed in the state. This means, that
* above we cannot dispatch/trigger a route change, as we are not in the context
* of the router. Followup projects from connected-react-router such as
* https://github.com/salvoravida/redux-first-history have currently also no
* support for react-router v6.22. (https://github.com/salvoravida/redux-first-history/issues/95)
* There are smaller projects such as https://github.com/cha0s/redux-data-router
* that implement the functionality, but how well they are maintained has to be
* seen. Until we know more, window.location is used, but this triggers a
* whole page reload, which is not favorable.
*/

export default notificationsSagas;
