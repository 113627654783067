export const DEFAULT_FILTER_PARAMS = {
  deviceTypeId: null,
  channelId: null,
  channelName: null,
  msId: null,
  msName: null,
  zones: [],
  usages: [],
};

export const ParametersWidgetTabs = {
  RESULTS: 0,
  AGGREGATIONS: 1,
};
