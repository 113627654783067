import types from './types';

export const searchNotifications = (showAllNotifications, page) => ({
  type: types.GET_NOTIFICATIONS,
  params: { showAllNotifications, page },
});

export const searchNotificationById = id => ({
  type: types.NOTIFICATION_BY_ID,
  params: { id },
});

export const searchNotificationsByIds = ids => ({
  type: types.NOTIFICATIONS_BY_IDS,
  params: { ids },
});

export const deleteNotification = notificationId => ({
  type: types.DELETE_NOTIFICATION,
  params: { notificationId },
});

export const updateNotificationEnable = (notificationId, enable) => ({
  type: types.UPDATE_NOTIFICATION_ENABLE,
  params: { notificationId, enable },
});

export const updateNotification = notification => ({
  type: types.UPDATE_NOTIFICATION_BY_ID,
  params: { notification },
});

export const createNotification = notification => ({
  type: types.CREATE_NOTIFICATION,
  params: { notification },
});

export const setSelectedNotification = selectedNotification => ({
  type: types.SET_SELECTED_NOTIFICATION,
  selectedNotification,
});

export default {
  searchNotifications,
  searchNotificationById,
  searchNotificationsByIds,
  deleteNotification,
  updateNotificationEnable,
  updateNotification,
  createNotification,
  setSelectedNotification,
};
