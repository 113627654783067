export const APPLICATION_NODE_TYPES = {
  DOCUMENT: '#document',
  MAP: 'map',
  SYNOPTIC: 'synoptic',
  EQUIPMENT: 'equipment',
  LABEL: 'label',
  LINE: 'line',
  BUTTON: 'button',
  ALARM: 'alarmSummary',
};

export const CLIPBOARD_MODE = {
  COPY: 'COPY',
  CUT: 'CUT',
};
