export const GET_NXCALS_BY_TYPE_SOURCES =
    'app/nxcalsManagement/GET_NXCALS_BY_TYPE_SOURCES';
export const GET_NXCALS_BY_TYPE_SOURCES_SUCCESS = `${GET_NXCALS_BY_TYPE_SOURCES}/SUCCESS`;
export const GET_NXCALS_BY_TYPE_SOURCES_FAILURE = `${GET_NXCALS_BY_TYPE_SOURCES}/FAILURE`;

export const UPDATE_NXCALS_BY_TYPE_BY_ID =
    'app/nxcalsManagement/UPDATE_NXCALS_BY_TYPE_BY_ID';
export const UPDATE_NXCALS_BY_TYPE_BY_ID_SUCCESS = `${UPDATE_NXCALS_BY_TYPE_BY_ID}/SUCCESS`;
export const UPDATE_NXCALS_BY_TYPE_BY_ID_FAILURE = `${UPDATE_NXCALS_BY_TYPE_BY_ID}/FAILURE`;

export const DELETE_NXCALS_BY_TYPE = 'app/nxcalsManagement/DELETE_NXCALS_BY_TYPE';
export const DELETE_NXCALS_BY_TYPE_SUCCESS = `${DELETE_NXCALS_BY_TYPE}/SUCCESS`;
export const DELETE_NXCALS_BY_TYPE_FAILURE = `${DELETE_NXCALS_BY_TYPE}/FAILURE`;

export const CREATE_NXCALS_BY_TYPE = 'app/nxcalsManagement/CREATE_NXCALS_BY_TYPE';
export const CREATE_NXCALS_BY_TYPE_SUCCESS = `${CREATE_NXCALS_BY_TYPE}/SUCCESS`;
export const CREATE_NXCALS_BY_TYPE_FAILURE = `${CREATE_NXCALS_BY_TYPE}/FAILURE`;


export default {
  GET_NXCALS_BY_TYPE_SOURCES,
  GET_NXCALS_BY_TYPE_SOURCES_SUCCESS,
  GET_NXCALS_BY_TYPE_SOURCES_FAILURE,

  UPDATE_NXCALS_BY_TYPE_BY_ID,
  UPDATE_NXCALS_BY_TYPE_BY_ID_SUCCESS,
  UPDATE_NXCALS_BY_TYPE_BY_ID_FAILURE,

  DELETE_NXCALS_BY_TYPE,
  DELETE_NXCALS_BY_TYPE_SUCCESS,
  DELETE_NXCALS_BY_TYPE_FAILURE,

  CREATE_NXCALS_BY_TYPE,
  CREATE_NXCALS_BY_TYPE_SUCCESS,
  CREATE_NXCALS_BY_TYPE_FAILURE
};
