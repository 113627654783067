import types from './types';

export const updateForm = (field, value) => ({
  type: types.UPDATE_FORM,
  field,
  value,
});

export const setSelectedMMS = selectedMMS => ({
  type: types.SET_SELECTED_MMS,
  selectedMMS,
});

export const sendForm = () => ({
  type: types.SEND_FORM,
});

export default {
  updateForm,
  setSelectedMMS,
  sendForm,
};
