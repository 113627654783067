import {v4 as uuidv4} from 'uuid';
import {
  numericFields
} from '../../components/ApplicationEditor/RemusApplication/PropertiesPanel/PropertiesTable/MultipleWidgetsProperties/constants';
import {
  isTargetAndNull
} from '../../components/ApplicationEditor/RemusApplication/PropertiesPanel/PropertiesTable/NumericPropertiesTableRow';
import {
  defaultCanvasSize,
  defaultLineEnd,
  defaultWidgetsPosition
} from './constants';
import {SYNOPTIC_IMAGE_PREFIX} from '../../constants';

//{244,21,5} to #f41505
function colorTransformationFromRGBToHex(color) {
  const rgbToHex = (r, g, b) => `#${[r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')}`;
  const noCurlyBracesColor = color.replace(/{|}/g, '');
  if (
    noCurlyBracesColor.split(',').length !== 3 ||
    isNaN(noCurlyBracesColor.split(',')[0]) ||
    isNaN(noCurlyBracesColor.split(',')[1]) ||
    isNaN(noCurlyBracesColor.split(',')[2]) ||
    noCurlyBracesColor.split(',')[0] < 0 ||
    noCurlyBracesColor.split(',')[0] > 255 ||
    noCurlyBracesColor.split(',')[1] < 0 ||
    noCurlyBracesColor.split(',')[1] > 255 ||
    noCurlyBracesColor.split(',')[2] < 0 ||
    noCurlyBracesColor.split(',')[2] > 255
  ) {
    return null;
  }
  const rgbColor = rgbToHex(
    Number(noCurlyBracesColor.split(',')[0]),
    Number(noCurlyBracesColor.split(',')[1]),
    Number(noCurlyBracesColor.split(',')[2])
  );
  return rgbColor;
}

//#f41505 to {244,21,5}
export function colorTransformationFromHexToRGB(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex?.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result
    ? `{${`${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}`}}`
    : null;
}

function checkValueIsANumber(value, constantField, constantAttribute) {
  return isNaN(Number(value)) || value === undefined || value === null
    ? constantField[constantAttribute]
    : Number(value);
}

export function defineBooleanValue(value) {
  if (value === false || value === 'false' || !value) {
    return false;
  }
  return true;
}

function containsSynoptics(synopticImage) {
  return synopticImage.includes(SYNOPTIC_IMAGE_PREFIX);
}

// eslint-disable-next-line
const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});

export function xmlToJson(node, parent) {
  if (!node) {
    return null;
  }
  return {
    id: uuidv4(),
    type: node.nodeName,
    parent,
    node,
    ...(node.children
      ? groupBy(
          Array.from(node.children)?.map(c => xmlToJson(c, node)),
          el => `${el.type}s`
        )
      : {}),
    ...(node.attributes
      ? Object.values(node.attributes)
          .map(attribute => {
            if (
              attribute.name === 'disableSummaryAlarm' ||
              attribute.name === 'disableSummaryFault' ||
              attribute.name === 'realTimeTrend' ||
              attribute.name === 'recursive'
            ) {
              attribute.value = attribute.value === 'true' || attribute.value === true;
            }

            if (attribute.name === 'posX' || attribute.name === 'posY') {
              attribute.value = checkValueIsANumber(
                attribute.value,
                defaultWidgetsPosition,
                attribute.name
              );
            }

            if (attribute.name === 'sizeX' || attribute.name === 'sizeY') {
              attribute.value = checkValueIsANumber(
                attribute.value,
                defaultCanvasSize,
                attribute.name
              );
            }

            if (attribute.name === 'color') {
              attribute.value = colorTransformationFromRGBToHex(attribute.value) || '';
            }
            if (numericFields.includes(attribute.name)) {
              return [
                attribute.name,
                isTargetAndNull(attribute.name, attribute.value)
                  ? null
                  : Math.round(Number(attribute.value)),
              ];
            }
            return [attribute.name, attribute.value];
          })
          .reduce((acc, current) => ({ ...acc, [current[0]]: current[1] }), {})
      : {}),
  };
}

export function jsonToXml(json, xmlDoc) {
  let node;

  if (!json) {
    return null;
  }
  switch (json.type) {
    case '#document': {
      node = document.implementation.createDocument('', '', null);
      const xmlInstruction = node.createProcessingInstruction(
        'xml',
        'version="1.0"',
        'encoding="UTF-8"'
      );

      node.appendChild(xmlInstruction);
      xmlDoc = node;
      break;
    }
    case 'map':
      node = xmlDoc.createElement('map');
      break;
    case 'synoptic':
      node = xmlDoc.createElement('synoptic');

      node.setAttribute('sizeX', checkValueIsANumber(json.sizeX, defaultCanvasSize, 'sizeX'));
      node.setAttribute('sizeY', checkValueIsANumber(json.sizeY, defaultCanvasSize, 'sizeY'));
      node.setAttribute('name', json?.name);
      node.setAttribute('posX', checkValueIsANumber(json?.posX, defaultWidgetsPosition, 'posX'));
      node.setAttribute('posY', checkValueIsANumber(json?.posY, defaultWidgetsPosition, 'posY'));

      if (json?.img) {
        node.setAttribute(
          'img',
          containsSynoptics(json?.img) ? json?.img : `${SYNOPTIC_IMAGE_PREFIX}${json?.img}`
        );
      }

      if (json?.color && colorTransformationFromHexToRGB(json?.color)) {
        node.setAttribute('color', colorTransformationFromHexToRGB(json?.color));
      }

      break;

    case 'button':
      node = xmlDoc.createElement('button');
      node.setAttribute('posX', checkValueIsANumber(json?.posX, defaultWidgetsPosition, 'posX'));
      node.setAttribute('posY', checkValueIsANumber(json?.posY, defaultWidgetsPosition, 'posY'));
      node.setAttribute('category', json?.category);

      if (json?.realTimeTrend === true || json?.realTimeTrend === 'true') {
        node.setAttribute('realTimeTrend', json?.realTimeTrend);
      }
      if (json?.recursive === true || json?.recursive === 'true') {
        node.setAttribute('recursive', json?.recursive);
      }

      break;

    case 'equipment':
      node = xmlDoc.createElement('equipment');
      node.setAttribute('equipmentId', json?.equipmentId || 0);
      node.setAttribute('posX', Math.round(Number(json?.posX)) || defaultWidgetsPosition.posX);
      node.setAttribute('posY', Math.round(Number(json?.posY)) || defaultWidgetsPosition.posY);

      if (json?.targetX !== null && json?.targetX !== undefined) {
        node.setAttribute('targetX', Number(json?.targetX));
      }

      if (json?.targetY !== null && json?.targetY !== undefined) {
        node.setAttribute('targetY', Number(json?.targetY));
      }
      node.setAttribute('category', json?.category);
      if (json?.version && json.version !== '' && json.version !== 'none') {
        node.setAttribute('version', json?.version);
      }
      if (json?.restriction && json.restriction !== 'none' && json.restriction !== '') {
        node.setAttribute('restriction', json?.restriction);
      }

      if (json?.info) {
        node.setAttribute('info', json?.info ?? '');
      }
      if (json?.valueFormat) {
        node.setAttribute('valueFormat', json?.valueFormat ?? '');
      }
      if (json?.freeText) {
        node.setAttribute('freeText', json?.freeText ?? '');
      }

      if (json?.realTimeTrend === true || json?.realTimeTrend === 'true') {
        node.setAttribute('realTimeTrend', json?.realTimeTrend ?? false);
      }
      if (json?.disableSummaryFault === true || json?.disableSummaryFault === 'true') {
        node.setAttribute('disableSummaryFault', json?.disableSummaryFault ?? false);
      }
      if (json?.disableSummaryAlarm === true || json?.disableSummaryAlarm === 'true') {
        node.setAttribute('disableSummaryAlarm', json?.disableSummaryAlarm ?? false);
      }

      break;

    case 'label':
      node = xmlDoc.createElement('label');

      node.setAttribute(
        'posX',
        checkValueIsANumber(Number(json?.posX), defaultWidgetsPosition.posX, 'posX')
      );
      node.setAttribute(
        'posY',
        checkValueIsANumber(Number(json?.posY), defaultWidgetsPosition.posY, 'posY')
      );

      node.setAttribute('name', json?.name);
      if (json?.fontSize) {
        node.setAttribute('fontSize', json?.fontSize ?? 12);
      }
      if (json?.fontStyle && json.fontStyle !== 'none') {
        node.setAttribute('fontStyle', json?.fontStyle);
      }

      if (json?.href) {
        node.setAttribute('href', json?.href);
      }

      break;

    case 'line':
      node = xmlDoc.createElement('line');
      node.setAttribute(
        'startX',
        checkValueIsANumber(json?.startX, defaultWidgetsPosition, 'posX')
      );
      node.setAttribute(
        'startY',
        checkValueIsANumber(json?.startY, defaultWidgetsPosition, 'posY')
      );

      node.setAttribute(
        'endX',
        Math.round(Number(checkValueIsANumber(json?.endX, defaultLineEnd, 'endX')))
      );
      node.setAttribute(
        'endY',
        Math.round(Number(checkValueIsANumber(json?.endY, defaultLineEnd, 'endY')))
      );

      break;
    case 'alarmSummary':
      node = xmlDoc.createElement('alarmSummary');
      node.setAttribute('posX', checkValueIsANumber(json?.posX, defaultWidgetsPosition, 'posX'));
      node.setAttribute('posY', checkValueIsANumber(json?.posY, defaultWidgetsPosition, 'posY'));

      break;

    default:
      return null;
  }

  if (json.synoptics) {
    const synopticNodes = json.synoptics.map(s => jsonToXml(s, xmlDoc));
    synopticNodes.forEach(s => {
      node.appendChild(s);
    });
  }

  if (json.buttons) {
    const buttonNodes = json.buttons.map(s => jsonToXml(s, xmlDoc));
    buttonNodes.forEach(s => node.appendChild(s));
  }

  if (json.labels) {
    const labelNodes = json.labels.map(s => jsonToXml(s, xmlDoc));
    labelNodes.forEach(s => node.appendChild(s));
  }
  if (json.equipments) {
    const equipmentNodes = json.equipments.map(s => jsonToXml(s, xmlDoc));
    equipmentNodes.forEach(s => {
      node.appendChild(s, xmlDoc);
    });
  }

  if (json.lines) {
    const lineNodes = json.lines.map(s => jsonToXml(s, xmlDoc));
    lineNodes.forEach(s => node.appendChild(s));
  }

  if (json.alarmSummarys) {
    const alarmNodes = json.alarmSummarys.map(s => jsonToXml(s, xmlDoc));
    alarmNodes.forEach(s => node.appendChild(s));
  }

  if (json.maps) {
    const mapNodes = json.maps.map(s => jsonToXml(s, xmlDoc));
    mapNodes.forEach(s => node?.appendChild(s));
  }

  return node;
}
