import moment from 'moment';
import HighCharts, { YAxisOptions } from 'highcharts';
import { DataResolution } from '../../behavior/trends/types/DataResolution';
import { DataSource } from '../../behavior/trends/types/DataSource';
import { periodTypes } from '../../behavior/trends/types/Period';
import { TrendsRequest } from '../../behavior/trends/types/TrendsRequest';

export const colorsArray = [
  '#A35014',
  '#42AAF5',
  '#03BA1C',
  '#F62C52',
  '#1C4D45',
  '#CBC104',
  '#5FDCDA',
  '#F86EB0',
  '#6E4DA8',
  '#F6AA95',
  '#34C57E',
  '#722152',
  '#2C540D',
  '#B3C86D',
  '#E0A1F8',
  '#633022',
  '#7C754C',
  '#FDA559',
  '#303366',
  '#E5BDD0',
  '#D93A14',
  '#7F8914',
  '#9ADC2C',
  '#CA1461',
  '#99C6DC',
  '#FB7266',
  '#87C596',
  '#2F8843',
  '#6E5B02',
  '#3B3316',
  '#3976B4',
  '#B32335',
  '#973893',
  '#FB8B98',
  '#76132B',
  '#663903',
  '#12BBEB',
  '#C49F15',
  '#3BA025',
  '#A8256B',
  '#35ACBF',
  '#E16301',
  '#206A41',
  '#DBAD82',
  '#3B3D03',
  '#C4C652',
  '#C2A5E9',
  '#F586AF',
  '#B06805',
  '#5C5BA6',
  '#F2C43C',
  '#5C265D',
  '#C80045',
  '#769A1A',
  '#2A4311',
  '#78CFFA',
  '#218856',
  '#6DC7D0',
  '#F3935D',
  '#0B7FCC',
  '#F47959',
  '#1EAB38',
  '#FC6F73',
  '#F8B161', //64, now repeat (for Fabrice!)
  '#A35014',
  '#42AAF5',
  '#03BA1C',
  '#F62C52',
  '#1C4D45',
  '#CBC104',
  '#5FDCDA',
  '#F86EB0',
  '#6E4DA8',
  '#F6AA95',
  '#34C57E',
  '#722152',
  '#2C540D',
  '#B3C86D',
  '#E0A1F8',
  '#633022',
  '#7C754C',
  '#FDA559',
  '#303366',
  '#E5BDD0',
  '#D93A14',
  '#7F8914',
  '#9ADC2C',
  '#CA1461',
  '#99C6DC',
  '#FB7266',
  '#87C596',
  '#2F8843',
  '#6E5B02',
  '#3B3316',
  '#3976B4',
  '#B32335',
  '#973893',
  '#FB8B98',
  '#76132B',
  '#663903',
  '#12BBEB',
  '#C49F15',
  '#3BA025',
  '#A8256B',
  '#35ACBF',
  '#E16301',
  '#206A41',
  '#DBAD82',
  '#3B3D03',
  '#C4C652',
  '#C2A5E9',
  '#F586AF',
  '#B06805',
  '#5C5BA6',
  '#F2C43C',
  '#5C265D',
  '#C80045',
  '#769A1A',
  '#2A4311',
  '#78CFFA',
  '#218856',
  '#6DC7D0',
  '#F3935D',
  '#0B7FCC',
  '#F47959',
  '#1EAB38',
  '#FC6F73',
  '#F8B161', //64, now repeat (for Fabrice!)
  '#A35014',
  '#42AAF5',
  '#03BA1C',
  '#F62C52',
  '#1C4D45',
  '#CBC104',
  '#5FDCDA',
  '#F86EB0',
  '#6E4DA8',
  '#F6AA95',
  '#34C57E',
  '#722152',
  '#2C540D',
  '#B3C86D',
  '#E0A1F8',
  '#633022',
  '#7C754C',
  '#FDA559',
  '#303366',
  '#E5BDD0',
  '#D93A14',
  '#7F8914',
  '#9ADC2C',
  '#CA1461',
  '#99C6DC',
  '#FB7266',
  '#87C596',
  '#2F8843',
  '#6E5B02',
  '#3B3316',
  '#3976B4',
  '#B32335',
  '#973893',
  '#FB8B98',
  '#76132B',
  '#663903',
  '#12BBEB',
  '#C49F15',
  '#3BA025',
  '#A8256B',
  '#35ACBF',
  '#E16301',
  '#206A41',
  '#DBAD82',
  '#3B3D03',
  '#C4C652',
  '#C2A5E9',
  '#F586AF',
  '#B06805',
  '#5C5BA6',
  '#F2C43C',
  '#5C265D',
  '#C80045',
  '#769A1A',
  '#2A4311',
  '#78CFFA',
  '#218856',
  '#6DC7D0',
  '#F3935D',
  '#0B7FCC',
  '#F47959',
  '#1EAB38',
  '#FC6F73',
  '#F8B161', //64, now repeat (for Fabrice!)
  '#A35014',
  '#42AAF5',
  '#03BA1C',
  '#F62C52',
  '#1C4D45',
  '#CBC104',
  '#5FDCDA',
  '#F86EB0',
  '#6E4DA8',
  '#F6AA95',
  '#34C57E',
  '#722152',
  '#2C540D',
  '#B3C86D',
  '#E0A1F8',
  '#633022',
  '#7C754C',
  '#FDA559',
  '#303366',
  '#E5BDD0',
  '#D93A14',
  '#7F8914',
  '#9ADC2C',
  '#CA1461',
  '#99C6DC',
  '#FB7266',
  '#87C596',
  '#2F8843',
  '#6E5B02',
  '#3B3316',
  '#3976B4',
  '#B32335',
  '#973893',
  '#FB8B98',
  '#76132B',
  '#663903',
  '#12BBEB',
  '#C49F15',
  '#3BA025',
  '#A8256B',
  '#35ACBF',
  '#E16301',
  '#206A41',
  '#DBAD82',
  '#3B3D03',
  '#C4C652',
  '#C2A5E9',
  '#F586AF',
  '#B06805',
  '#5C5BA6',
  '#F2C43C',
  '#5C265D',
  '#C80045',
  '#769A1A',
  '#2A4311',
  '#78CFFA',
  '#218856',
  '#6DC7D0',
  '#F3935D',
  '#0B7FCC',
  '#F47959',
  '#1EAB38',
  '#FC6F73',
  '#F8B161', //64, now repeat (for Fabrice!)
];

export const colorsArrayDark = [
  '#E4EC3A',
  '#DF4C4B',
  '#66E741',
  '#E38F26',
  '#67E4D4',
  '#A249E9',
  '#BE5E2D',
  '#76B3E9',
  '#87B637',
  '#E876CA',
  '#766EA8',
  '#E3BB56',
  '#5E71E0',
  '#B9E86B',
  '#E53AAC',
  '#60E9A8',
  '#D6D146',
  '#578BDF',
  '#E73F1F',
  '#E33E72',
  '#47B573',
  '#9966B6',
  '#B0B343',
  '#9A8EE7',
  '#4484B9',
  '#E86829',
  '#4CBBD3',
  '#DE9EE2',
  '#6C882B',
  '#CBBAF2',
  '#90801C',
  '#A496CD',
  '#96D682',
  '#AC6698',
  '#CFEC96',
  '#C16077',
  '#408651',
  '#E892B4',
  '#42A28C',
  '#E39155',
  '#A7DDAF',
  '#B46351',
  '#92B170',
  '#E6896D',
  '#67804D',
  '#DF978C',
  '#E6DD91',
  '#A96E24',
  '#E7BD86',
  '#91723B',
  '#8E8E4A',
  '#BA995F',
  '#B74EB5',
  '#489632',
  '#A5DF32',
  '#DC49EB',
  '#72E972',
  '#CC41CC',
  '#4BBB3B',
  '#7E60E8',
  '#EABB29',
  '#BE73E3',
  '#BF9330',
  '#C74A92', //64, now repeat (for Fabrice!)
  '#E4EC3A',
  '#DF4C4B',
  '#66E741',
  '#E38F26',
  '#67E4D4',
  '#A249E9',
  '#BE5E2D',
  '#76B3E9',
  '#87B637',
  '#E876CA',
  '#766EA8',
  '#E3BB56',
  '#5E71E0',
  '#B9E86B',
  '#E53AAC',
  '#60E9A8',
  '#D6D146',
  '#578BDF',
  '#E73F1F',
  '#E33E72',
  '#47B573',
  '#9966B6',
  '#B0B343',
  '#9A8EE7',
  '#4484B9',
  '#E86829',
  '#4CBBD3',
  '#DE9EE2',
  '#6C882B',
  '#CBBAF2',
  '#90801C',
  '#A496CD',
  '#96D682',
  '#AC6698',
  '#CFEC96',
  '#C16077',
  '#408651',
  '#E892B4',
  '#42A28C',
  '#E39155',
  '#A7DDAF',
  '#B46351',
  '#92B170',
  '#E6896D',
  '#67804D',
  '#DF978C',
  '#E6DD91',
  '#A96E24',
  '#E7BD86',
  '#91723B',
  '#8E8E4A',
  '#BA995F',
  '#B74EB5',
  '#489632',
  '#A5DF32',
  '#DC49EB',
  '#72E972',
  '#CC41CC',
  '#4BBB3B',
  '#7E60E8',
  '#EABB29',
  '#BE73E3',
  '#BF9330',
  '#C74A92', //64, now repeat (for Fabrice!)
  '#E4EC3A',
  '#DF4C4B',
  '#66E741',
  '#E38F26',
  '#67E4D4',
  '#A249E9',
  '#BE5E2D',
  '#76B3E9',
  '#87B637',
  '#E876CA',
  '#766EA8',
  '#E3BB56',
  '#5E71E0',
  '#B9E86B',
  '#E53AAC',
  '#60E9A8',
  '#D6D146',
  '#578BDF',
  '#E73F1F',
  '#E33E72',
  '#47B573',
  '#9966B6',
  '#B0B343',
  '#9A8EE7',
  '#4484B9',
  '#E86829',
  '#4CBBD3',
  '#DE9EE2',
  '#6C882B',
  '#CBBAF2',
  '#90801C',
  '#A496CD',
  '#96D682',
  '#AC6698',
  '#CFEC96',
  '#C16077',
  '#408651',
  '#E892B4',
  '#42A28C',
  '#E39155',
  '#A7DDAF',
  '#B46351',
  '#92B170',
  '#E6896D',
  '#67804D',
  '#DF978C',
  '#E6DD91',
  '#A96E24',
  '#E7BD86',
  '#91723B',
  '#8E8E4A',
  '#BA995F',
  '#B74EB5',
  '#489632',
  '#A5DF32',
  '#DC49EB',
  '#72E972',
  '#CC41CC',
  '#4BBB3B',
  '#7E60E8',
  '#EABB29',
  '#BE73E3',
  '#BF9330',
  '#C74A92', //64, now repeat (for Fabrice!)
  '#E4EC3A',
  '#DF4C4B',
  '#66E741',
  '#E38F26',
  '#67E4D4',
  '#A249E9',
  '#BE5E2D',
  '#76B3E9',
  '#87B637',
  '#E876CA',
  '#766EA8',
  '#E3BB56',
  '#5E71E0',
  '#B9E86B',
  '#E53AAC',
  '#60E9A8',
  '#D6D146',
  '#578BDF',
  '#E73F1F',
  '#E33E72',
  '#47B573',
  '#9966B6',
  '#B0B343',
  '#9A8EE7',
  '#4484B9',
  '#E86829',
  '#4CBBD3',
  '#DE9EE2',
  '#6C882B',
  '#CBBAF2',
  '#90801C',
  '#A496CD',
  '#96D682',
  '#AC6698',
  '#CFEC96',
  '#C16077',
  '#408651',
  '#E892B4',
  '#42A28C',
  '#E39155',
  '#A7DDAF',
  '#B46351',
  '#92B170',
  '#E6896D',
  '#67804D',
  '#DF978C',
  '#E6DD91',
  '#A96E24',
  '#E7BD86',
  '#91723B',
  '#8E8E4A',
  '#BA995F',
  '#B74EB5',
  '#489632',
  '#A5DF32',
  '#DC49EB',
  '#72E972',
  '#CC41CC',
  '#4BBB3B',
  '#7E60E8',
  '#EABB29',
  '#BE73E3',
  '#BF9330',
  '#C74A92', //64, now repeat (for Fabrice!)
];

export const DATE_PERIOD_MODE = 1;
export const LAST_PERIOD_MODE = 2;

export const DEFAULT_FORM_VALUES: TrendsRequest = {
  dateBegin: moment().subtract(15, 'minutes'),
  dateEnd: moment(),
  realTime: true,
  dataResolution: DataResolution.AUTO,
  periodNumber: 15,
  periodType: periodTypes.MINUTE,
  dateMode: LAST_PERIOD_MODE,
  dataSource: DataSource.ORACLE,
  workingModes: [],
  alarmStatuses: [],
  faultStatuses: [],
  updated: false,
};

export const DEFAULT_CHART_OPTIONS: HighCharts.Options = {
  yAxis: [
    {
      logarithmic: false,
    } as YAxisOptions,
    {
      logarithmic: false,
    } as YAxisOptions,
  ],
  annotations: [],
  chart: {
    zooming: {
      type: 'xy',
    },
  },
};
