import { v4 as uuidv4 } from 'uuid';
import { periodTypes } from '../../components/Common/RecentDataDatePicker/constants';
import calculateWidgetSize from '../../components/Dashboards/Dashboard/DashboardContext/hooks/widgetSizeCalculator';
import { DashboardPermissions, DashboardRoles } from './dashboardPermissions';
import types from './types';

export const DEFAULT_DASHBOARD = {
  id: undefined,
  name: '',
  editing: true,
  ispublic: false,
  lastModifiedDate: undefined,
  widgets: [],
  dirty: false,
  permissions: {},
  refreshDashboard: false,
  refreshPeriod: 10,
  refreshPeriodType: periodTypes.MINUTE,
};

const defaultState = {
  dashboard: DEFAULT_DASHBOARD,
  widgetEditor: {
    editedWidget: undefined,
    isNew: false,
  },
  widgetCatalogVisible: true,
  accessRightsModalOpen: false,
};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case types.CHANGE_DASHBOARD_NAME: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          name: action.name,
        },
      };
    }
    case types.UPDATE_LAYOUT: {
      const { widgets } = state.dashboard;
      const { layout } = action;
      const updatedWidgets = widgets.map(w => {
        const widgetLayout = layout.find(p => p.i === w.uuid) ?? {};
        return {
          ...w,
          w: widgetLayout.w ?? w.w,
          h: widgetLayout.h ?? w.h,
          x: widgetLayout.x ?? w.x,
          y: widgetLayout.y ?? w.y,
        };
      });

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: updatedWidgets,
          dirty: true,
        },
      };
    }
    case types.WIDGET_STATE_CHANGED: {
      const { widgetId, widgetState } = action;
      const { widgets } = state.dashboard;
      const updatedWidgets = widgets.map(w =>
        w.uuid === widgetId ? { ...w, json: widgetState } : w
      );
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: updatedWidgets,
          dirty: true,
        },
      };
    }
    case types.ADD_WIDGET: {
      const { widgets } = state.dashboard;
      const { widget } = action;
      const exists = widgets.some(w => w.uuid === widget.uuid);
      let newWidgets = widgets;
      if (exists) {
        newWidgets = widgets.map(w => (w.uuid === widget.uuid ? { ...widget, reload: true } : w));
      } else {
        let firstFreeSpace = 0;
        if (widgets.length) {
          firstFreeSpace = Math.max(...widgets.map(w => w.y + w.h));
        }
        const widgetSize = calculateWidgetSize(widget);
        const newWidget = {
          ...widget,
          x: 0,
          y: firstFreeSpace,
          w: widgetSize.w,
          h: widgetSize.h,
        };
        newWidgets = [...widgets, newWidget];
      }
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: newWidgets,
          dirty: true,
        },
      };
    }
    case types.REMOVE_WIDGET: {
      const { widgets } = state.dashboard;
      const { widget } = action;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: widgets.filter(w => w.uuid !== widget.uuid),
          dirty: true,
        },
      };
    }
    case types.LOAD_DASHBOARD: {
      const { dashboard } = action;
      return {
        ...state,
        dashboard: {
          ...dashboard,
          editing: false,
          refreshPeriodType: periodTypes[dashboard.refreshPeriodType] ?? periodTypes.MINUTE,
        },
      };
    }
    case types.CLEAR_DASHBOARD: {
      return defaultState;
    }
    case types.CHANGE_PERMISSION: {
      const { role, permission } = action;
      const { permissions } = state.dashboard;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dirty: true,
          permissions: {
            ...permissions,
            [role]: { role, permission },
          },
        },
      };
    }
    case types.DELETE_PERMISSION: {
      const { role } = action;
      const { permissions } = state.dashboard;
      const permissionsCopy = { ...permissions };
      delete permissionsCopy[role];
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dirty: true,
          permissions: permissionsCopy,
        },
      };
    }
    case types.APPLY_PERMISSION_TO_ALL_ROLES: {
      const { permission } = action;
      let newPermissions = {};
      if (DashboardPermissions.NO_ACCESS !== permission) {
        newPermissions = DashboardRoles.reduce((map, obj) => {
          map[obj.role] = { role: obj.role, permission };
          return map;
        }, {});
      }
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dirty: true,
          permissions: newPermissions,
        },
      };
    }
    case types.CHANGE_REFRESH_CONFIG: {
      const { refreshDashboard, refreshPeriod, refreshPeriodType } = action;
      const newRefreshDashboard = refreshDashboard ?? state.dashboard.refreshDashboard;
      const newRefreshPeriod = refreshPeriod ?? state.dashboard.refreshPeriod;
      const newRefreshPeriodType = refreshPeriodType ?? state.dashboard.refreshPeriodType;

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          refreshDashboard: newRefreshDashboard,
          refreshPeriod: newRefreshPeriod,
          refreshPeriodType: newRefreshPeriodType,
          dirty: true,
        },
      };
    }
    case types.SET_DIRTY: {
      const { dirty } = action;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dirty,
        },
      };
    }
    case types.TOGGLE_EDITION: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          editing: !state.dashboard.editing,
        },
      };
    }
    case types.ACKNOWLEDGE_WIDGET_RELOAD: {
      const { uuid } = action;
      const { widgets } = state.dashboard;

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: widgets.map(w => (w.uuid === uuid ? { ...w, reload: false } : w)),
        },
      };
    }
    case types.START_WIDGET_CREATION: {
      const { application } = action;

      return {
        ...state,
        widgetEditor: {
          editedWidget: {
            uuid: uuidv4(),
            title: undefined,
            application,
            json: undefined,
          },
          isNew: true,
        },
      };
    }
    case types.START_WIDGET_EDITION: {
      const { widget } = action;
      return {
        ...state,
        widgetEditor: {
          editedWidget: widget,
          isNew: false,
        },
      };
    }
    case types.CLONE_WIDGET: {
      const { widget } = action;
      const clonedWidget = {
        ...widget,
        uuid: uuidv4(),
        title: widget.title ? `${widget.title} (Copy)` : '',
      };
      return {
        ...state,
        widgetEditor: {
          editedWidget: clonedWidget,
          isNew: true,
        },
      };
    }
    case types.EDIT_WIDGET_TITLE: {
      const { title } = action;
      return {
        ...state,
        widgetEditor: {
          editedWidget: {
            ...state.widgetEditor.editedWidget,
            title,
          },
        },
      };
    }
    case types.EDIT_WIDGET_STATE: {
      const { widgetState } = action;
      return {
        ...state,
        widgetEditor: {
          editedWidget: {
            ...state.widgetEditor.editedWidget,
            json: widgetState,
          },
        },
      };
    }
    case types.SET_EDITED_WIDGET: {
      const { widget } = action;
      return {
        ...state,
        widgetEditor: {
          editedWidget: widget,
        },
      };
    }
    default:
      return state;
  }
};
