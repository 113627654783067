import types from './types';

const Messages = action => {
  switch (action.type) {
    case types.GET_USER_FAILURE:
      return {
        title: 'Error starting application, support has been informed',
        message: action.data.message,
        type: 'error'
      };
    default:
      return false;
  }
};

export default Messages;
