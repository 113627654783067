import { getAPI, takeQuery } from '../../api/agent';
import types from './types';
import actions from './actions';

export const systemNxcalsByType = {
  getNxcalsByType: ({ pagination }) =>
    getAPI().post(`nxcalsManagement/getNxcalsByType`, {}, false, pagination),

  deleteNxcalsByType: ({ msDevice }) =>
    getAPI().post(`/nxcalsManagement/deleteByType`, msDevice, false),

  updateNxcalsByType: ({ msDevice }) => getAPI().patch(`/nxcalsManagement`, msDevice, false, false),
  createNxcalsByType: ({ msDevice }) => getAPI().put(`/nxcalsManagement/newNxcalsByType`, msDevice),
};

const systemNxcalsByTypeSagas = [
  takeQuery(types.GET_NXCALS_BY_TYPE_SOURCES, {
    api: systemNxcalsByType.getNxcalsByType,
  }),
  takeQuery(types.DELETE_NXCALS_BY_TYPE, {
    api: systemNxcalsByType.deleteNxcalsByType,
    notify: [actions.getNxcalsByType],
  }),
  takeQuery(types.UPDATE_NXCALS_BY_TYPE_BY_ID, {
    api: systemNxcalsByType.updateNxcalsByType,
    notify: [actions.getNxcalsByType],
  }),
  takeQuery(types.CREATE_NXCALS_BY_TYPE, {
    api: systemNxcalsByType.createNxcalsByType,
    notify: [actions.getNxcalsByType],
  }),
];

export default systemNxcalsByTypeSagas;
