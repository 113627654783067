import { RemusWebApps } from '../../../../../constants';
import { displayModes } from '../../../../Measurements/MeasurementsStatistics/constants';
import { ParametersWidgetTabs } from '../../../../Parameters/constants';
import { SnapshotsWidgetTabs } from '../../../../Snapshots/constants';
import WidgetDefaultSizes from '../../../WidgetDefaultSizes';

function calculateWidgetSize(widget) {
  switch (widget?.application) {
    case RemusWebApps.MEASUREMENTS_STATISTICS: {
      const { json } = widget;
      const { selectedSources, displayMode, displayedTableColumns } = json ?? {};
      return {
        ...WidgetDefaultSizes[widget.application],
        w:
          displayMode === displayModes.TABLE_MODE
            ? Math.ceil(4 + displayedTableColumns.length * 0.65)
            : WidgetDefaultSizes[widget.application].w,
        h: Math.ceil(4 + selectedSources.length * 0.45) || WidgetDefaultSizes[widget.application].h,
      };
    }
    case RemusWebApps.SNAPSHOTS: {
      const { json } = widget;
      const {
        resultsContext: { activeWidgetTab, displayedColumns, paginationConfig, numericAggsColumns },
      } = json ?? {};
      if (SnapshotsWidgetTabs.RESULTS === activeWidgetTab) {
        return {
          ...WidgetDefaultSizes[widget.application],
          w: Math.min(WidgetDefaultSizes.SNAPSHOTS.w, Math.ceil(4 + displayedColumns.length * 0.5)),
          h: 4 + Math.ceil(paginationConfig.size * 0.7),
        };
      }
      if (SnapshotsWidgetTabs.NUMERIC_AGGS === activeWidgetTab) {
        return {
          ...WidgetDefaultSizes[widget.application],
          w: WidgetDefaultSizes.SNAPSHOTS.w,
          h: 4 + Math.ceil(numericAggsColumns.length * 0.7),
        };
      }
      return WidgetDefaultSizes.SNAPSHOTS;
    }
    case RemusWebApps.PARAMETERS: {
      const { json } = widget;
      const { activeWidgetTab, displayedColumns, paginationConfig } = json ?? {};
      if (ParametersWidgetTabs.RESULTS === activeWidgetTab) {
        return {
          ...WidgetDefaultSizes[widget.application],
          w: Math.min(
            WidgetDefaultSizes.PARAMETERS.w,
            Math.ceil(8 + displayedColumns.length * 0.75)
          ),
          h: 4 + Math.ceil(paginationConfig.size * 0.7),
        };
      }
      return WidgetDefaultSizes.PARAMETERS;
    }
    default:
      return WidgetDefaultSizes[widget?.application];
  }
}

export default calculateWidgetSize;
