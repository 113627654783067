import moment from 'moment';

const convertMillisToLocalMillis = millis =>
  moment(millis)
    .local()
    .valueOf();

export const convertHeatmapDataToLocalDate = data => {
  if (data?.length >= 4) {
    const xAxis = data[0]?.map(millis => convertMillisToLocalMillis(millis));
    const endTimestamp = convertMillisToLocalMillis(data[2]);
    const meas = data[3]?.map(array => [
      convertMillisToLocalMillis(array[0]),
      array[1],
      array[2]
    ]);
    return [xAxis, data[1], endTimestamp, meas];
  }
  return data;
};
