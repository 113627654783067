import { UPDATE_SCREEN_WIDTH, TOGGLE_SIDEBAR, OPEN_SIDEBAR, CLOSE_SIDEBAR } from './types';

const updateScreenWidth = width => ({
  type: UPDATE_SCREEN_WIDTH,
  params: { width },
});

const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });
const openSidebar = () => ({ type: OPEN_SIDEBAR });
const closeSidebar = () => ({ type: CLOSE_SIDEBAR });

export default {
  updateScreenWidth,
  toggleSidebar,
  openSidebar,
  closeSidebar,
};
