import { toast } from 'react-toastify';
import types from './types';
import { getErrorMessage } from '../errorHandler';

export default ({ type, data }) => {
  switch (type) {
    case types.GET_DATA_BY_TYPE_FAILURE:
      return {
        title: `Error getting Heatmap`,
        message: getErrorMessage(data),
        type: toast.TYPE.ERROR,
      };
    default:
      return false;
  }
};
