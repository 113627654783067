import types from './types';

export const changeDashboardName = name => ({
  type: types.CHANGE_DASHBOARD_NAME,
  name,
});

export const updateLayout = layout => ({
  type: types.UPDATE_LAYOUT,
  layout,
});

export const widgetStateChanged = (widgetId, widgetState) => ({
  type: types.WIDGET_STATE_CHANGED,
  widgetId,
  widgetState,
});

export const addWidget = widget => ({
  type: types.ADD_WIDGET,
  widget,
});

export const removeWidget = widget => ({
  type: types.REMOVE_WIDGET,
  widget,
});

export const loadDashboard = dashboard => ({
  type: types.LOAD_DASHBOARD,
  dashboard,
});

export const clearDashboard = () => ({
  type: types.CLEAR_DASHBOARD,
});

export const changePermission = (role, permission) => ({
  type: types.CHANGE_PERMISSION,
  role,
  permission,
});

export const deletePermission = role => ({
  type: types.DELETE_PERMISSION,
  role,
});

export const applyPermissionToAllRoles = permission => ({
  type: types.APPLY_PERMISSION_TO_ALL_ROLES,
  permission,
});

export const changeRefreshConfig = ({ refreshDashboard, refreshPeriod, refreshPeriodType }) => ({
  type: types.CHANGE_REFRESH_CONFIG,
  refreshDashboard,
  refreshPeriod,
  refreshPeriodType,
});

export const setDirty = dirty => ({
  type: types.SET_DIRTY,
  dirty,
});

export const toggleEdition = () => ({
  type: types.TOGGLE_EDITION,
});

export const acknowledgeWidgetReload = uuid => ({
  type: types.ACKNOWLEDGE_WIDGET_RELOAD,
  uuid,
});

export const startWidgetCreation = application => ({
  type: types.START_WIDGET_CREATION,
  application,
});

export const startWidgetEdition = widget => ({
  type: types.START_WIDGET_EDITION,
  widget,
});

export const cloneWidget = widget => ({
  type: types.CLONE_WIDGET,
  widget,
});

export const editWidgetTitle = title => ({
  type: types.EDIT_WIDGET_TITLE,
  title,
});

export const editWidgetState = widgetState => ({
  type: types.EDIT_WIDGET_STATE,
  widgetState,
});

export const setEditedWidget = widget => ({
  type: types.SET_EDITED_WIDGET,
  widget,
});

export default {
  changeDashboardName,
  updateLayout,
  widgetStateChanged,
  addWidget,
  removeWidget,
  loadDashboard,
  clearDashboard,
  changePermission,
  deletePermission,
  applyPermissionToAllRoles,
  changeRefreshConfig,
  setDirty,
  toggleEdition,
  acknowledgeWidgetReload,
  startWidgetCreation,
  startWidgetEdition,
  cloneWidget,
  editWidgetTitle,
  editWidgetState,
  setEditedWidget,
};
