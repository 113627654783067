import types from './types';

export const getNxcalsByType = ({ pagination }) => ({
  type: types.GET_NXCALS_BY_TYPE_SOURCES,
  params: {
    pagination,
  },
});
export const updateNxcalsByType = msDevice => ({
  type: types.UPDATE_NXCALS_BY_TYPE_BY_ID,
  params: { msDevice },
});

export const createNxcalsByType = msDevice => ({
  type: types.CREATE_NXCALS_BY_TYPE,
  params: { msDevice },
});

export const deleteNxcalsByType = msDevice => ({
  type: types.DELETE_NXCALS_BY_TYPE,
  params: { msDevice },
});

export default {
  getNxcalsByType,
  updateNxcalsByType,
  createNxcalsByType,
  deleteNxcalsByType,
};
