import { periodTypes } from '../../components/Common/RecentDataDatePicker/constants';
import { LAST_PERIOD_MODE } from '../../components/Trends/constants';
import { SAMPLE_TYPES } from './constants';
import types from './types';

const defaultState = {
  form: {
    dateBegin: null,
    dateEnd: null,
    dataLossThreshold: 60,
    sampleType: SAMPLE_TYPES.MIN,
    periodNumber: 1,
    periodType: periodTypes.DAY,
    dateMode: LAST_PERIOD_MODE,
  },
  sources: [],
};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case types.UPDATE_FORM: {
      const { field, value } = action;
      return {
        ...state,
        form: {
          ...state.form,
          [field]: value,
        },
      };
    }
    case types.SET_SOURCES: {
      const { sources } = action;
      return {
        ...state,
        sources,
      };
    }
    case types.RESET_STATE: {
      return { ...defaultState };
    }
    default:
      return state;
  }
};
