export const HEADER_ROW_DATE_FORMAT = 'DD-MM-YYYY_HH:mm:ss';
export const FILE_NAME_DATE_FORMAT = 'DD-MM-YYYY';

export const INTERNAL_CHANNELS_SOURCE = { id: undefined, name: 'Internal Channels' };

export const INTERNAL_CHANNELS_SOURCE_OPTION = {
  key: Number.MAX_SAFE_INTEGER,
  value: INTERNAL_CHANNELS_SOURCE,
  text: 'Internal channels',
};
