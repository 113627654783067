export function truncateText(text: string, maxLength: number) {
  if (text && text.length > maxLength) {
    return `${text.substring(0, maxLength)  }...`;
  }
  return text;
}

export const replaceNonAlphaNumeric =
  (text: string) => text.replace(/\W/g, '-')

export const keyify = (text: string) => replaceNonAlphaNumeric(text);