import {takePut, takeQuery} from "react-lib/dist/agent-es";
import {getAPI} from '../../api/agent';
import types from './types';

const Site = {
  getUser: () => getAPI().get('/user'),
  getSiteStatus: () => getAPI().get('/siteStatus'),
};

export default [
  takePut(types.START, [types.GET_USER, types.GET_SITE_STATUS]),
  takeQuery(types.GET_USER, {
    api: Site.getUser,
  }),
  takeQuery(types.GET_SITE_STATUS, {
    api: Site.getSiteStatus,
  }),
];
