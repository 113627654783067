import types from './types';

export const getRecentHeatmapAction = (
  filter,
  aggregateType,
  nbDay,
  averageOnPeriod
) => ({
  type: types.GET_RECENT_HEATMAP,
  params: { filter, aggregateType, nbDay, averageOnPeriod }
});
export const getHistoricalHeatmapAction = (
  filter,
  aggregateType,
  dateStart,
  dateEnd,
  averageOnPeriod
) => ({
  type: types.GET_HISTORICAL_HEATMAP,
  params: {
    filter,
    aggregateType,
    dateStart,
    dateEnd,
    averageOnPeriod
  }
});

export default {
  getRecentHeatmapAction,
  getHistoricalHeatmapAction
};
