import types from './types';

export const updateForm = (field, value) => ({
  type: types.UPDATE_FORM,
  field,
  value,
});

export const updateChartConfig = (field, value) => ({
  type: types.UPDATE_CHART_CONFIG,
  field,
  value,
});

export const sendForm = () => ({
  type: types.SEND_FORM,
});

export default {
  updateForm,
  updateChartConfig,
  sendForm,
};
