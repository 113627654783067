import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';
import moment from 'moment';

const initialState = {
  selectedCluster: undefined,
  selectedClusterId: undefined,
  selectedVersion: undefined,
};

const newCluster = () => ({
  name: '',
  active: true,
  versions: [
    {
      tmestamp: moment(),
      comment: '',
      clusterChannels: [],
    },
  ],
});

const clustersSlice = createSlice({
  name: 'clusters',
  initialState,
  reducers: {
    setSelectedCluster(state, action) {
      state.selectedCluster = action.payload;
      state.selectedClusterId = state.selectedCluster.id;
    },
    setClusterActive(state, action) {
      state.selectedCluster.active = action.payload;
    },
    setSelectedVersion(state, action) {
      state.selectedVersion = action.payload;
    },
    reset: () => initialState,
    newCluster(state) {
      state.cluster = newCluster();
    },
    updateSelectedVersion(state, action) {
      const { field, value } = action.payload;
      state.selectedVersion[field] = value;
    },
    updateSelectedVersionChannel(state, action) {
      const { index, field, value } = action.payload;

      state.selectedVersion.clusterChannels = state.selectedVersion.clusterChannels.map((ch, idx) =>
        index !== idx
          ? ch
          : {
              ...ch,
              [field]: value,
            }
      );
    },
    deleteSelectedVersionChannelByIndex(state, action) {
      state.selectedVersion.clusterChannels = state.selectedVersion.clusterChannels.filter(
        (ch, idx) => idx !== action.payload
      );
    },
    createNewVersion(state) {
      state.selectedVersion = {
        comment: '',
        clusterChannels: [],
      };
    },
    cloneSelectedVersion(state) {
      state.selectedVersion = cloneDeep(state.selectedVersion);
      state.selectedVersion.comment = '';
      state.selectedVersion.cloneId = state.selectedVersion.id;
      state.selectedVersion.id = null;
    },
    resetSelectedVersion(state) {
      state.selectedVersion = null;
    },
  },
});

export const clusterActions = clustersSlice.actions;
export default clustersSlice.reducer;
