const METEO_DATA_EXTRACTION_ACTION_PREFIX = '/app/METEO_DATA_EXTRACTION';
export const UPDATE_FORM = `${METEO_DATA_EXTRACTION_ACTION_PREFIX}/UPDATE_FORM`;
export const SET_SELECTED_MMS = `${METEO_DATA_EXTRACTION_ACTION_PREFIX}/SET_SELECTED_MMS`;
export const SEND_FORM = `${METEO_DATA_EXTRACTION_ACTION_PREFIX}/SEND_FORM`;

export default {
  UPDATE_FORM,
  SET_SELECTED_MMS,
  SEND_FORM,
};
