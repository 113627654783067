import { breakpoints } from '../../mediaStyles';
import types from './types';

const defaultState = {
  screenWidth: window.innerWidth,
  sidebar: window.innerWidth >= breakpoints.tablet,
};

export default (state = defaultState, { type, params }) => {
  switch (type) {
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: !state.sidebar,
      };
    case types.CLOSE_SIDEBAR:
      return {
        ...state,
        sidebar: false,
      };
    case types.OPEN_SIDEBAR:
      return {
        ...state,
        sidebar: true,
      };
    case types.UPDATE_SCREEN_WIDTH: {
      return {
        ...state,
        screenWidth: params.width,
      };
    }
    default:
      return state;
  }
};
