const AVAILABILITY_ACTIONS_PREFIX = 'app/AVAILABILITY';
export const SET_SOURCES = `${AVAILABILITY_ACTIONS_PREFIX}/SET_SOURCES`;
export const UPDATE_FORM = `${AVAILABILITY_ACTIONS_PREFIX}/UPDATE_FORM`;
export const RESET_STATE = `${AVAILABILITY_ACTIONS_PREFIX}/RESET_STATE`;

export default {
  SET_SOURCES,
  UPDATE_FORM,
  RESET_STATE,
};
