import { Pagination } from "../types/Pagination";

const parsePagination = (response: any) => ({
  totalElements: response.totalElements,
  totalPages: response.totalPages,
  currentPage: response.number,
  size: response.size
}) as Pagination;

export default parsePagination;
