export default {
  // Maximum width = 24
  TRENDS: {
    w: 12,
    h: 6,
    minW: 3,
    minH: 3,
  },
  ALARM_SCREEN: {
    w: 12,
    h: 6,
    minW: 3,
    minH: 3,
  },
  MARKDOWN: {
    w: 6,
    h: 6,
    minW: 3,
    minH: 3,
  },
  MEASUREMENTS_STATISTICS: {
    w: 10,
    h: 10,
    minW: 3,
    minH: 3,
  },
  SNAPSHOTS: {
    w: 12,
    h: 10,
    minW: 3,
    minH: 3,
  },
  PARAMETERS: {
    w: 12,
    h: 8,
    minW: 3,
    minH: 3,
  },
  MS_PARAMETERS: {
    w: 4,
    h: 10,
    minW: 3,
    minH: 3,
  },
  CHANNEL_PARAMETERS: {
    w: 4,
    h: 10,
    minW: 3,
    minH: 3,
  },
  MS_INFO: {
    w: 6,
    h: 6,
    minW: 3,
    minH: 3,
  },
  CHANNEL_INFO: {
    w: 12,
    h: 6,
    minW: 3,
    minH: 3,
  },
};
