import types from './types';
import { convertHeatmapDataToLocalDate } from './selectors';

const defaultState = {
  isLoading: false,
  data: [],
};

export default (state = defaultState, { type, data: responseData }) => {
  switch (type) {
    case types.GET_RECENT_HEATMAP:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_RECENT_HEATMAP_SUCCESS: {
      const parsedData = convertHeatmapDataToLocalDate(responseData);
      const [timeSerie, managerSerie, lastDateEnd, data] = parsedData;
      return {
        ...state,
        isLoading: false,
        timeSerie,
        managerSerie,
        lastDateEnd,
        data,
      };
    }
    case types.GET_RECENT_HEATMAP_FAILURE: {
      return {
        isLoading: false,
        timeSerie: [],
        managerSerie: [],
        lastDateEnd: null,
        data: [],
      };
    }
    case types.GET_HISTORICAL_HEATMAP_SUCCESS: {
      const parsedData = convertHeatmapDataToLocalDate(responseData);
      const [timeSerie, managerSerie, lastDateEnd, data] = parsedData;
      return {
        ...state,
        isLoading: false,
        timeSerie,
        managerSerie,
        lastDateEnd,
        data,
      };
    }
    case types.GET_HISTORICAL_HEATMAP_FAILURE: {
      return {
        isLoading: false,
        timeSerie: [],
        managerSerie: [],
        lastDateEnd: null,
        data: [],
      };
    }
    case types.GET_HISTORICAL_HEATMAP: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return state;
  }
};
