import register from 'react-lib/dist/behavior-es';
//DECLARE IMPORTS
import { heatmapMessages, heatmapReducer, heatmapSagas } from './heatmaps';
import { initMessages, initReducer, initSagas } from './init';
import { filterReducer, filterSagas } from './filtering';
import { notificationsMessages, notificationsReducer, notificationsSagas } from './notifications';
import { availabilityMessages, availabilityReducer } from './availability';
import { hyetogramReducer } from './hyetogram';
import { meteoDataExtractionReducer } from './meteoDataExtraction';
import {
  dataExportStatusMessages,
  dataExportStatusReducer,
  dataExportStatusSagas,
} from './dataExportStatus';
import {
  systemNxcalsByTypeMessages,
  systemNxcalsByTypeReducer,
  systemNxcalsByTypeSagas,
} from './systemNxcalsByType';
import { dashboardReducers } from './dashboards';
import { applicationEditorReducer } from './applicationEditor';
import { clustersReducers } from './netAmbientDoses';
import { generalReducer } from './general';

export const allSagas = [
  ...initSagas,
  //DECLARE SAGAS
  ...systemNxcalsByTypeSagas,
  ...heatmapSagas,
  ...notificationsSagas,
  ...filterSagas,
  ...dataExportStatusSagas,
];

export const [reducers, rootSaga] = register(
  {
    init: initReducer,
    //DECLARE REDUCERS
    general: generalReducer,
    heatmaps: heatmapReducer,
    notifications: notificationsReducer,
    filter: filterReducer,
    availability: availabilityReducer,
    hyetogram: hyetogramReducer,
    meteoDataExtraction: meteoDataExtractionReducer,
    dataExportStatus: dataExportStatusReducer,
    systemNxcalsByType: systemNxcalsByTypeReducer,
    dashboards: dashboardReducers,
    clusters: clustersReducers,
    applicationEditor: applicationEditorReducer,
  },
  [
    initMessages,
    //DECLARE MESSAGES
    systemNxcalsByTypeMessages,
    heatmapMessages,
    notificationsMessages,
    availabilityMessages,
    dataExportStatusMessages,
  ],
  allSagas
);
