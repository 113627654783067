const NOTIFICATION_ACTIONS_PREFIX = 'app/NOTIFICATIONS';
export const GET_NOTIFICATIONS = `${NOTIFICATION_ACTIONS_PREFIX}/GET_NOTIFICATIONS`;
export const GET_NOTIFICATIONS_SUCCESS = `${GET_NOTIFICATIONS}/SUCCESS`;
export const GET_NOTIFICATIONS_FAILURE = `${GET_NOTIFICATIONS}/FAILURE`;

export const CREATE_NOTIFICATION = `${NOTIFICATION_ACTIONS_PREFIX}/CREATE_NOTIFICATION`;
export const CREATE_NOTIFICATION_SUCCESS = `${CREATE_NOTIFICATION}/SUCCESS`;
export const CREATE_NOTIFICATION_FAILURE = `${CREATE_NOTIFICATION}/FAILURE`;

export const DELETE_NOTIFICATION = `${NOTIFICATION_ACTIONS_PREFIX}/DELETE_NOTIFICATION`;
export const DELETE_NOTIFICATION_SUCCESS = `${DELETE_NOTIFICATION}/SUCCESS`;
export const DELETE_NOTIFICATION_FAILURE = `${DELETE_NOTIFICATION}/FAILURE`;

export const UPDATE_NOTIFICATION_ENABLE = `${NOTIFICATION_ACTIONS_PREFIX}/UPDATE_NOTIFICATION_ENABLE`;
export const UPDATE_NOTIFICATION_ENABLE_SUCCESS = `${UPDATE_NOTIFICATION_ENABLE}/SUCCESS`;
export const UPDATE_NOTIFICATION_ENABLE_FAILURE = `${UPDATE_NOTIFICATION_ENABLE}/FAILURE`;

export const UPDATE_NOTIFICATION_BY_ID = `${NOTIFICATION_ACTIONS_PREFIX}/UPDATE_NOTIFICATION_BY_ID`;
export const UPDATE_NOTIFICATION_BY_ID_SUCCESS = `${UPDATE_NOTIFICATION_BY_ID}/SUCCESS`;
export const UPDATE_NOTIFICATION_BY_ID_FAILURE = `${UPDATE_NOTIFICATION_BY_ID}/FAILURE`;

export const NOTIFICATION_BY_ID = `${NOTIFICATION_ACTIONS_PREFIX}/NOTIFICATION_BY_ID`;
export const NOTIFICATION_BY_ID_SUCCESS = `${NOTIFICATION_BY_ID}/SUCCESS`;
export const NOTIFICATION_BY_ID_FAILURE = `${NOTIFICATION_BY_ID}/FAILURE`;

export const NOTIFICATIONS_BY_IDS = `${NOTIFICATION_ACTIONS_PREFIX}/NOTIFICATIONS_BY_IDS`;
export const NOTIFICATIONS_BY_IDS_SUCCESS = `${NOTIFICATIONS_BY_IDS}/SUCCESS`;
export const NOTIFICATIONS_BY_IDS_FAILURE = `${NOTIFICATIONS_BY_IDS}/FAILURE`;

export const GET_NOTIFICATION_FILTERS = `${NOTIFICATION_ACTIONS_PREFIX}/GET_NOTIFICATION_FILTERS`;
export const GET_NOTIFICATION_FILTERS_SUCCESS = `${GET_NOTIFICATION_FILTERS}/SUCCESS`;
export const GET_NOTIFICATION_FILTERS_FAILURE = `${GET_NOTIFICATION_FILTERS}/FAILURE`;

export const SET_SELECTED_NOTIFICATION = `${NOTIFICATION_ACTIONS_PREFIX}/SET_SELECTED_NOTIFICATION`;

export default {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  NOTIFICATION_BY_ID,
  NOTIFICATION_BY_ID_SUCCESS,
  NOTIFICATION_BY_ID_FAILURE,
  NOTIFICATIONS_BY_IDS,
  NOTIFICATIONS_BY_IDS_SUCCESS,
  NOTIFICATIONS_BY_IDS_FAILURE,
  GET_NOTIFICATION_FILTERS,
  GET_NOTIFICATION_FILTERS_SUCCESS,
  GET_NOTIFICATION_FILTERS_FAILURE,

  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,

  UPDATE_NOTIFICATION_ENABLE,
  UPDATE_NOTIFICATION_ENABLE_SUCCESS,
  UPDATE_NOTIFICATION_ENABLE_FAILURE,
  UPDATE_NOTIFICATION_BY_ID,
  UPDATE_NOTIFICATION_BY_ID_SUCCESS,
  UPDATE_NOTIFICATION_BY_ID_FAILURE,

  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILURE,

  SET_SELECTED_NOTIFICATION,
};
