import types from './types';
import parsePagination from '../../utils/PaginationUtils';

const defaultState = {
  sourcesCatalog: {
    sources: [],
    pagination: {},
  },
  isLoadingAvailabilities: false,
};

export default (state = defaultState, { type, data }) => {
  switch (type) {
    case types.GET_NXCALS_BY_TYPE_SOURCES:
      return {
        ...state,
        sourcesCatalog: {
          ...state.sourcesCatalog,
          isLoading: true,
        },
      };

    case types.GET_NXCALS_BY_TYPE_SOURCES_FAILURE:
      return {
        ...state,
        sourcesCatalog: {
          ...state.sourcesCatalog,
          isLoading: false,
        },
      };
    case types.GET_NXCALS_BY_TYPE_SOURCES_SUCCESS: {
      return {
        ...state,
        sourcesCatalog: {
          sources: data?.content.map(s => ({ ...s, active: false })),
          pagination: parsePagination(data),
          isLoading: false,
        },
      };
    }

    case types.DELETE_NXCALS_BY_TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.DELETE_NXCALS_BY_TYPE_SUCCESS: {
      return {
        ...state,
      };
    }
    case types.UPDATE_NXCALS_BY_TYPE_BY_ID: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.UPDATE_NXCALS_BY_TYPE_BY_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.UPDATE_NXCALS_BY_TYPE_BY_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case types.CREATE_NXCALS_BY_TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.CREATE_NXCALS_BY_TYPE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
