import { useCallback, useState } from 'react';
import { orderBy } from 'lodash';

export const SORT_ASCENDING = 'ascending';
export const SORT_DESCENDING = 'descending';

export const DEFAULT_SORT = {
  column: null,
  direction: null,
};

function useSort(initialSort = DEFAULT_SORT) {
  const [sort, setSort] = useState(initialSort);

  const nextStepOfCurrentSort = useCallback(
    sortColumn => {
      const { column, direction } = sort;
      if (sortColumn === column) {
        if (SORT_DESCENDING === direction) {
          setSort(DEFAULT_SORT);
        } else {
          setSort({ column, direction: SORT_DESCENDING });
        }
      } else {
        setSort({ column: sortColumn, direction: SORT_ASCENDING });
      }
    },
    [sort, setSort]
  );

  const changeSort = useCallback(
    (sortColumn, newDirection) => {
      if (sortColumn && newDirection) {
        setSort({ column: sortColumn, direction: newDirection });
      } else {
        nextStepOfCurrentSort(sortColumn);
      }
    },
    [setSort, nextStepOfCurrentSort]
  );

  return { sort, setSort, changeSort };
}

/**
 * Sorts a list taking into account the "sort" parameter
 * By default it will try to find the sorted column in the first level of the object keys
 * If innerSortField is determined, it will try to sort by the properties of the innerSortField object if it exists
 *
 * For example, if innerSortField = "params" and the objects have this structure:
 *
 * {
 *  msId: 1
 *  msName: Example,
 *  params: {
 *    histeresis: 0.5
 *  }
 * }
 *
 * The sort will be done over the fields inside "params" object
 *
 * @param {*} list - list to sort
 * @param {*} sort - object composed of "column" and "direction"
 * @param {*} innerSortField - Name of an inner object property in which to perform the sort.
 *
 */
export function sortList(list = [], sort, innerSortField) {
  let sortedList = [...list];
  if (sort.column) {
    const objWithKey = sortedList.find(p => p[sort.column]);
    if (objWithKey) {
      sortedList = orderBy(sortedList, [o => o[sort.column] || '']);
    } else if (innerSortField) {
      sortedList = orderBy(sortedList, [o => o?.[innerSortField][sort.column] || '']);
    }
    if (SORT_DESCENDING === sort.direction) {
      sortedList.reverse();
    }
  }
  return sortedList;
}

export default useSort;
