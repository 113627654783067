import { getAPI } from '../../api/agent';

const AvailabilityAPI = {
  getAvailabilities: ({ sources, dateBegin, dateEnd, dataLossThreshold, sampleType }) =>
    getAPI().post('availability', sources, false, {
      dateBegin,
      dateEnd,
      dataLossThreshold,
      sampleType,
    }),
};

export default AvailabilityAPI;
