const APPLICATION_EDITOR_PREFIX = 'app/applicationEditor';
export const SET_SELECTED_SYNOPTIC_ID = `${APPLICATION_EDITOR_PREFIX}/SET_SELECTED_SYNOPTIC_ID`;
export const SET_SELECTED_ELEMENT = `${APPLICATION_EDITOR_PREFIX}/SET_SELECTED_ELEMENT`;
export const SET_SELECTED_WIDGET = `${APPLICATION_EDITOR_PREFIX}/SET_SELECTED_WIDGET`;
export const SET_PROPERTIES_PANEL_ELEMENT = `${APPLICATION_EDITOR_PREFIX}/SET_PROPERTIES_PANEL_ELEMENT`;
export const SET_OPEN_CONTEXT_MENU = `${APPLICATION_EDITOR_PREFIX}/SET_OPEN_CONTEXT_MENU`;
export const SET_APPLICATION = `${APPLICATION_EDITOR_PREFIX}/SET_APPLICATION`;
export const SET_APPLICATION_METADATA = `${APPLICATION_EDITOR_PREFIX}/SET_APPLICATION_METADATA`;
export const UPDATE_EQUIPMENT_FIELD = `${APPLICATION_EDITOR_PREFIX}/UPDATE_EQUIPMENT_FIELD`;
export const UPDATE_ENTITY_FIELD = `${APPLICATION_EDITOR_PREFIX}/UPDATE_ENTITY_FIELD`;
export const RESET_STATE = `${APPLICATION_EDITOR_PREFIX}/RESET_STATE`;
export const SET_FILE_EDITION_CODE = `${APPLICATION_EDITOR_PREFIX}/SET_FILE_EDITION_CODE`;
export const INITIALIZE_TEXT_EDITION_MODE = `${APPLICATION_EDITOR_PREFIX}/INITIALIZE_TEXT_EDITION_MODE`;
export const FINISH_TEXT_EDITION_MODE = `${APPLICATION_EDITOR_PREFIX}/FINISH_TEXT_EDITION_MODE`;
export const SET_EDITION_MODE = `${APPLICATION_EDITOR_PREFIX}/SET_EDITION_MODE`;
export const ADD_BUTTON = `${APPLICATION_EDITOR_PREFIX}/ADD_BUTTON`;
export const ADD_ALARM = `${APPLICATION_EDITOR_PREFIX}/ADD_ALARM`;
export const ADD_EQUIPMENT_TO_ALARM = `${APPLICATION_EDITOR_PREFIX}/ADD_EQUIPMENT_TO_ALARM`;
export const REMOVE_EQUIPMENT_TO_ALARM = `${APPLICATION_EDITOR_PREFIX}/REMOVE_EQUIPMENT_TO_ALARM`;
export const DELETE_BUTTON = `${APPLICATION_EDITOR_PREFIX}/DELETE_BUTTON`;
export const DELETE_LABEL = `${APPLICATION_EDITOR_PREFIX}/DELETE_LABEL`;
export const DELETE_LINE = `${APPLICATION_EDITOR_PREFIX}/DELETE_LINE`;
export const ADD_LINE = `${APPLICATION_EDITOR_PREFIX}/ADD_LINE`;
export const ADD_LABEL = `${APPLICATION_EDITOR_PREFIX}/ADD_LABEL`;
export const DELETE_WIDGET = `${APPLICATION_EDITOR_PREFIX}/DELETE_WIDGET`;
export const NEW_SYNOPTIC_CONTEXT_MENU = `${APPLICATION_EDITOR_PREFIX}/NEW_SYNOPTIC_CONTEXT_MENU`;
export const CLONE_SYNOPTIC = `${APPLICATION_EDITOR_PREFIX}/CLONE_SYNOPTIC`;
export const DELETE_SYNOPTIC = `${APPLICATION_EDITOR_PREFIX}/DELETE_SYNOPTIC`;
export const NEW_SYNOPTIC_MENU = `${APPLICATION_EDITOR_PREFIX}/NEW_SYNOPTIC_MENU`;
export const DISPLAY_MODAL_WIDGETS = `${APPLICATION_EDITOR_PREFIX}/DISPLAY_MODAL_WIDGETS`;
export const MOVE_SYNOPTIC_UP_ITS_PARENT = `${APPLICATION_EDITOR_PREFIX}/MOVE_SYNOPTIC_UP_ITS_PARENT`;
export const RENAME_SYNOPTIC = `${APPLICATION_EDITOR_PREFIX}/RENAME_SYNOPTIC`;
export const SET_CLIPBOARD_FOR_WIDGETS = `${APPLICATION_EDITOR_PREFIX}/SET_CLIPBOARD_FOR_WIDGETS`;
export const PASTE_SYNOPTIC = `${APPLICATION_EDITOR_PREFIX}/PASTE_SYNOPTIC`;
export const ADD_EQUIPMENT = `${APPLICATION_EDITOR_PREFIX}/ADD_EQUIPMENT`;
export const MOVE_SYNOPTIC_UP_INSIDE_PARENT = `${APPLICATION_EDITOR_PREFIX}/MOVE_SYNOPTIC_UP_INSIDE_PARENT`;
export const MOVE_SYNOPTIC_DOWN_INSIDE_PARENT = `${APPLICATION_EDITOR_PREFIX}/MOVE_SYNOPTIC_DOWN_INSIDE_PARENT`;
export const CHANGE_SYNOPTIC_BACKGROUND = `${APPLICATION_EDITOR_PREFIX}/CHANGE_SYNOPTIC_BACKGROUND`;
export const SET_CANVAS_COLOR = `${APPLICATION_EDITOR_PREFIX}/SET_CANVAS_COLOR`;
export const SET_WIDGET_OUT_OF_CANVAS = `${APPLICATION_EDITOR_PREFIX}/SET_WIDGET_OUT_OF_CANVAS`;
export const EXECUTE_UNDO = `${APPLICATION_EDITOR_PREFIX}/EXECUTE_UNDO`;
export const EXECUTE_REDO = `${APPLICATION_EDITOR_PREFIX}/EXECUTE_REDO`;
export const ALIGN_HORIZONTALLY = `${APPLICATION_EDITOR_PREFIX}/ALIGN_HORIZONTALLY`;
export const ALIGN_VERTICALLY = `${APPLICATION_EDITOR_PREFIX}/ALIGN_VERTICALLY`;
export const UPDATE_MULTIPLE_EQUIPMENT_FIELD = `${APPLICATION_EDITOR_PREFIX}/UPDATE_MULTIPLE_EQUIPMENT_FIELD`;
export const UPDATE_ENTITY_FIELD_WIDGET = `${APPLICATION_EDITOR_PREFIX}/UPDATE_ENTITY_FIELD_WIDGET`;
export const COPY_MULTIPLE_WIDGETS = `${APPLICATION_EDITOR_PREFIX}/COPY_MULTIPLE_WIDGETS`;
export const PASTE_MULTIPLE_WIDGETS_FROM_CLIPBOARD = `${APPLICATION_EDITOR_PREFIX}/PASTE_MULTIPLE_WIDGETS_FROM_CLIPBOARD`;
export const SET_ALL_WIDGETS_SELECTED = `${APPLICATION_EDITOR_PREFIX}/SET_ALL_WIDGETS_SELECTED`;
export const SET_RENAME_SYNOPTIC = `${APPLICATION_EDITOR_PREFIX}/SET_RENAME_SYNOPTIC`;
export const SET_WIDGETS_SELECTED_BY_DRAG = `${APPLICATION_EDITOR_PREFIX}/SET_WIDGETS_SELECTED_BY_DRAG`;
export const MOVE_MULTIPLE_WIDGETS = `${APPLICATION_EDITOR_PREFIX}/MOVE_MULTIPLE_WIDGETS`;
export const RELOCATE_WIDGETS_INSIDE_CANVAS = `${APPLICATION_EDITOR_PREFIX}/RELOCATE_WIDGETS_INSIDE_CANVAS`;
export const RELOCATE_AFTER_MOVING_WITH_ARROWS = `${APPLICATION_EDITOR_PREFIX}/RELOCATE_AFTER_MOVING_WITH_ARROWS`;
export const SET_CLICK_ON_CANVAS = `${APPLICATION_EDITOR_PREFIX}/SET_CLICK_ON_CANVAS`;
export const RESET_SELECTED_WIDGETS = `${APPLICATION_EDITOR_PREFIX}/RESET_SELECTED_WIDGETS`;
export const SET_WIDGET_DIMENSIONS = `${APPLICATION_EDITOR_PREFIX}/SET_WIDGET_DIMENSIONS`;
export const SET_REMUS_APP_FREE_TEXT = `${APPLICATION_EDITOR_PREFIX}/SET_REMUS_APP_FREE_TEXT`;
export const SET_SAVE_BUTTON_AVAILABILITY = `${APPLICATION_EDITOR_PREFIX}/SET_SAVE_BUTTON_AVAILABILITY`;
export const SET_DELETE_LAST_MAIN_SYNOPTIC = `${APPLICATION_EDITOR_PREFIX}/SET_DELETE_LAST_MAIN_SYNOPTIC`;
export const SET_CHANGE_SYNOPTIC_BACKGROUND_MODAL = `${APPLICATION_EDITOR_PREFIX}/SET_CHANGE_SYNOPTIC_BACKGROUND_MODAL`;
export const DELETE_WIDGET_CONFIRMATION_MODAL = `${APPLICATION_EDITOR_PREFIX}/DELETE_WIDGET_CONFIRMATION_MODAL`;
export const DELETE_WIDGETS_IN_CLIPBOARD = `${APPLICATION_EDITOR_PREFIX}/DELETE_WIDGETS_IN_CLIPBOARD`;
export const DELETE_ELEMENTS_SELECTED = `${APPLICATION_EDITOR_PREFIX}/DELETE_ELEMENTS_SELECTED`;
export const ADD_ROOT_NODE_CONTEXT_MENU = `${APPLICATION_EDITOR_PREFIX}/ADD_ROOT_NODE_CONTEXT_MENU`;
export const ADD_SYNOPTIC_ROOT_NODE = `${APPLICATION_EDITOR_PREFIX}/ADD_SYNOPTIC_ROOT_NODE`;
export const WIDGETS_ARE_BEING_DRAGGED = `${APPLICATION_EDITOR_PREFIX}/WIDGETS_ARE_BEING_DRAGGED`;
export const CHANGES_SINCE_SAVE = `${APPLICATION_EDITOR_PREFIX}/CHANGES_SINCE_SAVE`;

export default {
  SET_SELECTED_SYNOPTIC_ID,
  SET_SELECTED_ELEMENT,
  SET_SELECTED_WIDGET,
  SET_PROPERTIES_PANEL_ELEMENT,
  SET_OPEN_CONTEXT_MENU,
  SET_APPLICATION,
  SET_APPLICATION_METADATA,
  UPDATE_EQUIPMENT_FIELD,
  UPDATE_ENTITY_FIELD,
  RESET_STATE,
  SET_FILE_EDITION_CODE,
  SET_EDITION_MODE,
  INITIALIZE_TEXT_EDITION_MODE,
  FINISH_TEXT_EDITION_MODE,
  ADD_EQUIPMENT,
  ADD_BUTTON,
  ADD_ALARM,
  ADD_EQUIPMENT_TO_ALARM,
  REMOVE_EQUIPMENT_TO_ALARM,
  ADD_LINE,
  ADD_LABEL,
  DELETE_BUTTON,
  DELETE_LABEL,
  DELETE_LINE,
  DELETE_WIDGET,
  NEW_SYNOPTIC_CONTEXT_MENU,
  CLONE_SYNOPTIC,
  DELETE_SYNOPTIC,
  NEW_SYNOPTIC_MENU,
  DISPLAY_MODAL_WIDGETS,
  MOVE_SYNOPTIC_UP_ITS_PARENT,
  RENAME_SYNOPTIC,
  SET_CLIPBOARD_FOR_WIDGETS,
  PASTE_SYNOPTIC,
  MOVE_SYNOPTIC_UP_INSIDE_PARENT,
  MOVE_SYNOPTIC_DOWN_INSIDE_PARENT,
  CHANGE_SYNOPTIC_BACKGROUND,
  SET_CANVAS_COLOR,
  SET_WIDGET_OUT_OF_CANVAS,
  EXECUTE_UNDO,
  EXECUTE_REDO,
  ALIGN_HORIZONTALLY,
  ALIGN_VERTICALLY,
  UPDATE_MULTIPLE_EQUIPMENT_FIELD,
  UPDATE_ENTITY_FIELD_WIDGET,
  COPY_MULTIPLE_WIDGETS,
  PASTE_MULTIPLE_WIDGETS_FROM_CLIPBOARD,
  SET_ALL_WIDGETS_SELECTED,
  SET_RENAME_SYNOPTIC,
  SET_WIDGETS_SELECTED_BY_DRAG,
  MOVE_MULTIPLE_WIDGETS,
  RELOCATE_WIDGETS_INSIDE_CANVAS,
  RELOCATE_AFTER_MOVING_WITH_ARROWS,
  SET_CLICK_ON_CANVAS,
  RESET_SELECTED_WIDGETS,
  SET_WIDGET_DIMENSIONS,
  SET_REMUS_APP_FREE_TEXT,
  SET_SAVE_BUTTON_AVAILABILITY,
  SET_DELETE_LAST_MAIN_SYNOPTIC,
  SET_CHANGE_SYNOPTIC_BACKGROUND_MODAL,
  DELETE_WIDGET_CONFIRMATION_MODAL,
  DELETE_WIDGETS_IN_CLIPBOARD,
  DELETE_ELEMENTS_SELECTED,
  ADD_ROOT_NODE_CONTEXT_MENU,
  ADD_SYNOPTIC_ROOT_NODE,
  WIDGETS_ARE_BEING_DRAGGED,
  CHANGES_SINCE_SAVE,
};
