// Integration types
export const NO_INTEGRATION = 'NO_INTEGRATION';
export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';

export const FLOW_RATE_UNITS = [
  {
    label: 'm\u00B3/h',
    timeConversionFactor: 1,
    volumeUnit: 'm\u00B3',
    volumeConversionFactor: 1
  },
  {
    label: 'm\u00B3/d',
    timeConversionFactor: 24,
    volumeUnit: 'm\u00B3',
    volumeConversionFactor: 1
  },
  {
    label: 'L/s',
    timeConversionFactor: 1 / 3600,
    volumeUnit: 'L',
    volumeConversionFactor: 1000
  }
];

export const PRECIPITATION_UNITS = [
  {
    label: 'mm/h',
    timeConversionFactor: 60,
    volumeUnit: 'mm',
    volumeConversionFactor: 1
  },
  {
    label: 'mm/m',
    timeConversionFactor: 1,
    volumeUnit: 'mm',
    volumeConversionFactor: 1
  },
  {
    label: 'mm/d',
    timeConversionFactor: 60 * 24,
    volumeUnit: 'mm',
    volumeConversionFactor: 1
  }
];

// Sample Types
export const SAMPLE_TYPE_MINUTE = 'MINUTE_VAL';
export const SAMPLE_TYPE_HOUR = 'HOUR_VAL';

export const DataResolution = {
  AUTO: 'AUTO',
  HOUR: 'HOUR',
  MINUTE: 'MINUTE',
  BEST: 'BEST'
};
