import RouteDefinition from 'react-lib/dist/routes-es';

// #############################
// Platform Root
// #############################
export const PlatformRoot = new RouteDefinition(':platform');


// #############################
// Admin
// #############################
export const AdminHome = new RouteDefinition('admin', PlatformRoot);
export const SystemStats = new RouteDefinition('system/stats', AdminHome);
export const Heatmap = new RouteDefinition('heatmap', AdminHome)
export const SystemNxcals = new RouteDefinition('system/nxcalsManagement', AdminHome);
export const SystemNxcalsByType = new RouteDefinition('type', SystemNxcals);
export const SystemNxcalsByChid = new RouteDefinition('chid', SystemNxcals);


// #############################
// Application Editor
// #############################
export const ApplicationEditorRoot = new RouteDefinition('applicationEditor', PlatformRoot);
export const SynopticWidgetsCatalog = new RouteDefinition('widgets', ApplicationEditorRoot);
export const SynopticsCatalog = new RouteDefinition('synoptics', ApplicationEditorRoot);
export const RemusApplication = new RouteDefinition(':remusApplicationId?', ApplicationEditorRoot);

// #############################
// Dashboards
// #############################
export const DashboardsRoot = new RouteDefinition('dashboards', PlatformRoot);
export const Dashboards = new RouteDefinition(':dashboardId?', DashboardsRoot);
export const NewDashboard = new RouteDefinition('new', DashboardsRoot);
export const MSDashboardsRoot = new RouteDefinition('ms', DashboardsRoot);
export const MSDashboards = new RouteDefinition(':msId?', MSDashboardsRoot);

// #############################
// Data Export
// #############################
export const DataExportStatusHome = new RouteDefinition('dataExportStatus', PlatformRoot);
export const DataExportStatusNxcals = new RouteDefinition('nxcals', DataExportStatusHome);
export const DataExportStatusInflux = new RouteDefinition('influx', DataExportStatusHome);




// #############################
// Notifications
// #############################
export const NotificationList = new RouteDefinition('notifications', PlatformRoot);
export const UnsubscribeNotifications = new RouteDefinition('unsubscribe', NotificationList);
export const Notification = new RouteDefinition(':notificationId', NotificationList);
export const NewNotification = new RouteDefinition('new', NotificationList);


// #############################
// Reporting
// #############################
export const ReportingHome = new RouteDefinition('reporting', PlatformRoot);
export const Parameters = new RouteDefinition('parameters', ReportingHome);
export const Snapshots = new RouteDefinition('snapshots', ReportingHome);
export const AlarmExtraction = new RouteDefinition('alarmExtraction', ReportingHome);
export const Availability = new RouteDefinition('availability', ReportingHome);
export const Hyetogram = new RouteDefinition('hyetogram', ReportingHome);
export const MeasurementsStatistics = new RouteDefinition('measurementsStatistics', ReportingHome);
export const MeteoDataExtraction = new RouteDefinition('meteo', ReportingHome);

// Net Ambient Doses
export const NetAmbientDoses = new RouteDefinition('measurementsProcessing', ReportingHome);
export const Clusters = new RouteDefinition('clusters', NetAmbientDoses);
export const ClusterVersion = new RouteDefinition(':clusterVersionId', Clusters);
export const PipelineRoot = new RouteDefinition('pipeline', ClusterVersion);
export const Pipeline = new RouteDefinition(':pipelineId?', PipelineRoot);


// #############################
// Statistics
// #############################
export const StatisticsHome = new RouteDefinition('statistics', PlatformRoot);
export const ChannelStatistics = new RouteDefinition('channels', StatisticsHome);
export const MSStatistics = new RouteDefinition('MS', StatisticsHome);
export const DeviceStatistics = new RouteDefinition('devices', StatisticsHome);
export const ClientApplicationSessionStatistics
  = new RouteDefinition('clientApplicationSessions', StatisticsHome);
export const ClientSynopticSessionsStatistics
  = new RouteDefinition('clientSynopticSessions', StatisticsHome);
export const ChannelMonthHistoryStatistics
  = new RouteDefinition('channelMonthHistory', StatisticsHome);
export const MSMonthHistoryStatistics = new RouteDefinition('msMonthHistory', StatisticsHome);
export const RemusEventStatistics = new RouteDefinition('events', StatisticsHome);
export const ChannelHistoryStatistics = new RouteDefinition('channelHistory', StatisticsHome);
export const MSHistoryStatistics = new RouteDefinition('msHistory', StatisticsHome);
export const MSDAChannelStatistics = new RouteDefinition('MSDAChannel', StatisticsHome);
export const UserPrivilegesStatistics = new RouteDefinition('userPrivileges', StatisticsHome);
export const ClientSessionStatistics = new RouteDefinition('clientSessions', StatisticsHome);


// #############################
// MISC
// #############################
export const Alarms = new RouteDefinition('alarms', PlatformRoot);
export const MonitoringGroups = new RouteDefinition('monitoringGroups', PlatformRoot);
export const Trends = new RouteDefinition('trends', PlatformRoot);


// #############################
// Export
// #############################
export default {
  Parameters,
  SystemStats,
  SystemNxcals,
  SystemNxcalsByType,
  SystemNxcalsByChid,
  Admin: AdminHome,
  PlatformRoot,
  NotificationList,
  UnsubscribeNotifications,
  Notification,
  NewNotification,
  Heatmap,
  Trends,
  Alarms,
  Availability,
  StatisticsHome,
  ChannelStatistics,
  MSStatistics,
  DeviceStatistics,
  UserPrivilegesStatistics,
  ClientSessionStatistics,
  ClientApplicationSessionStatistics,
  ClientSynopticSessionsStatistics,
  ChannelMonthHistoryStatistics,
  MSMonthHistoryStatistics,
  MSDAChannelStatistics,
  RemusEventStatistics,
  ChannelHistoryStatistics,
  MSHistoryStatistics,
  Reporting: ReportingHome,
  Hyetogram,
  MeteoDataExtraction,
  AlarmExtraction,
  MeasurementsStatistics,
  Snapshots,
  DataExportStatusHome,
  DataExportStatusNxcals,
  DataExportStatusInflux,
  Dashboards,
  DashboardsRoot,
  MSDashboards,
  MSDashboardsRoot,
  NewDashboard,
  MonitoringGroups,
  ApplicationEditorRoot,
  SynopticWidgetsCatalog,
  SynopticsCatalog,
  RemusApplication,
  NetAmbientDoses,
  Clusters,
};
