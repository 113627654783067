import { createMedia } from '@artsy/fresnel';

export const breakpoints = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1500,
  widescreen: 1920,
};

const AppMedia = createMedia({
  breakpoints,
});

export const mediaStyles = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
