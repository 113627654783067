import { errorMessage } from '../../behavior/messages';
import ReportsAPI from '../../behavior/reports/api';

export const SnapshotsWidgetTabs = {
  RESULTS: 0,
  NUMERIC_AGGS: 1,
  CATEGORICAL_AGGS: 2,
};

export const SNAPSHOT_TYPES_WITH_PDF_EXPORT = {
  FHT1388S_CTRL: {
    exportFunction: snapshot =>
      ReportsAPI.getFHT1388SSnapshotReport(snapshot).catch(error =>
        errorMessage({ title: 'There was an error getting the snapshot', error })
      ),
  },
};
