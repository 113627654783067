import Connector, { takeQuery } from '../../api/agent';
import types from './types';

const { getAPI } = Connector;

const Heatmap = {
  recentHeatmap: ({ filter, aggregateType, nbDay, averageOnPeriod }) =>
    getAPI().get(`/heatmap/recentData`, false, {
      filter,
      aggregateType,
      nbDay,
      averageOnPeriod,
    }),
  historicalHeatmap: ({ filter, aggregateType, dateStart, dateEnd, averageOnPeriod }) =>
    getAPI().get(`heatmap/historicalData`, false, {
      filter,
      aggregateType,
      dateStart,
      dateEnd,
      averageOnPeriod,
    }),
};

const heatmapSagas = [
  takeQuery(types.GET_RECENT_HEATMAP, {
    api: Heatmap.recentHeatmap,
  }),
  takeQuery(types.GET_HISTORICAL_HEATMAP, {
    api: Heatmap.historicalHeatmap,
  }),
];

export default heatmapSagas;
