import { denormalize } from 'normalizr';
import xmlFormatter from 'xml-formatter';
import { jsonToXml } from '../../pages/RemusApplicationPage/applicationXmlToJson';
import { documentSchema, synoptic } from './normalize';
import { isOutsideCanvas } from '../../components/ApplicationEditor/RemusApplication/RemusApplicationCanvas/utils';

function intersectObjects(objSource, objInterface) {
  const newObj = {};
  // eslint-disable-next-line
  for (const key in objSource) {
    // eslint-disable-next-line
    if (objInterface.hasOwnProperty(key)) {
      // in javascript an array is a object too.
      if (
        objSource[key] instanceof Object &&
        !Array.isArray(objSource[key]) &&
        objInterface[key] instanceof Object &&
        !Array.isArray(objInterface[key])
      ) {
        newObj[key] = {};
        newObj[key] = intersectObjects(objSource[key], objInterface[key]);
      } else {
        newObj[key] = objSource[key];
      }
    }
  }

  return newObj;
}

export const selectedSynopticIdSelector = ({ applicationEditor }) =>
  applicationEditor.selectedSynoptic;

export const selectedSynopticSelector = ({ applicationEditor }) => {
  const { selectedSynoptic, entities } = applicationEditor;

  if (selectedSynoptic) {
    return denormalize(selectedSynoptic, synoptic, entities);
  }
  return null;
};

export const getSynopticByIdSelector =
  id =>
  ({ applicationEditor }) => {
    const { entities } = applicationEditor;
    return denormalize(entities?.synoptics[id], synoptic, entities);
  };

export const selectedElementSelector = ({ applicationEditor }) => applicationEditor.selectedElement;

export const allObjectsSelectedSelector = ({ applicationEditor }) => {
  const selectedWidgets = applicationEditor?.selectedWidgets;
  const { entities } = applicationEditor;
  if (applicationEditor?.selectedWidgets.length >= 1) {
    const allObjectsSelected = [];
    selectedWidgets?.forEach(obj => {
      allObjectsSelected.push(entities[`${obj?.type}s`][obj?.id]);
    });
    return allObjectsSelected;
  }
  return [];
};

export const allEquipmentsInOneAlarmSelector = ({ applicationEditor }) => {
  const selectedWidgets = applicationEditor?.selectedWidgets;
  const { entities } = applicationEditor;
  const equipmentIdsInAlarm = entities.alarmSummarys[selectedWidgets[0].id].equipments;
  const allEquipments = [];
  Object.entries(entities?.equipments).forEach(e => {
    if (equipmentIdsInAlarm?.includes(e[0])) {
      allEquipments?.push(e[1]);
    }
  });
  return allEquipments;
};

export const selectedWidgetSelector = ({ applicationEditor }) => applicationEditor.selectedWidgets;
export const freeTextSelector = ({ applicationEditor }) => applicationEditor.freeText;
export const displayVersionsSelector = ({ applicationEditor }) =>
  applicationEditor.displayRemusApplicationVersions;

export const allEquipmentsInSynopticSelector = ({ applicationEditor }) => {
  const { selectedSynoptic, entities } = applicationEditor;
  const allEquipmentsIds = entities?.synoptics[selectedSynoptic].equipments;
  const allEquipments = allEquipmentsIds.map(id => entities.equipments[id]);
  return allEquipments;
};

export const intersectionSelector = ({ applicationEditor }) => {
  const selectedWidgets = allObjectsSelectedSelector({ applicationEditor });
  let interse = selectedWidgets[0];
  const propertiesEqual = [];
  if (selectedWidgets.length > 1) {
    selectedWidgets.forEach((e, index) => {
      if (index === 0) {
        return;
      }
      interse = intersectObjects(interse, e);
    });
  }
  Object.keys(interse).forEach(key =>
    selectedWidgets.every(obj => obj[key] === interse[key])
      ? propertiesEqual.push(key)
      : `key ${key} is NOT the same`
  );
  return [propertiesEqual, interse];
};

export const displayedSettingsSelector = ({ applicationEditor }) => {
  const { displayedSettings, entities } = applicationEditor;
  if (!displayedSettings) {
    return null;
  }
  const { id, type } = displayedSettings;
  return entities[`${type}s`][id];
};

export const contextMenuStateSelector = ({ applicationEditor }) =>
  applicationEditor.contextMenuState;
export const rightClickCoordinatesSelector = ({ applicationEditor }) =>
  applicationEditor.rightClickCoordinates;
export const canvasModalCoordinatesSelector = ({ applicationEditor }) =>
  applicationEditor.canvasModalCoordinates;

export const isSelectedInComponentsContainedSelector = ({ applicationEditor }) =>
  applicationEditor.isSelectedInComponentsContained;

export const applicationSelector = ({ applicationEditor }) => {
  const { applicationParent, entities } = applicationEditor;
  if (applicationParent && entities) {
    const denormalized = denormalize(applicationParent, documentSchema, entities);

    return denormalized;
  }
  return null;
};

export const areElementsOutsideCanvasSelector = ({ applicationEditor }) => {
  const { selectedSynoptic, entities } = applicationEditor;
  const selectedSynopticWithItsElements = entities.synoptics[selectedSynoptic];
  const selectedSynopticChildren = allSynopticChildrenSelector(selectedSynoptic)({
    applicationEditor,
  });
  return selectedSynopticChildren?.some(element => {
    if (isOutsideCanvas(element, selectedSynopticWithItsElements)) {
      return true;
    }
    return false;
  });
};

export const allSynopticChildrenSelector =
  synopticId =>
  ({ applicationEditor }) => {
    const { entities } = applicationEditor;

    const selectedSynopticWithItsElements = entities.synoptics[synopticId];
    const labels = selectedSynopticWithItsElements?.labels?.map(id => entities.labels[id]) ?? [];
    const equipments =
      selectedSynopticWithItsElements?.equipments?.map(id => entities.equipments[id]) ?? [];
    const buttons = selectedSynopticWithItsElements?.buttons?.map(id => entities.buttons[id]) ?? [];
    const synoptics =
      selectedSynopticWithItsElements?.synoptics?.map(id => entities.synoptics[id]) ?? [];
    const alarmSummarys =
      selectedSynopticWithItsElements?.alarmSummarys?.map(id => entities.alarmSummarys[id]) ?? [];
    const lines = selectedSynopticWithItsElements?.lines?.map(id => entities.lines[id]) ?? [];
    return [...labels, ...equipments, ...buttons, ...synoptics, ...alarmSummarys, ...lines];
  };

export const applicationXMLSelector = ({ applicationEditor }) => {
  const { applicationParent, entities } = applicationEditor;
  if (applicationParent && entities) {
    const denormalized = denormalize(applicationParent, documentSchema, entities);
    const xml = jsonToXml(denormalized);
    return xml;
  }
  return null;
};

export const isOriginalSynopticNodeSelector = ({ applicationEditor }) => {
  const { selectedWidgets, entities } = applicationEditor;

  if (entities.maps[selectedWidgets[0]?.parent]) {
    return true;
  }

  return false;
};

export const allAlarmEquipments = ({ applicationEditor }) => {
  const { entities, selectedWidgets } = applicationEditor;
  const alarmEquipments = entities?.alarmSummarys[selectedWidgets[0].id]?.equipments;
  return alarmEquipments;
};

export const selectedSynopticPropertiesSelector = ({ applicationEditor }) => {
  const { selectedSynoptic, entities } = applicationEditor;
  const selectedSynopticWithItsElements = entities.synoptics[selectedSynoptic];
  return selectedSynopticWithItsElements;
};

export function serializeXmlNode(xmlNode) {
  if (xmlNode) {
    return new window.XMLSerializer().serializeToString(xmlNode);
  }
  return '';
}

export const applicationXMLCodeSelector = state => {
  const applicationXML = applicationXMLSelector(state);
  return xmlFormatter(serializeXmlNode(applicationXML));
};

export const editionModeSelector = ({ applicationEditor }) => applicationEditor.editionMode;
export const fileEditionCodeSelector = ({ applicationEditor }) =>
  applicationEditor.fileEdition?.code;

export const applicationMetadataSelector = ({ applicationEditor }) =>
  applicationEditor.applicationMetadata;
export const lastMainSynopticSelector = ({ applicationEditor }) =>
  applicationEditor.triedToDeletelastMainSynoptic;

export const newSynopticContextSelector = ({ applicationEditor }) =>
  applicationEditor.newSynopticContext;

export const openModalWidgetsSelector = ({ applicationEditor }) =>
  applicationEditor.displayWidgetsModal;
export const cutOperationValidSelector = ({ applicationEditor }) =>
  applicationEditor.cutOperationValid;

export const openDeleteWidgetConfirmationModalSelector = ({ applicationEditor }) =>
  applicationEditor.deleteWidgetConfirmation;

export const openChangeSynopticBackgroundModalSelector = ({ applicationEditor }) =>
  applicationEditor.openChangeSynopticBackgroundModal;
export const clipboardSelector = ({ applicationEditor }) => applicationEditor.clipboard;

export const clickOnTreeSelector = ({ applicationEditor }) => applicationEditor.clickOnTree;
export const synopticTreeModalStateSelector = ({ applicationEditor }) =>
  applicationEditor.synopticTreeModalState;
export const undoSelector = ({ applicationEditor }) => applicationEditor.undo;

export const redoSelector = ({ applicationEditor }) => applicationEditor.redo;
export const saveButtonAvailabilitySelector = ({ applicationEditor }) =>
  applicationEditor.isSaveButtonActive;

export const elementsOusideOfCanvasSelector = ({ applicationEditor }) =>
  applicationEditor.containsElementsOutside;

export const widgetsInClipboardSelector = ({ applicationEditor }) =>
  applicationEditor.widgetsInClipboard;

export const addRootNodeContextMenuSelector = ({ applicationEditor }) =>
  applicationEditor.addRootNodeContextMenu;

export const areWidgetsBeingDraggedSelector = ({applicationEditor}) =>
    applicationEditor.areWidgetsBeingDragged;

export const changesSinceSaveSelector = ({applicationEditor}) => applicationEditor.changesSinceSave;
