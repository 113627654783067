export const validFieldsList = [
  'parent',
  'node',
  'id',
  'synoptics',
  'width',
  'height',
  'equipments',
  'type',
  'color',
  'buttons',
  'labels',
  'lines',
  'alarmSummarys',
];
export const numericFields = [
  'equipmentId',
  'posX',
  'posY',
  'startX',
  'startY',
  'endX',
  'endY',
  'sizeX',
  'sizeY',
  'targetX',
  'targetY',
];
export const linkFields = ['href'];
export const booleanFields = [
  'disableSummaryAlarm',
  'disableSummaryFault',
  'realTimeTrend',
  'recursive',
];
export const dropdownFields = ['category', 'version', 'restriction'];
export const inputFields = [
  'equipmentId',
  'posX',
  'posY',
  'targetX',
  'targetY',
  'startX',
  'startY',
  'endX',
  'endX',
  'name',
  'sizeX',
  'sizeY',
  'img',
  'href',
  'info',
  'valueFormat',
  'freeText',
];

export const equipmentMSChannelFields = [
  'category',
  'equipmentId',
  'posX',
  'posY',
  'targetX',
  'targetY',
  'version',
  'restriction',
  'realTimeTrend',
  'disableSummaryFault',
  'disableSummaryAlarm',
  'valueFormat',
  'freeText',
];

export const equipmentChannelUAFields = [
  'category',
  'equipmentId',
  'posX',
  'posY',
  'targetX',
  'targetY',
  'version',
  'restriction',
  'info',
  'freeText',
];

export const ergoButtonField = ['category', 'posX', 'posY', 'realTimeTrend', 'recursive'];
export const logoutButtonField = ['category', 'posX', 'posY'];

export const alarmSummaryFields = ['posX', 'posY'];
export const lineFields = ['startX', 'startY', 'endX', 'endY'];
export const labelFields = ['name', 'posX', 'posY', 'fontSize', 'fontStyle', 'href'];
export const synopticFields = ['name', 'sizeX', 'sizeY', 'img', 'posX', 'posY',
  'color'];
