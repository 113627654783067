
export const GET_DATA_EXPORT_NXCALS= 'app/dataExportStatus/GET_DATA_EXPORT_NXCALS';
export const GET_DATA_EXPORT_NXCALS_SUCCESS = `${GET_DATA_EXPORT_NXCALS}/SUCCESS`;
export const GET_DATA_EXPORT_NXCALS_FAILURE = `${GET_DATA_EXPORT_NXCALS}/FAILURE`;

export const GET_DATA_EXPORT_INFLUX= 'app/dataExportStatus/GET_DATA_EXPORT_INFLUX';
export const GET_DATA_EXPORT_INFLUX_SUCCESS = `${GET_DATA_EXPORT_INFLUX}/SUCCESS`;
export const GET_DATA_EXPORT_INFLUX_FAILURE = `${GET_DATA_EXPORT_INFLUX}/FAILURE`;

export const GET_DATA_BY_TYPE= 'app/dataExportStatus/GET_DATA_BY_TYPE';
export const GET_DATA_BY_TYPE_SUCCESS = `${GET_DATA_BY_TYPE}/SUCCESS`;
export const GET_DATA_BY_TYPE_FAILURE = `${GET_DATA_BY_TYPE}/FAILURE`;



export default {
  GET_DATA_EXPORT_NXCALS,
  GET_DATA_EXPORT_NXCALS_SUCCESS,
  GET_DATA_EXPORT_NXCALS_FAILURE,
  GET_DATA_EXPORT_INFLUX,
  GET_DATA_EXPORT_INFLUX_SUCCESS,
  GET_DATA_EXPORT_INFLUX_FAILURE,
  GET_DATA_BY_TYPE,
  GET_DATA_BY_TYPE_SUCCESS,
  GET_DATA_BY_TYPE_FAILURE,
};
