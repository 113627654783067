import types from './types';

export const setSources = sources => ({
  type: types.SET_SOURCES,
  sources,
});

export const updateForm = (field, value) => ({
  type: types.UPDATE_FORM,
  field,
  value,
});

export const resetState = () => ({
  type: types.RESET_STATE,
});

export default {
  updateForm,
  setSources,
  resetState,
};
