import types from './types';

const start = () => ({
  type: types.START
});

const getSiteStatus = () => ({
  type: types.GET_SITE_STATUS,
  params: {}
});

export default {
  start,
  getSiteStatus
};