const DASHBOARD_ACTIONS_PREFIX = 'app/DASHBOARD';
export const CHANGE_DASHBOARD_NAME = `${DASHBOARD_ACTIONS_PREFIX}/CHANGE_DASHBOARD_NAME`;
export const UPDATE_LAYOUT = `${DASHBOARD_ACTIONS_PREFIX}/UPDATE_LAYOUT`;
export const WIDGET_STATE_CHANGED = `${DASHBOARD_ACTIONS_PREFIX}/WIDGET_STATE_CHANGED`;
export const SAVE_DASHBOARD = `${DASHBOARD_ACTIONS_PREFIX}/SAVE_DASHBOARD`;
export const ADD_WIDGET = `${DASHBOARD_ACTIONS_PREFIX}/ADD_WIDGET`;
export const REMOVE_WIDGET = `${DASHBOARD_ACTIONS_PREFIX}/REMOVE_WIDGET`;
export const LOAD_DASHBOARD = `${DASHBOARD_ACTIONS_PREFIX}/LOAD_DASHBOARD`;
export const CLEAR_DASHBOARD = `${DASHBOARD_ACTIONS_PREFIX}/CLEAR_DASHBOARD`;
export const CHANGE_PERMISSION = `${DASHBOARD_ACTIONS_PREFIX}/CHANGE_PERMISSION`;
export const DELETE_PERMISSION = `${DASHBOARD_ACTIONS_PREFIX}/DELETE_PERMISSION`;
export const APPLY_PERMISSION_TO_ALL_ROLES = `${DASHBOARD_ACTIONS_PREFIX}/APPLY_PERMISSION_TO_ALL_ROLES`;
export const CHANGE_REFRESH_CONFIG = `${DASHBOARD_ACTIONS_PREFIX}/CHANGE_REFRESH_CONFIG`;
export const SET_DIRTY = `${SET_DIRTY}/SET_DIRTY`;
export const TOGGLE_EDITION = `${DASHBOARD_ACTIONS_PREFIX}/TOGGLE_EDITION`;
export const ACKNOWLEDGE_WIDGET_RELOAD = `${DASHBOARD_ACTIONS_PREFIX}/ACKNOWLEDGE_WIDGET_RELOAD`;
export const START_WIDGET_CREATION = `${DASHBOARD_ACTIONS_PREFIX}/START_WIDGET_CREATION`;
export const START_WIDGET_EDITION = `${DASHBOARD_ACTIONS_PREFIX}/START_WIDGET_EDITION`;
export const CLONE_WIDGET = `${DASHBOARD_ACTIONS_PREFIX}/CLONE_WIDGET`;
export const EDIT_WIDGET_TITLE = `${DASHBOARD_ACTIONS_PREFIX}/EDIT_WIDGET_TITLE`;
export const EDIT_WIDGET_STATE = `${DASHBOARD_ACTIONS_PREFIX}/EDIT_WIDGET_STATE`;
export const SET_EDITED_WIDGET = `${DASHBOARD_ACTIONS_PREFIX}/SET_EDITED_WIDGET`;

export default {
  CHANGE_DASHBOARD_NAME,
  UPDATE_LAYOUT,
  WIDGET_STATE_CHANGED,
  SAVE_DASHBOARD,
  ADD_WIDGET,
  REMOVE_WIDGET,
  LOAD_DASHBOARD,
  CLEAR_DASHBOARD,
  CHANGE_PERMISSION,
  DELETE_PERMISSION,
  APPLY_PERMISSION_TO_ALL_ROLES,
  CHANGE_REFRESH_CONFIG,
  SET_DIRTY,
  TOGGLE_EDITION,
  ACKNOWLEDGE_WIDGET_RELOAD,
  START_WIDGET_CREATION,
  START_WIDGET_EDITION,
  CLONE_WIDGET,
  EDIT_WIDGET_TITLE,
  EDIT_WIDGET_STATE,
  SET_EDITED_WIDGET,
};
