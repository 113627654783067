import {Dropdown, Icon, Menu} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {VersionComponent} from 'react-lib/dist/components-es';
import {Link, NavLink, useMatch} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {userType} from '../proptypes';
import Connector from '../api/agent';
import {generalActions} from '../behavior/general';
import {PlatformRoot} from '../routes';
import {initSelectors} from '../behavior/init';
import platforms from '../platformConfig';
import './NavHeader.css';
import {Media} from '../mediaStyles';
import keycloak from '../keycloak';
import useIsMobileDisplay from '../hooks/useIsMobileDisplay';
import usePlatform from "../hooks/usePlatform";
import {Breadcrumbs} from "./Breadcrumbs";

const NavHeader = ({ onToggleSidebar, user }) => {
  const isMobileDisplay = useIsMobileDisplay();
  const { apiProd } = Connector;

  const currentPlatform = usePlatform();
  const currentSubPath = useMatch(PlatformRoot.getRoute()).params["*"]


  let userDropdownText;
  if (!isMobileDisplay) {
    if (user) {
      userDropdownText = initSelectors.getUsername(user);
    } else {
      userDropdownText = 'Not connected';
    }
  }

  return (
    <Menu inverted borderless size="large" fixed="top" fluid id="app-main-navbar">
      <Menu.Menu>

        {/* Toolbar Toggle */}
        <Menu.Item icon="sidebar" size="large" onClick={onToggleSidebar} />
        <Media greaterThanOrEqual="tablet">
          <Menu.Item as={Link} to="/">
            <img alt="CERN logo" src={`${process.env.PUBLIC_URL}/assets/imgs/cern-logo.png`} />
          </Menu.Item>
        </Media>

        {/* Logo */}
        <Menu.Item as={Link} to="/">
          <img
            alt="REMUS logo"
            src={`${process.env.PUBLIC_URL}/assets/imgs/remus_icon_256x256.png`}
          />
        </Menu.Item>

        {/* AppName & Version */}
        <Media greaterThanOrEqual="tablet">
          <Menu.Item as={Link} to="/" className="appNameAndVersion">
            REMUS Web
            <VersionComponent api={apiProd} />
          </Menu.Item>
        </Media>

        {/* Breadcrumbs */}
        <Media greaterThanOrEqual="computer">
          <Menu.Item>
            <Breadcrumbs />
          </Menu.Item>
        </Media>

      </Menu.Menu>

      <Menu.Menu position="right">
        {/* Platforms */}
        {platforms.map(platform => (
          <Menu.Item
            as={NavLink}
            name={platform}
            active={platform === currentPlatform}
            to={PlatformRoot.getLink({ platform }) + currentSubPath}
            key={platform}
            value={platform}
          >
            <Icon name="database" />
            {platform}
          </Menu.Item>
        ))}

        {/* User Menu */}
        <Menu.Item>
          <Dropdown
            trigger={
              <span>
                <Icon name="user" />
                {userDropdownText}
              </span>
            }
          >
            <Dropdown.Menu>
              {user && (
                <CopyToClipboard text={user ? user.authorities.join(', ') : ''}>
                  <Dropdown.Item data-for="roles-tooltip" data-tip="test">
                    <Icon name="users" /> Roles
                    <br />
                    <br />
                    {user.authorities.map(auth => (
                      <div style={{ fontSize: '0.7em' }} key={auth}>{auth}</div>
                    ))}
                  </Dropdown.Item>
                </CopyToClipboard>
              )}
              <Dropdown.Divider />
              <Dropdown.Item
                as="form"
                id="logout-button-form"
                method="POST"
                type="submit"
                icon="sign-out"
                text="Logout"
                onClick={() => keycloak?.logout()}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

NavHeader.propTypes = {
  onToggleSidebar: PropTypes.func.isRequired,
  user: userType.isRequired,
};

const mapStateToProps = ({ init: { user } }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  onToggleSidebar: () => dispatch(generalActions.toggleSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader);
