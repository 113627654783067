import { normalize, schema } from 'normalizr';

export const equipment = new schema.Entity('equipments');

export const button = new schema.Entity('buttons');

export const alarmSummary = new schema.Entity(
  'alarmSummarys',
  {
    equipments: [equipment],
  },
  {
    processStrategy: (value, parent) => ({
      ...value,
      parent: value.id !== parent.id ? parent.id : value.parent,
    }),
  }
);

export const label = new schema.Entity('labels');

export const line = new schema.Entity('lines');

export const synoptic = new schema.Entity(
  'synoptics',
  {
    equipments: [equipment],
    buttons: [button],
    alarmSummarys: [alarmSummary],
    labels: [label],
    lines: [line],
  },
  {
    processStrategy: (value, parent) => ({
      ...value,
      parent: value.id !== parent.id ? parent.id : value.parent,
    }),
  }
);

export const synoptics = new schema.Array(synoptic);

synoptic.define({ synoptics });

export const map = new schema.Entity('maps', {
  synoptics,
});

export const documentSchema = new schema.Entity('documents', {
  maps: [map],
});

export function normalizeTree(tree) {
  return normalize(tree, documentSchema);
}
