import { v4 as uuidv4 } from 'uuid';
import { denormalize, normalize } from 'normalizr';
import { cloneDeep } from 'lodash';
import types from './types';
import { applicationXMLCodeSelector } from './selectors';
import { xmlToJson } from '../../pages/RemusApplicationPage/applicationXmlToJson';
import { normalizeTree, synoptic as synopticSchema } from './normalize';

import { defaultLabel, defaultPositions, states, widgetTypes } from './constants';
import { defaultCanvasSize } from '../../pages/RemusApplicationPage/constants';
import { CLIPBOARD_MODE } from '../../components/ApplicationEditor/RemusApplication/constants';
import { SYNOPTIC_IMAGE_PREFIX } from '../../constants';

const defaultState = {
  selectedSynoptic: null,
  editionMode: 'graphic',
  fileEdition: null,
  preSelectedWidget: null,
  selectedWidgets: [],
  application: null,
  newSynopticContext: null,
  redo: [],
  undo: [],
  changesSinceSave: 0,
  intersection: [],
  canvasModalCoordinates: null,
  freeText: null,
  isSaveButtonActive: true,
  triedToDeletelastMainSynoptic: false,
  openChangeSynopticBackgroundModal: {
    isOpen: false,
    mode: undefined,
  },
  cutOperationValid: null,
  widgetsInClipboard: null,
};

function getEquipmentCategory(equipment) {
  if (equipment.sourceCategory === 'Channel' || equipment.category === 'Channel') {
    return 'channel';
  }
  if (equipment.sourceCategory === 'ChannelUA' || equipment.category === 'ChannelUA') {
    return 'channelUA';
  }
  return 'MS';
}

function generateUndo(state) {
  const { undo, redo, ...rest } = state;
  return [...undo, rest].slice(-states.NUM_OF_SAVED_UNDO_STATES);
}

/** */
function checkIfIsOneItsChildren(synopticToPaste, possibleChildrenId, entities) {
  const parent =
    entities?.synoptics[possibleChildrenId]?.parent || entities?.maps[possibleChildrenId]?.parent;
  if (entities?.maps[possibleChildrenId]) {
    return false;
  }

  if (synopticToPaste?.id === possibleChildrenId) {
    return true;
  }
  return checkIfIsOneItsChildren(synopticToPaste, parent, entities);
}

const filterObject = (obj, filter, filterValue) =>
  Object.keys(obj).reduce(
    (acc, val) =>
      obj[val][filter] === filterValue
        ? acc
        : {
            ...acc,
            [val]: obj[val],
          },
    {}
  );

function cloneJsonNode(node) {
  return {
    ...node,
    id: uuidv4(),
    equipments: node.equipments?.map(e => cloneJsonNode(e)),
    buttons: node.buttons?.map(e => cloneJsonNode(e)),
    synoptics: node.synoptics?.map(e => cloneJsonNode(e)),
    labels: node.labels?.map(e => cloneJsonNode(e)),
    lines: node.lines?.map(e => cloneJsonNode(e)),
    alarmSummarys: node.alarmSummarys?.map(e => cloneJsonNode(e)),
  };
}

function cloneNormalizedEntity(node) {
  return {
    ...node,
    id: uuidv4(),
    ...(node.maps && { maps: node.maps?.map(id => id) }),
    ...(node.equipments && { equipments: node.equipments?.map(id => id) }),
    ...(node.buttons && { buttons: node.buttons?.map(id => id) }),
    ...(node.synoptics && { synoptics: node.synoptics?.map(id => id) }),
    ...(node.labels && { labels: node.labels?.map(id => id) }),
    ...(node.lines && { lines: node.lines?.map(id => id) }),
    ...(node.alarmSummarys && {
      alarmSummarys: node.alarmSummarys?.map(id => id),
    }),
  };
}

// eslint-disable-next-line
export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case types.SET_SELECTED_SYNOPTIC_ID: {
      const { selectedSynoptic } = action;
      return {
        ...state,
        selectedWidgets: [],
        selectedSynoptic,
      };
    }

    case types.SET_RENAME_SYNOPTIC: {
      const { synoptic, value } = action;
      const isStringValue = value?.constructor === String;
      if (value) {
        return {
          ...state,
          entities: {
            ...state.entities,
            synoptics: {
              ...state.entities.synoptics,
              [synoptic.id]: {
                ...state.entities.synoptics[synoptic.id],
                isRenaming: isStringValue ? value.replaceAll('"', '') : value,
              },
            },
          },
        };
      }
      if (!value) {
        return {
          ...state,
          entities: {
            ...state.entities,
            synoptics: {
              ...state.entities.synoptics,
              [synoptic?.id]: {
                ...state.entities.synoptics[synoptic.id],
                name: state.entities.synoptics[synoptic.id].isRenaming ?? synoptic.name,
                isRenaming: false,
              },
            },
          },
        };
      }

      break;
    }

    case types.SET_PROPERTIES_PANEL_ELEMENT: {
      const { element } = action;
      return {
        ...state,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        displayedSettings: {
          id: element?.id,
          type: element?.type,
        },
      };
    }

    case types.SET_CLICK_ON_CANVAS: {
      const { positionX, positionY } = action;

      return {
        ...state,

        canvasModalCoordinates: { positionX, positionY },
      };
    }

    case types.SET_DELETE_LAST_MAIN_SYNOPTIC: {
      const { triedToDeletelastMainSynoptic } = action;
      return {
        ...state,
        triedToDeletelastMainSynoptic,
      };
    }

    case types.SET_REMUS_APP_FREE_TEXT: {
      const { freeText } = action;
      return {
        ...state,
        freeText,
      };
    }

    case types.SET_SAVE_BUTTON_AVAILABILITY: {
      const { isSaveButtonActive } = action;
      return {
        ...state,
        isSaveButtonActive,
      };
    }

    case types.CHANGES_SINCE_SAVE: {
      const { changesSinceSave } = action;
      return {
        ...state,
        changesSinceSave,
      };
    }

    case types.SET_SELECTED_WIDGET: {
      const { selectedWidget, ctrlCliked } = action;
      if (selectedWidget.length === 0) {
        return {
          ...state,
          selectedWidgets: [],
        };
      }

      let selectedWidgets = [...state.selectedWidgets];

      if (ctrlCliked) {
        if (!state.selectedWidgets.some(widget => widget?.id === selectedWidget?.id)) {
          selectedWidgets = [...state.selectedWidgets, selectedWidget];
        }
      } else {
        selectedWidgets = [selectedWidget];
      }

      let selectedWidgetsWithoutParent;
      if (selectedWidgets.length > 1) {
        selectedWidgetsWithoutParent = selectedWidgets.filter(
          selectedElement => selectedElement.id !== state.selectedSynoptic
        );
      }

      const selectedWidgetsArray = selectedWidgetsWithoutParent || selectedWidgets;
      const arrayWithParentAsAnId = selectedWidgetsArray.map(elementSelected => {
        if (elementSelected.type !== 'synoptic') {
          return { ...elementSelected, parent: state.selectedSynoptic };
        }

        return { ...elementSelected };
      });

      return {
        ...state,
        selectedWidgets: arrayWithParentAsAnId,
      };
    }

    case types.SET_WIDGET_DIMENSIONS: {
      const { widget, dimensions } = action;
      return {
        ...state,
        entities: {
          ...state.entities,
          [`${widget?.type}s`]: {
            ...state.entities[`${widget?.type}s`],
            [widget?.id]: {
              ...state.entities[`${widget?.type}s`][widget?.id],
              width: dimensions.width,
              height: dimensions.height,
            },
          },
        },
      };
    }

    case types.SET_WIDGETS_SELECTED_BY_DRAG: {
      const { selectedElements } = action;
      if (selectedElements.added.length === 0 && selectedElements.selected.length === 0) {
        return {
          ...state,
        };
      }
      const selectedElementsWithSelecto = selectedElements.selected;
      const arrayWithIdsSelected = selectedElementsWithSelecto.map(element => ({
        id: element.id.split('*')[1],
        type: element.id.split('*')[2],
      }));
      const arrayWithSelectedWidgets = arrayWithIdsSelected.map(obj => ({
        ...state.entities[`${obj.type}s`][obj.id],
      }));
      const arrayWithFullSynopticObjects = [];
      arrayWithSelectedWidgets.forEach(element => {
        if (element.type === 'synoptic') {
          const synoptic = denormalize(element.id, synopticSchema, state.entities);
          arrayWithFullSynopticObjects.unshift(synoptic);
        } else {
          arrayWithFullSynopticObjects.push(element);
        }
      });

      const arrayWithParentsAsAnId = arrayWithFullSynopticObjects.map(elementSelectedByDrag => ({
        ...elementSelectedByDrag,
        parent: state.selectedSynoptic,
      }));

      return {
        ...state,
        selectedWidgets: arrayWithParentsAsAnId,
      };
    }
    case types.MOVE_MULTIPLE_WIDGETS: {
      const { positionX, positionY } = action;
      const synopticSelectedSizeX = state?.entities?.synoptics[state.selectedSynoptic].sizeX;
      const synopticSelectedSizeY = state?.entities?.synoptics[state.selectedSynoptic].sizeY;
      const copyOfEntities = cloneDeep(state.entities);
      state.selectedWidgets.forEach(selectWidget => {
        const widgetId = selectWidget?.id;
        const widgetType = `${selectWidget?.type}s`;

        const widget = copyOfEntities[widgetType][widgetId];
        const modifiedCopyOfWidget = {
          ...widget,
          posX: widget.posX + positionX,
          posY: widget.posY + positionY,
        };

        const widgetWidth = widget.width ?? 0;
        const widgetHeight = widget.height ?? 0;

        modifiedCopyOfWidget.posX =
          modifiedCopyOfWidget.posX + widgetWidth > synopticSelectedSizeX
            ? synopticSelectedSizeX - widgetWidth
            : modifiedCopyOfWidget.posX;
        modifiedCopyOfWidget.posY =
          modifiedCopyOfWidget.posY + widgetHeight > synopticSelectedSizeY
            ? synopticSelectedSizeY - widgetHeight
            : modifiedCopyOfWidget.posY;

        modifiedCopyOfWidget.posX =
          modifiedCopyOfWidget.posX < 0 ? 0 : Math.round(modifiedCopyOfWidget.posX);
        modifiedCopyOfWidget.posY =
          modifiedCopyOfWidget.posY < 0 ? 0 : Math.round(modifiedCopyOfWidget.posY);

        copyOfEntities[widgetType] = {
          ...copyOfEntities[widgetType],
          [widgetId]: modifiedCopyOfWidget,
        };
      });
      return {
        ...state,
        entities: copyOfEntities,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
      };
    }

    case types.SET_OPEN_CONTEXT_MENU: {
      const { contextMenuState, coordinates, isSelectedInComponentsContained } = action;

      return {
        ...state,

        contextMenuState,
        rightClickCoordinates: coordinates,
        isSelectedInComponentsContained,
      };
    }

    case types.SET_CHANGE_SYNOPTIC_BACKGROUND_MODAL: {
      const { backgroundModalOptions } = action;

      return {
        ...state,
        openChangeSynopticBackgroundModal: {
          isOpen: backgroundModalOptions.isOpen,
          mode: backgroundModalOptions.mode,
        },
      };
    }

    case types.DELETE_WIDGET_CONFIRMATION_MODAL: {
      const { deleteWidgetConfirmation } = action;

      return {
        ...state,
        deleteWidgetConfirmation,
      };
    }

    case types.SET_APPLICATION: {
      const { application } = action;
      const { entities, result, ...metadata } = application;
      return {
        ...state,
        applicationMetadata: metadata,
        applicationParent: result,
        entities,
      };
    }
    case types.SET_APPLICATION_METADATA: {
      const { applicationMetadata } = action;
      return {
        ...state,
        applicationMetadata,
      };
    }
    case types.UPDATE_EQUIPMENT_FIELD: {
      const { id, equipmentType, field, value } = action;
      return {
        ...state,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          [equipmentType]: {
            ...state.entities[equipmentType],
            [id]: {
              ...state.entities[equipmentType][id],
              [field]: value,
            },
          },
        },
      };
    }

    case types.UPDATE_ENTITY_FIELD: {
      const { id, entityType, field, value } = action;
      const entityTypePlural = `${entityType}s`;
      const lastState = cloneDeep(state);

      const isStringValue = value.constructor === String;
      return {
        ...state,
        undo: generateUndo(lastState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          [entityTypePlural]: {
            ...state.entities[entityTypePlural],
            [id]: {
              ...state.entities[entityTypePlural][id],
              [field]: isStringValue ? value.replaceAll('"', '') : value,
            },
          },
        },
      };
    }

    case types.RESET_STATE:
      return {
        ...defaultState,
      };

    case types.SET_FILE_EDITION_CODE: {
      const { fileEditionCode } = action;
      const lastState = cloneDeep(state);
      return {
        ...state,
        undo: generateUndo(lastState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        fileEdition: {
          code: fileEditionCode,
        },
      };
    }

    case types.SET_EDITION_MODE: {
      const { editionMode } = action;
      return {
        ...state,
        editionMode,
      };
    }

    case types.INITIALIZE_TEXT_EDITION_MODE: {
      const applicationXMLCode = applicationXMLCodeSelector({ applicationEditor: state });
      return {
        ...state,
        fileEdition: {
          code: applicationXMLCode,
        },
      };
    }
    case types.FINISH_TEXT_EDITION_MODE: {
      if (state?.fileEdition) {
        const { code } = state?.fileEdition;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(code, 'text/xml');
        const applicationJson = xmlToJson(xmlDoc);
        const normalizedApplication = normalizeTree(applicationJson);
        const { entities, result } = normalizedApplication;
        return {
          ...state,
          applicationParent: result,
          selectedSynoptic: applicationJson?.maps[0]?.synoptics[0]?.id,
          selectedWidgets: [applicationJson?.maps[0]?.synoptics[0]],
          entities,
          fileEdition: null,
        };
      }

      return {
        ...state,
      };
    }

    case types.ADD_EQUIPMENT: {
      const { equipment } = action;
      const idRandom = uuidv4();
      state.preSelectedWidget = equipment;

      const undoState = cloneDeep(state);
      undoState.displayWidgetsModal = false;
      return {
        ...state,
        undo: generateUndo(undoState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          equipments: {
            ...state.entities.equipments,
            [idRandom]: {
              id: idRandom,
              type: 'equipment',
              parent: state.entities.synoptics[state.selectedSynoptic]?.node,
              posX: defaultPositions.posX,
              posY: defaultPositions.posY,
              targetX: equipment?.targetX,
              targetY: equipment?.targetY,
              version: equipment?.version?.toLowerCase(),
              equipmentId: equipment?.sourceId,
              category: getEquipmentCategory(equipment),
              preSelectedWidget: state?.preSelectedWidget,
            },
          },
          synoptics: {
            ...state.entities.synoptics,
            [state.selectedSynoptic]: {
              ...state.entities.synoptics[state.selectedSynoptic],
              equipments: [
                ...(state.entities.synoptics[state.selectedSynoptic].equipments ?? []),
                idRandom,
              ],
            },
          },
        },
      };
    }

    case types.ADD_BUTTON: {
      const { buttonCategory } = action;
      const idRandom = uuidv4();

      const undoState = cloneDeep(state);
      undoState.displayWidgetsModal = false;

      return {
        ...state,
        undo: generateUndo(undoState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          buttons: {
            ...state.entities.buttons,
            [idRandom]: {
              id: idRandom,
              type: 'button',
              parent: state.entities.synoptics[state.selectedSynoptic]?.node,
              posX: defaultPositions.posX,
              posY: defaultPositions.posY,
              category: buttonCategory.category ?? buttonCategory?.toLowerCase(),
            },
          },
          synoptics: {
            ...state.entities.synoptics,
            [state.selectedSynoptic]: {
              ...state.entities.synoptics[state.selectedSynoptic],
              buttons: [
                ...(state.entities.synoptics[state.selectedSynoptic].buttons ?? []),
                idRandom,
              ],
            },
          },
        },
      };
    }

    case types.ADD_ALARM: {
      const idRandom = uuidv4();
      const undoState = cloneDeep(state);
      undoState.displayWidgetsModal = false;
      return {
        ...state,
        undo: generateUndo(undoState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          alarmSummarys: {
            ...state.entities.alarmSummarys,
            [idRandom]: {
              id: idRandom,
              type: 'alarmSummary',
              parent: state.entities.synoptics[state.selectedSynoptic]?.node,
              posX: defaultPositions.posX,
              posY: defaultPositions.posY,
            },
          },
          synoptics: {
            ...state.entities.synoptics,
            [state.selectedSynoptic]: {
              ...state.entities.synoptics[state.selectedSynoptic],
              alarmSummarys: [
                ...(state.entities.synoptics[state.selectedSynoptic].alarmSummarys ?? []),
                idRandom,
              ],
            },
          },
        },
      };
    }

    case types.ADD_EQUIPMENT_TO_ALARM: {
      const { equipment } = action;
      const stateCopy = cloneDeep(state);

      stateCopy.displayWidgetsModal = false;

      let stateWithEquipmentAdded = stateCopy;

      stateWithEquipmentAdded = {
        ...stateCopy.entities.alarmSummarys[state.selectedWidgets[0].id],

        equipments: [
          ...(stateCopy.entities.alarmSummarys[state.selectedWidgets[0].id].equipments ?? []),
          equipment.id,
        ],
      };

      return {
        ...state,
        undo: generateUndo(stateCopy),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          equipments: {
            ...state.entities.equipments,
            [equipment.id]: equipment,
          },

          alarmSummarys: {
            ...state.entities.alarmSummarys,
            [state.selectedWidgets[0].id]: {
              ...state.entities.alarmSummarys[state.selectedWidgets[0].id],
              equipments: [...stateWithEquipmentAdded.equipments],
            },
          },
        },
      };
    }

    case types.REMOVE_EQUIPMENT_TO_ALARM: {
      const { equipment } = action;
      const stateCopy = cloneDeep(state);
      const stateToRemoveEquipment = cloneDeep(state.entities.equipments);
      let stateWithEquipmentAdded = stateCopy;
      stateWithEquipmentAdded = stateCopy.entities.alarmSummarys[
        state.selectedWidgets[0].id
      ].equipments.filter(e => e !== equipment.id);
      delete stateToRemoveEquipment[equipment.id];

      return {
        ...state,
        undo: generateUndo(stateCopy),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          equipments: stateToRemoveEquipment,
          alarmSummarys: {
            ...state.entities.alarmSummarys,
            [state.selectedWidgets[0].id]: {
              ...state.entities.alarmSummarys[state.selectedWidgets[0].id],
              equipments: stateWithEquipmentAdded,
            },
          },
        },
      };
    }

    case types.ADD_LINE: {
      const idRandom = uuidv4();
      const undoState = cloneDeep(state);
      undoState.displayWidgetsModal = false;
      return {
        ...state,
        undo: generateUndo(undoState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          lines: {
            ...state.entities.lines,
            [idRandom]: {
              id: idRandom,
              type: 'line',
              parent: state.entities.synoptics[state.selectedSynoptic]?.node,
              startX: defaultPositions.startX,
              startY: defaultPositions.startY,
              endX: defaultPositions.endX,
              endY: defaultPositions.endY,
            },
          },
          synoptics: {
            ...state.entities.synoptics,
            [state.selectedSynoptic]: {
              ...state.entities.synoptics[state.selectedSynoptic],
              lines: [...(state.entities.synoptics[state.selectedSynoptic].lines ?? []), idRandom],
            },
          },
        },
      };
    }

    case types.ADD_LABEL: {
      const { label } = action;
      const idRandom = uuidv4();
      const undoState = cloneDeep(state);
      undoState.displayWidgetsModal = false;
      return {
        ...state,
        undo: generateUndo(undoState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          labels: {
            ...state.entities.labels,
            [idRandom]: {
              id: idRandom,
              type: 'label',
              parent: state.entities.synoptics[state.selectedSynoptic]?.node,
              posX: defaultPositions.posX,
              posY: defaultPositions.posY,
              name: label.name ?? 'undefined label',
              fontSize: label.fontSize ?? defaultLabel.fontSize,
              fontStyle: label.fontStyle,
              href: '',
            },
          },
          synoptics: {
            ...state.entities.synoptics,
            [state.selectedSynoptic]: {
              ...state.entities.synoptics[state.selectedSynoptic],
              labels: [
                ...(state.entities.synoptics[state.selectedSynoptic].labels ?? []),
                idRandom,
              ],
            },
          },
        },
      };
    }

    case types.DELETE_WIDGET: {
      const { deleteMode } = action;
      const stateCopy = cloneDeep(state);
      stateCopy.deleteWidgetConfirmation = false;
      stateCopy.contextMenuState = null;
      const elementsToDelete =
        deleteMode === CLIPBOARD_MODE.CUT ? state?.elementsAvailableToCut : state.selectedWidgets;

      //Create copy of entities to allow undo/redo
      const entities = { ...state.entities };

      // Remove each widget from its corresponding widget type in entities;
      elementsToDelete?.reduce((newEntities, allSelectedWidgets) => {
        Array.from(allSelectedWidgets).forEach(selectedWidget => {
          const widgetType = `${selectedWidget.type}s`;
          // A copy of the object holding the entities of this type also needs to be done
          const entitiesOfType = { ...newEntities[widgetType] };
          newEntities[widgetType] = entitiesOfType;
        });

        return newEntities;
      }, entities);
      // Make a copy of the selected synoptic
      // Remove the widgets from the corresponding id lists in the synoptic

      elementsToDelete?.forEach(selectedWidget => {
        const selectedSynoptic = { ...entities.synoptics[selectedWidget.parent] };

        const widgetType = `${selectedWidget.type}s`;
        selectedSynoptic[widgetType] = selectedSynoptic[widgetType]?.filter(
          id => id !== selectedWidget?.id
        );
        // Replace the old synoptic with the new copy
        entities.synoptics[selectedWidget.parent] = selectedSynoptic;
      });

      return {
        ...state,
        undo: generateUndo(stateCopy),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        elementsAvailableToCut: [],
        deleteWidgetConfirmation: false,
        selectedWidgets: [],
        widgetsInClipboard: [],
        entities,
      };
    }

    case types.DELETE_WIDGETS_IN_CLIPBOARD: {
      const stateCopy = cloneDeep(state);

      const entitiesCopy = cloneDeep(state.entities);

      //Create copy of entities to allow undo/redo

      const widgetsInClipboard = [...state.elementsAvailableToCut];

      let filteredByKey = null;
      widgetsInClipboard.forEach(elementSelected => {
        if (
          elementSelected !== 'synoptic' &&
          state.cutOperationValid.some(e => e.id === elementSelected.id)
        ) {
          const elementType = `${elementSelected?.type}s`;
          const elementId = elementSelected?.id;
          const synopticParent =
            entitiesCopy?.maps?.[elementSelected.parent]?.[elementType] ||
            entitiesCopy?.synoptics?.[elementSelected.parent]?.[elementType];

          const myIndex = synopticParent.indexOf(elementId);
          if (myIndex !== -1) {
            synopticParent.splice(myIndex, 1);
          }
          delete entitiesCopy[elementType][elementId];
        } else if (elementSelected === 'synoptic') {
          const synopticParent = elementSelected.parent;
          const parentIsNotMapsNodeOrIsMapsButHasBrothers =
            !entitiesCopy?.maps[synopticParent] ||
            (entitiesCopy?.maps[synopticParent] &&
              entitiesCopy?.maps[synopticParent].synoptics.length > 1);

          if (parentIsNotMapsNodeOrIsMapsButHasBrothers) {
            const outOfParent =
              entitiesCopy?.synoptics[synopticParent]?.synoptics.filter(
                syn => syn !== elementSelected.id
              ) ??
              entitiesCopy?.maps[synopticParent]?.synoptics.filter(
                syn => syn !== elementSelected.id
              );
            filteredByKey = filterObject(entitiesCopy.synoptics, 'id', elementSelected?.id);
            if (entitiesCopy.maps[synopticParent]) {
              entitiesCopy.maps[synopticParent].synoptics = Object.values(outOfParent);
            } else {
              filteredByKey[synopticParent].synoptics = Object.values(outOfParent);
            }
          }
        }
      });

      return {
        ...state,
        undo: generateUndo(stateCopy),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        widgetsInClipboard: [],
        deleteWidgetConfirmation: false,
        entities: entitiesCopy,
        selectedWidgets: [],
      };
    }

    case types.COPY_MULTIPLE_WIDGETS: {
      let entitiesModified = cloneDeep(state.entities);
      const newWidgets = [];
      const selectedWidgetsWithSynopticsFirst = [];
      state.selectedWidgets.forEach(selectedElement => {
        if (selectedElement.type === 'synoptic') {
          selectedWidgetsWithSynopticsFirst.unshift(selectedElement);
        } else {
          selectedWidgetsWithSynopticsFirst.push(selectedElement);
        }
      });

      selectedWidgetsWithSynopticsFirst.forEach(selectedWidget => {
        if (selectedWidget?.type === 'synoptic') {
          const clonedSynoptic = cloneJsonNode(selectedWidget);
          const normalization = normalize(clonedSynoptic, synopticSchema);
          const { entities: clonedEntities } = normalization;
          let newEntities = Object.entries(entitiesModified).reduce((obj, [key, val]) => {
            obj[key] = { ...(val ?? {}), ...(clonedEntities[key] ?? {}) };
            return obj;
          }, {});

          const parentType = state.entities.maps[selectedWidget.parent] ? 'maps' : 'synoptics';
          newEntities = {
            ...newEntities,
            [parentType]: {
              ...newEntities[parentType],
              [selectedWidget.parent]: {
                ...newEntities[parentType][selectedWidget.parent],
                synoptics: [
                  ...(newEntities[parentType][selectedWidget.parent]?.synoptics ?? []),
                  clonedSynoptic.id,
                ],
              },
            },
          };
          entitiesModified = { ...newEntities };
          newWidgets.push(newEntities.synoptics[clonedSynoptic.id]);
        } else {
          const newElement = cloneNormalizedEntity(
            entitiesModified[`${selectedWidget?.type}s`][selectedWidget?.id]
          );

          entitiesModified[`${selectedWidget?.type}s`][newElement.id] = newElement;
          newWidgets.push(entitiesModified[`${selectedWidget?.type}s`][newElement.id]);
          if (entitiesModified.synoptics[state.selectedSynoptic][`${selectedWidget.type}s`]) {
            entitiesModified.synoptics[state.selectedSynoptic][`${selectedWidget.type}s`] = [
              ...entitiesModified.synoptics[state.selectedSynoptic][`${selectedWidget.type}s`],
              newElement.id,
            ];
          } else {
            entitiesModified.synoptics[state.selectedSynoptic][`${selectedWidget.type}s`] = [
              newElement.id,
            ];
          }

          if (
            selectedWidget.type === widgetTypes.alarmSummary &&
            entitiesModified.alarmSummarys[selectedWidget.id].equipments
          ) {
            const newEquipmentsArray = [];
            let entitiesWithEquipmentsModified = { ...entitiesModified.equipments };
            entitiesModified.alarmSummarys[selectedWidget.id].equipments.forEach(equipmentId => {
              const idRandom = uuidv4();
              newEquipmentsArray.push(idRandom);
              const alarmOldEquipment = {
                ...entitiesModified.equipments[equipmentId],
                id: idRandom,
              };
              entitiesWithEquipmentsModified = {
                ...entitiesWithEquipmentsModified,
                [idRandom]: alarmOldEquipment,
              };
            });
            entitiesModified.alarmSummarys[newElement.id].equipments = newEquipmentsArray;
            entitiesModified.equipments = {
              ...entitiesWithEquipmentsModified,
            };
          }
        }
      });

      return {
        ...state,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        selectedWidgets: newWidgets,
        entities: entitiesModified,
      };
    }

    case types.PASTE_MULTIPLE_WIDGETS_FROM_CLIPBOARD: {
      const { parentSynoptic } = action;
      const stateCopy = cloneDeep(state);
      stateCopy.contextMenuState = null;
      const parentSelectedForTheWidgets = parentSynoptic || state.selectedSynoptic;
      const cutAndPasteMode = state.widgetsInClipboard?.mode === CLIPBOARD_MODE.CUT;
      const elementsCleaned = cutAndPasteMode
        ? state.widgetsInClipboard?.widgets.filter(elementToClean => {
            const isAWidgetAndIsNotPastedInTheCurrentSynoptic =
              elementToClean.type !== 'synoptic' &&
              elementToClean.parent !== parentSelectedForTheWidgets;
            const isSynopticAndIsNotBeingCopiedItsSelf =
              elementToClean.type === 'synoptic' &&
              parentSelectedForTheWidgets !== elementToClean.id;
            const elementToCleanParentIsNotMaps = !state?.entities?.maps[elementToClean.parent];
            const elementToCleanParentIsMapsButHasBrothers =
              state?.entities?.maps[elementToClean.parent] &&
              state?.entities?.maps[elementToClean.parent].synoptics.length > 1 &&
              elementToClean.parent !== parentSelectedForTheWidgets;

            return (
              isAWidgetAndIsNotPastedInTheCurrentSynoptic ||
              (isSynopticAndIsNotBeingCopiedItsSelf &&
                elementToClean.type === 'synoptic' &&
                elementToClean.parent !== parentSelectedForTheWidgets &&
                !checkIfIsOneItsChildren(elementToClean, parentSynoptic, state.entities) &&
                (elementToCleanParentIsNotMaps || elementToCleanParentIsMapsButHasBrothers))
            );
          })
        : state.widgetsInClipboard?.widgets;

      let entitiesModified = cloneDeep(state.entities);
      const newWidgets = [];

      const cutOperationWasValidForThisSynoptics = elementsCleaned?.filter(
        e => e.type === 'synoptic'
      );

      const selectedWidgetsWithSynopticsFirst = [];
      elementsCleaned?.forEach(selectedElement => {
        if (selectedElement.type === 'synoptic') {
          selectedWidgetsWithSynopticsFirst.unshift(selectedElement);
        } else {
          selectedWidgetsWithSynopticsFirst.push(selectedElement);
        }
      });
      selectedWidgetsWithSynopticsFirst.forEach(selectedWidget => {
        if (selectedWidget?.type === 'synoptic') {
          let clonedSynoptic = cloneJsonNode(selectedWidget);
          const normalization = normalize(clonedSynoptic, synopticSchema);
          const { entities: clonedEntities } = normalization;
          let newEntities = Object.entries(entitiesModified).reduce((obj, [key, val]) => {
            obj[key] = { ...(val ?? {}), ...(clonedEntities[key] ?? {}) };
            return obj;
          }, {});

          clonedSynoptic = {
            ...clonedSynoptic,
            parent: parentSelectedForTheWidgets,
          };
          const parentType = state.entities.maps[parentSelectedForTheWidgets]
            ? 'maps'
            : 'synoptics';
          newEntities = {
            ...newEntities,
            [parentType]: {
              ...newEntities[parentType],
              [parentSelectedForTheWidgets]: {
                ...newEntities[parentType][parentSelectedForTheWidgets],
                synoptics: [
                  ...(newEntities[parentType][parentSelectedForTheWidgets]?.synoptics ?? []),
                  clonedSynoptic.id,
                ],
              },
            },
          };
          newEntities.synoptics[clonedSynoptic.id].parent = parentSelectedForTheWidgets;
          entitiesModified = { ...newEntities };
          newWidgets.push(newEntities.synoptics[clonedSynoptic.id]);
        } else {
          let newElement = cloneJsonNode(
            entitiesModified[`${selectedWidget?.type}s`][selectedWidget?.id]
          );

          newElement = { ...newElement, parent: parentSelectedForTheWidgets };

          entitiesModified[`${selectedWidget?.type}s`][newElement.id] = newElement;

          newWidgets.push(entitiesModified[`${selectedWidget?.type}s`][newElement.id]);
          if (entitiesModified.synoptics[parentSelectedForTheWidgets][`${selectedWidget.type}s`]) {
            entitiesModified.synoptics[parentSelectedForTheWidgets][`${selectedWidget.type}s`] = [
              ...entitiesModified.synoptics[parentSelectedForTheWidgets][`${selectedWidget.type}s`],
              newElement.id,
            ];
          } else {
            entitiesModified.synoptics[parentSelectedForTheWidgets][`${selectedWidget.type}s`] = [
              newElement.id,
            ];
          }

          if (
            selectedWidget.type === widgetTypes.alarmSummary &&
            entitiesModified.alarmSummarys[selectedWidget.id].equipments
          ) {
            const newEquipmentsArray = [];
            let entitiesWithEquipmentsModified = { ...entitiesModified.equipments };
            entitiesModified.alarmSummarys[selectedWidget.id].equipments.forEach(equipmentId => {
              const idRandom = uuidv4();
              newEquipmentsArray.push(idRandom);
              const alarmOldEquipment = {
                ...entitiesModified.equipments[equipmentId],
                id: idRandom,
              };
              entitiesWithEquipmentsModified = {
                ...entitiesWithEquipmentsModified,
                [idRandom]: alarmOldEquipment,
              };
            });
            entitiesModified.alarmSummarys[newElement.id].equipments = newEquipmentsArray;
            entitiesModified.equipments = {
              ...entitiesWithEquipmentsModified,
            };
          }
        }
      });
      return {
        ...state,
        undo: generateUndo(stateCopy),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        selectedWidgets: newWidgets,
        entities: entitiesModified,
        cutOperationValid: cutOperationWasValidForThisSynoptics,
        elementsAvailableToCut: elementsCleaned,
      };
    }

    case types.NEW_SYNOPTIC_CONTEXT_MENU: {
      const { newSynopticContext } = action;
      return {
        ...state,
        newSynopticContext,
      };
    }
    case types.CLONE_SYNOPTIC: {
      const { synoptic } = action;

      const clonedSynoptic = cloneJsonNode(synoptic);
      const normalization = normalize(clonedSynoptic, synopticSchema);
      const { entities: clonedEntities } = normalization;
      let newEntities = Object.entries(state.entities).reduce((obj, [key, val]) => {
        obj[key] = { ...(val ?? {}), ...(clonedEntities[key] ?? {}) };
        return obj;
      }, {});

      const parentType = state.entities.maps[synoptic.parent] ? 'maps' : 'synoptics';
      newEntities = {
        ...newEntities,
        [parentType]: {
          ...newEntities[parentType],
          [synoptic.parent]: {
            ...newEntities[parentType][synoptic.parent],
            synoptics: [
              ...(newEntities[parentType][synoptic.parent]?.synoptics ?? []),
              clonedSynoptic.id,
            ],
          },
        },
      };

      return {
        ...state,
        entities: newEntities,
      };
    }
    case types.DELETE_SYNOPTIC: {
      const { synopticToDelete } = action;
      const lastState = cloneDeep(state);
      lastState.contextMenuState = null;
      lastState.deleteWidgetConfirmation = null;
      const synoptic = synopticToDelete ?? state.elementsAvailableToCut[0];

      if (synoptic) {
        const synopticParent = synoptic.parent;
        const parentIsNotMapsNodeOrIsMapsButHasBrothers =
          !state?.entities?.maps[synopticParent] ||
          (state?.entities?.maps[synopticParent] &&
            state?.entities?.maps[synopticParent].synoptics.length > 1);

        if (parentIsNotMapsNodeOrIsMapsButHasBrothers) {
          const outOfParent =
            state?.entities?.synoptics[synopticParent]?.synoptics.filter(
              syn => syn !== synoptic.id
            ) ??
            state?.entities?.maps[synopticParent]?.synoptics.filter(syn => syn !== synoptic.id);
          const filteredByKey = filterObject(state.entities.synoptics, 'id', synoptic?.id);
          if (state.entities.maps[synopticParent]) {
            state.entities.maps[synopticParent].synoptics = Object.values(outOfParent);
          } else {
            filteredByKey[synopticParent].synoptics = Object.values(outOfParent);
          }

          return {
            ...state,
            undo: generateUndo(lastState),
            changesSinceSave: ++state.changesSinceSave,
            redo: [],
            deleteWidgetConfirmation: false,
            selectedWidgets: [],

            entities: {
              ...state.entities,
              synoptics: filteredByKey,
            },
          };
        }
        return {
          ...state,
          undo: generateUndo(lastState),
          changesSinceSave: ++state.changesSinceSave,
          redo: [],
          selectedWidgets: [],
          deleteWidgetConfirmation: false,
          triedToDeletelastMainSynoptic: true,
        };
      }
      return {
        ...state,
        undo: generateUndo(lastState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        selectedWidgets: [],
        deleteWidgetConfirmation: false,
      };
    }
    case types.DELETE_ELEMENTS_SELECTED: {
      const { synoptic } = action;
      const lastState = cloneDeep(state);

      const elementsToDelete =
        state.elementsAvailableToCut || state.widgetsInClipboard?.widgets || state.selectedWidgets;
      let newEntitiesState = cloneDeep(state.entities);

      elementsToDelete.forEach(elementToDelete => {
        if (elementToDelete.type === 'synoptic') {
          const synopticParent = synoptic.parent;
          const parentIsNotMapsNodeOrIsMapsButHasBrothers =
            !state?.entities?.maps[synoptic.parent] ||
            (state?.entities?.maps[synoptic.parent] &&
              state?.entities?.maps[synoptic.parent].synoptics.length > 1);

          if (parentIsNotMapsNodeOrIsMapsButHasBrothers) {
            const outOfParent =
              state?.entities?.synoptics[synopticParent]?.synoptics.filter(
                syn => syn !== synoptic.id
              ) ??
              state?.entities?.maps[synopticParent]?.synoptics.filter(syn => syn !== synoptic.id);
            const filteredByKey = filterObject(state.entities.synoptics, 'id', synoptic?.id);
            if (state.entities.maps[synopticParent]) {
              state.entities.maps[synopticParent].synoptics = Object.values(outOfParent);
            } else {
              filteredByKey[synopticParent].synoptics = Object.values(outOfParent);
            }

            newEntitiesState = { ...newEntitiesState, synoptics: filteredByKey };
          }
        }
      });

      return {
        ...state,
        undo: generateUndo(lastState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        deleteWidgetConfirmation: false,
        selectedWidgets: [],
        entities: {
          ...newEntitiesState,
        },
      };
    }

    case types.NEW_SYNOPTIC_MENU: {
      const { synoptic } = action;
      const idRandom = uuidv4();
      const synopticId = state.newSynopticContext?.id ?? state.selectedWidgets[0].id;

      const newSynoptic = {
        ...synoptic,
        id: idRandom,
        img: synoptic?.img ? `${`${SYNOPTIC_IMAGE_PREFIX}${synoptic.img}`}` : '',
        name: synoptic.name,
        parent: synopticId,
        type: 'synoptic',
        sizeX: synoptic.sizeX ?? defaultCanvasSize.sizeX,
        sizeY: synoptic.sizeY ?? defaultCanvasSize.sizeY,
        posX: synoptic.posX ?? Math.floor(Math.random() * 677),
        posY: synoptic.posY ?? Math.floor(Math.random() * 560),
      };
      return {
        ...state,
        selectedSynoptic: idRandom,
        selectedWidgets: [newSynoptic],
        deleteWidgetConfirmation: false,
        entities: {
          ...state.entities,
          synoptics: {
            ...state.entities.synoptics,
            [idRandom]: newSynoptic,
            [synopticId]: {
              ...(state.entities.synoptics[synopticId] ?? []),
              synoptics: [...(state.entities?.synoptics[synopticId]?.synoptics ?? []), idRandom],
            },
          },
        },
      };
    }

    case types.DISPLAY_MODAL_WIDGETS: {
      const { boolean } = action;
      return {
        ...state,
        displayWidgetsModal: boolean,
      };
    }

    case types.MOVE_SYNOPTIC_UP_ITS_PARENT: {
      const { synoptic } = action;
      const synopticGrandparentId = state.entities.synoptics[synoptic.parent].parent;
      const synopticGrandparentObject = state.entities.synoptics[synopticGrandparentId];
      const outOfParent = state?.entities?.synoptics[synoptic.parent]?.synoptics.filter(
        syn => syn !== synoptic.id
      );
      const filteredByKey = filterObject(state.entities.synoptics, 'id', synoptic.id);
      filteredByKey[synoptic.parent].synoptics = Object.values(outOfParent);
      return {
        ...state,
        entities: {
          ...state.entities,
          synoptics: {
            ...filteredByKey,
            [synoptic.id]: {
              ...(state.entities.synoptics[synoptic.id] ?? {}),
              parent: state.entities.synoptics[synoptic.parent].parent,
            },
            [synopticGrandparentId]: {
              ...synopticGrandparentObject,
              synoptics: [...synopticGrandparentObject.synoptics, synoptic.id],
            },
          },
        },
      };
    }

    case types.SET_CLIPBOARD_FOR_WIDGETS: {
      const { mode } = action;
      const widgetsInClipboard = [...state.selectedWidgets];

      const elements = [];
      widgetsInClipboard.forEach(element => {
        if (element.type !== 'synoptic') {
          const newElementWithProperParent = {
            ...element,
            parent: state.selectedSynoptic,
          };
          elements.push(newElementWithProperParent);
        } else {
          elements.push(element);
        }
      });
      return {
        ...state,
        widgetsInClipboard: {
          ...state.widgetsInClipboard,
          widgets: elements,
          mode,
        },
        contextMenuState: null,
        rightClickCoordinates: null,
      };
    }

    case types.PASTE_SYNOPTIC: {
      const { idParent, synoptic } = action;
      const stateCopy = cloneDeep(state);
      const parentIsNotMapsNodeOrIsMapsButHasBrothers =
        !state?.entities?.maps[synoptic.parent] ||
        (state?.entities?.maps[synoptic.parent] &&
          state?.entities?.maps[synoptic.parent].synoptics.length > 1);

      const cutOperationValid =
        parentIsNotMapsNodeOrIsMapsButHasBrothers &&
        state.clipboard.mode === CLIPBOARD_MODE.CUT &&
        !checkIfIsOneItsChildren(synoptic, idParent, state.entities);

      if (cutOperationValid || state.clipboard.mode !== CLIPBOARD_MODE.CUT) {
        const clonedSynoptic = cloneJsonNode(synoptic);
        const clonedSynopticWithProperParent = {
          ...clonedSynoptic,
          parent: idParent,
        };
        const normalization = normalize(clonedSynopticWithProperParent, synopticSchema);
        const { entities: clonedEntities } = normalization;

        let newEntities = Object.entries(state.entities).reduce((obj, [key, val]) => {
          obj[key] = { ...(val ?? {}), ...(clonedEntities[key] ?? {}) };
          return obj;
        }, {});

        const parentType = state.entities.maps[idParent] ? 'maps' : 'synoptics';
        newEntities = {
          ...newEntities,
          [parentType]: {
            ...newEntities[parentType],
            [idParent]: {
              ...newEntities[parentType][idParent],
              synoptics: [
                ...(newEntities[parentType][idParent]?.synoptics ?? []),
                clonedSynopticWithProperParent.id,
              ],
            },
          },
        };

        if (cutOperationValid) {
          const outOfParent =
            newEntities?.synoptics[synoptic?.parent]?.synoptics?.filter(
              syn => syn !== synoptic.id
            ) ?? newEntities?.maps[synoptic?.parent]?.synoptics?.filter(syn => syn !== synoptic.id);
          const filteredByKey = filterObject(newEntities.synoptics, 'id', synoptic?.id);
          if (newEntities.maps[synoptic?.parent]) {
            newEntities.maps[synoptic?.parent].synoptics = Object.values(outOfParent);
          } else {
            filteredByKey[synoptic?.parent].synoptics = Object.values(outOfParent);
          }
        }

        return {
          ...state,
          undo: generateUndo(stateCopy),
          changesSinceSave: ++state.changesSinceSave,
          redo: [],
          selectedSynoptic: clonedSynopticWithProperParent.id,
          entities: newEntities,
          clipboard: {
            ...state.clipboard,
            synoptic,
            mode: state.clipboard.mode,
          },
          cutOperationValid: null,
        };
      }

      return {
        ...state,

        selectedWidgets: [],
        triedToDeletelastMainSynoptic: !parentIsNotMapsNodeOrIsMapsButHasBrothers,
        cutOperationValid,
      };
    }

    case types.MOVE_SYNOPTIC_UP_INSIDE_PARENT: {
      const { synoptic } = action;
      const parentChildren = state.entities.synoptics[synoptic.parent]?.synoptics
        ? [...state.entities.synoptics[synoptic.parent]?.synoptics]
        : [...state.entities.maps[synoptic.parent]?.synoptics];

      const synopticIndex = parentChildren.indexOf(synoptic.id);
      if (synopticIndex !== 0) {
        const synopticIdBefore = state.entities.synoptics[synoptic.parent]?.synoptics
          ? state.entities.synoptics[synoptic.parent].synoptics[synopticIndex - 1]
          : state.entities.maps[synoptic.parent].synoptics[synopticIndex - 1];

        parentChildren[synopticIndex - 1] = synoptic.id;
        parentChildren[synopticIndex] = synopticIdBefore;
      }

      if (!state.entities.maps[synoptic.parent]) {
        return {
          ...state,
          undo: generateUndo(state),
          changesSinceSave: ++state.changesSinceSave,
          redo: [],
          entities: {
            ...state.entities,
            synoptics: {
              ...state.entities.synoptics,
              [synoptic.parent]: {
                ...(state.entities.synoptics[synoptic.parent] ?? {}),
                synoptics: parentChildren,
              },
            },
          },
        };
      }

      return {
        ...state,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          maps: {
            ...state.entities.maps,
            [synoptic.parent]: {
              ...(state.entities.maps[synoptic.parent] ?? {}),
              synoptics: parentChildren,
            },
          },
        },
      };
    }

    case types.MOVE_SYNOPTIC_DOWN_INSIDE_PARENT: {
      const { synoptic } = action;
      let parentChildren = null;
      if (state.entities.synoptics[synoptic.parent]) {
        parentChildren = [...state.entities.synoptics[synoptic.parent].synoptics];
      } else {
        parentChildren = [...state.entities.maps[synoptic.parent].synoptics];
      }

      const synopticIndex = parentChildren.indexOf(synoptic.id);

      if (synopticIndex < parentChildren.length - 1) {
        let synopticIdAfter = null;

        synopticIdAfter = state.entities.synoptics[synoptic.parent]
          ? state.entities.synoptics[synoptic.parent].synoptics[synopticIndex + 1]
          : (synopticIdAfter = state.entities.maps[synoptic.parent].synoptics[synopticIndex + 1]);

        parentChildren[synopticIndex + 1] = synoptic.id;
        parentChildren[synopticIndex] = synopticIdAfter;
      }
      if (state.entities.synoptics[synoptic.parent]) {
        return {
          ...state,
          entities: {
            ...state.entities,
            synoptics: {
              ...state.entities.synoptics,
              [synoptic.parent]: {
                ...(state.entities.synoptics[synoptic.parent] ?? {}),
                synoptics: parentChildren,
              },
            },
          },
        };
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          maps: {
            ...state.entities.maps,
            [synoptic.parent]: {
              ...(state.entities.maps[synoptic.parent] ?? {}),
              synoptics: parentChildren,
            },
          },
        },
      };
    }

    case types.CHANGE_SYNOPTIC_BACKGROUND: {
      const { background } = action;

      const synopticId = state.newSynopticContext?.id ?? state.selectedWidgets[0].id;

      return {
        ...state,
        openChangeSynopticBackgroundModal: {
          ...state.openChangeSynopticBackgroundModal,
          isOpen: false,
        },

        entities: {
          ...state.entities,
          synoptics: {
            ...state.entities.synoptics,
            [synopticId]: {
              ...state.entities.synoptics[synopticId],

              img: background ? `${`${SYNOPTIC_IMAGE_PREFIX}${background}`}` : '',
            },
          },
        },
      };
    }

    case types.SET_CANVAS_COLOR: {
      const { color } = action;
      return {
        ...state,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        entities: {
          ...state.entities,
          synoptics: {
            ...state.entities.synoptics,
            [state.selectedSynoptic]: {
              ...state.entities.synoptics[state.selectedSynoptic],
              color,
            },
          },
        },
      };
    }

    case types.EXECUTE_UNDO: {
      if (state?.undo?.length - 1 >= 0) {
        const lastUndoState = { ...state.undo[state.undo.length - 1] };
        lastUndoState.undo = state.undo;
        lastUndoState.redo = state.redo;
        state.redo.push((({ undo, redo, ...o }) => o)(state));
        state.undo.pop([state.undo.length - 1]);
        return lastUndoState;
      }
      return state;
    }
    case types.EXECUTE_REDO: {
      if (state.redo.length - 1 >= 0) {
        const lastRedoState = { ...state.redo[state.redo.length - 1] };
        lastRedoState.undo = state.undo;
        lastRedoState.redo = state.redo;

        lastRedoState.undo.push((({ undo, redo, ...o }) => o)(state));
        state.redo.pop([state?.undo?.length - 1]);
        return lastRedoState;
      }
      return state;
    }

    case types.ALIGN_VERTICALLY: {
      const { direction } = action;

      const entitiesModified = { ...state.entities };

      const synopticSizeY =
        direction === 'top' ? entitiesModified?.synoptics[state.selectedSynoptic].sizeY : 0;

      let maxValue = synopticSizeY;
      if (state.selectedWidgets.length > 1) {
        state.selectedWidgets.forEach(selectWidget => {
          const condition =
            direction === 'top'
              ? entitiesModified[`${selectWidget.type}s`][selectWidget.id].posY < maxValue
              : entitiesModified[`${selectWidget.type}s`][selectWidget.id].posY > maxValue;

          if (condition) {
            maxValue = entitiesModified[`${selectWidget.type}s`][selectWidget.id].posY;
          }
        });
      }
      state.selectedWidgets.forEach(selectWidget => {
        const widget = entitiesModified[`${selectWidget.type}s`][selectWidget.id];
        const modifiedCopyOfWidget = {
          ...widget,
          posY: maxValue,
        };
        entitiesModified[`${selectWidget.type}s`] = {
          ...entitiesModified[`${selectWidget.type}s`],
          [selectWidget.id]: modifiedCopyOfWidget,
        };
      });
      return {
        ...state,
        entities: entitiesModified,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
      };
    }

    case types.ALIGN_HORIZONTALLY: {
      const { direction } = action;

      const entitiesModified = { ...state.entities };

      const synopticSizeX =
        direction === 'left' ? entitiesModified?.synoptics[state.selectedSynoptic].sizeX : 0;

      let maxValue = synopticSizeX;

      if (state.selectedWidgets.length > 1) {
        state.selectedWidgets.forEach(selectWidget => {
          const condition =
            direction === 'left'
              ? entitiesModified[`${selectWidget.type}s`][selectWidget.id].posX < maxValue
              : entitiesModified[`${selectWidget.type}s`][selectWidget.id].posX > maxValue;

          if (condition) {
            maxValue = entitiesModified[`${selectWidget.type}s`][selectWidget.id].posX;
          }
        });
      }
      state.selectedWidgets.forEach(selectWidget => {
        const widget = entitiesModified[`${selectWidget.type}s`][selectWidget.id];
        const modifiedCopyOfWidget = {
          ...widget,
          posX: maxValue,
        };
        entitiesModified[`${selectWidget.type}s`] = {
          ...entitiesModified[`${selectWidget.type}s`],
          [selectWidget.id]: modifiedCopyOfWidget,
        };
      });
      return {
        ...state,
        entities: entitiesModified,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
      };
    }

    case types.UPDATE_MULTIPLE_EQUIPMENT_FIELD: {
      const { field, value, actionForEntity } = action;
      let widgetsWhichHaveThisField = state.selectedWidgets;
      const entitiesModified = { ...state.entities };
      const stateCopy = cloneDeep(state);

      if (actionForEntity) {
        widgetsWhichHaveThisField = state.selectedWidgets.filter(elementSelected =>
          actionForEntity?.includes(elementSelected.type)
        );
      }
      if ((field === 'targetX' || field === 'targetY') && value === undefined) {
        widgetsWhichHaveThisField.forEach(selectWidget => {
          if (field === 'targetX') {
            delete entitiesModified[`${selectWidget.type}s`][selectWidget.id]?.targetX;
          }

          if (field === 'targetY') {
            delete entitiesModified[`${selectWidget.type}s`][selectWidget.id]?.targetY;
          }
        });
        return {
          ...state,
          entities: entitiesModified,
          undo: generateUndo(stateCopy),
          changesSinceSave: ++state.changesSinceSave,
          redo: [],
        };
      }
      state.openChangeSynopticBackgroundModal =
        field === 'img' ? null : { ...state.openChangeSynopticBackgroundModal };

      const isStringValue = value?.constructor === String;

      widgetsWhichHaveThisField.forEach(selectWidget => {
        const widget = entitiesModified[`${selectWidget.type}s`][selectWidget.id];

        const modifiedCopyOfWidget = {
          ...widget,
          [field]: isStringValue ? value.replaceAll('"', '') : value,
        };
        entitiesModified[`${selectWidget.type}s`] = {
          ...entitiesModified[`${selectWidget.type}s`],
          [selectWidget.id]: modifiedCopyOfWidget,
        };
      });

      return {
        ...state,
        entities: entitiesModified,
        undo: generateUndo(stateCopy),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
      };
    }

    case types.SET_ALL_WIDGETS_SELECTED: {
      const labels = state.entities.synoptics[state.selectedSynoptic]?.labels || [];
      const equipments = state.entities.synoptics[state.selectedSynoptic]?.equipments || [];
      const buttons = state.entities.synoptics[state.selectedSynoptic]?.buttons || [];
      const lines = state.entities.synoptics[state.selectedSynoptic]?.lines || [];
      const synoptics = state.entities.synoptics[state.selectedSynoptic]?.synoptics || [];
      const alarmSummarys = state.entities.synoptics[state.selectedSynoptic]?.alarmSummarys || [];

      const synopticChildren = {
        labels,
        equipments,
        buttons,
        lines,
        synoptics,
        alarmSummarys,
      };
      const arrayWithSelectedWidgets = Object.entries(synopticChildren).reduce(
        (previous, currentValue) => {
          previous.push(
            ...currentValue[1].map(elementId => state.entities[currentValue[0]][elementId])
          );

          return previous;
        },
        []
      );

      const arrayWithParentAsAnId = arrayWithSelectedWidgets.map(elementSelected => {
        if (elementSelected?.type !== 'synoptic') {
          return { ...elementSelected, parent: state.selectedSynoptic };
        }

        return { ...elementSelected };
      });

      return {
        ...state,
        selectedWidgets: arrayWithParentAsAnId,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
      };
    }

    case types.RELOCATE_WIDGETS_INSIDE_CANVAS: {
      const { field } = action;
      const copyOfEntities = cloneDeep(state.entities);
      const labels = copyOfEntities.synoptics[state.selectedSynoptic]?.labels || [];
      const equipments = copyOfEntities.synoptics[state.selectedSynoptic]?.equipments || [];
      const buttons = copyOfEntities.synoptics[state.selectedSynoptic]?.buttons || [];
      const synoptics = copyOfEntities.synoptics[state.selectedSynoptic]?.synoptics || [];
      const alarmSummarys = copyOfEntities.synoptics[state.selectedSynoptic]?.alarmSummarys || [];
      const lines = copyOfEntities.synoptics[state.selectedSynoptic]?.lines || [];

      const synopticChildren = {
        labels,
        equipments,
        buttons,
        synoptics,
        alarmSummarys,
        lines,
      };
      const synopticSizeX = copyOfEntities?.synoptics[state.selectedSynoptic].sizeX;
      const synopticSizeY = copyOfEntities?.synoptics[state.selectedSynoptic].sizeY;
      Object.entries(synopticChildren).forEach(childrenElement => {
        const elementType = childrenElement[0];
        const elementsArray = childrenElement[1];

        if (elementsArray.length > 0) {
          elementsArray.forEach(elementId => {
            const element = copyOfEntities[elementType][elementId];
            const width = field === 'target' ? 0 : Number(element.width) || 0;
            const height = field === 'target' ? 0 : Number(element.height) || 0;
            if (element[`${field}X`] + width > Number(synopticSizeX)) {
              element[`${field}X`] = Number(synopticSizeX) - width;
            } else if (element[`${field}X`] < 0) {
              element[`${field}X`] = 0;
            }
            if (element[`${field}Y`] + height > synopticSizeY) {
              element[`${field}Y`] = Number(synopticSizeY) - height;
            } else if (element[`${field}Y`] < 0) {
              element[`${field}Y`] = 0;
            }
          });
        }
      });
      return {
        ...state,
        entities: copyOfEntities,
      };
    }

    case types.RELOCATE_AFTER_MOVING_WITH_ARROWS: {
      const { arrayWithPositions } = action;

      const lastState = cloneDeep(state);

      const synopticSelectedSizeX = state?.entities?.synoptics[state.selectedSynoptic].sizeX;
      const synopticSelectedSizeY = state?.entities?.synoptics[state.selectedSynoptic].sizeY;
      const copyOfEntities = { ...state.entities };
      arrayWithPositions.forEach(selectWidget => {
        const idWidget = selectWidget?.id;
        const typeWidget = `${selectWidget?.type}s`;
        const widget = copyOfEntities[typeWidget][idWidget];
        const modifiedCopyOfWidget = {
          ...widget,
          posX: selectWidget.x,
          posY: selectWidget.y,
        };
        modifiedCopyOfWidget.posX =
          modifiedCopyOfWidget.posX > synopticSelectedSizeX
            ? synopticSelectedSizeX - 30
            : modifiedCopyOfWidget.posX;
        modifiedCopyOfWidget.posY =
          modifiedCopyOfWidget.posY > synopticSelectedSizeY
            ? synopticSelectedSizeY - 40
            : modifiedCopyOfWidget.posY;
        modifiedCopyOfWidget.posX = modifiedCopyOfWidget.posX < 0 ? 0 : modifiedCopyOfWidget.posX;
        modifiedCopyOfWidget.posY = modifiedCopyOfWidget.posY < 0 ? 0 : modifiedCopyOfWidget.posY;
        copyOfEntities[typeWidget] = {
          ...copyOfEntities[typeWidget],
          [idWidget]: modifiedCopyOfWidget,
        };
      });
      return {
        ...state,
        entities: copyOfEntities,
        undo: generateUndo(lastState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
      };
    }

    case types.RESET_SELECTED_WIDGETS: {
      const newSelectedWidgetsArray = [];
      return {
        ...state,
        selectedWidgets: newSelectedWidgetsArray,
        undo: generateUndo(state),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
      };
    }
    case types.ADD_ROOT_NODE_CONTEXT_MENU: {
      const { coordinates } = action;
      return {
        ...state,
        addRootNodeContextMenu: coordinates,
      };
    }

    case types.ADD_SYNOPTIC_ROOT_NODE: {
      const lastState = cloneDeep(state);
      const newEntities = cloneDeep(state.entities);
      lastState.addRootNodeContextMenu = null;

      const idRandom = uuidv4();
      const [[mapsEntityId]] = Object.entries(newEntities.maps);
      const newSynopticsArrayInMaps = [...newEntities.maps[mapsEntityId].synoptics, idRandom];
      newEntities.maps[mapsEntityId].synoptics = newSynopticsArrayInMaps;
      newEntities.synoptics = {
        ...newEntities.synoptics,
        [idRandom]: {
          id: idRandom,
          parent: mapsEntityId,
          type: 'synoptic',
          name: 'newSynopticRoot',
          sizeX: defaultCanvasSize.sizeX,
          sizeY: defaultCanvasSize.sizeY,
          posX: defaultPositions.posX,
          posY: defaultPositions.posY,
        },
      };
      return {
        ...state,
        selectedSynoptic: idRandom,
        selectedWidgets: [newEntities.synoptics[idRandom]],
        entities: newEntities,
        undo: generateUndo(lastState),
        changesSinceSave: ++state.changesSinceSave,
        redo: [],
        addRootNodeContextMenu: null,
      };
    }
    case types.WIDGETS_ARE_BEING_DRAGGED: {
      const { areWidgetsBeingDragged } = action;
      return {
        ...state,
        areWidgetsBeingDragged,
      };
    }

    default:
      return state;
  }
};
