import { AxiosInstance } from 'axios';
//@ts-ignore
import { createApiHandler } from 'react-lib/dist/agent-es';
import { keycloakConfig } from '../keycloak';
import { RemusPlatform } from "../platformConfig";

//@ts-ignore
export * from 'react-lib/dist/agent-es';

const apiTest: AxiosInstance = createApiHandler(
  {
    baseURL: '/api/TEST',
  },
  null,
  null,
  keycloakConfig
);

const apiProd: AxiosInstance = createApiHandler(
  {
    baseURL: '/api/PROD',
  },
  null,
  null,
  keycloakConfig
);

const apiConfig = {
  baseURL: '/api/PROD',
};

export function getCurrentPlatform() : RemusPlatform {
  const { pathname } = window.location || {};

  // If no platform in the path, it means the app is still loading and it will be PROD
  return (pathname ? pathname.split('/')[1] : 'PROD') as RemusPlatform;
}

export function getAPI() {
  const platform = getCurrentPlatform();
  switch (platform) {
    case 'TEST':
      return apiTest;
    case 'PROD':
      return apiProd;
    default:
      return apiProd;
  }
}

export default {
  getAPI,
  apiProd,
  apiConfig,
  getCurrentPlatform,
};
