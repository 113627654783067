import React, {Suspense, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {Loading, NotificationBar} from 'react-lib/dist/components-es';
import {Container} from "semantic-ui-react";
import PWAPrompt from "react-ios-pwa-prompt";
import {Outlet} from "react-router-dom";
import Header from './NavHeader';
import BarSide from './NavSidebar';
import MessagesComponent from '../components/Messages/MessagesComponent';
import generalActions from '../behavior/general/actions';
import useIsMobileDisplay from '../hooks/useIsMobileDisplay';

const AppComponent = ({ closeSidebar, openSidebar, updateScreenWidth, sidebar, siteStatus }) => {
  const [isNotificationBarShown, updateIsNotificationBarShown] = useState(true);
  const isMobileDisplay = useIsMobileDisplay();

  useEffect(() => {
    let xDown = null;
    let yDown = null;
    const handleResize = () => {
      updateScreenWidth(window.innerWidth);
    };

    function getTouches(evt) {
      return (
        evt.touches || evt.originalEvent.touches // browser API
      ); // jQuery
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /* most significant */
        if (xDiff > 0) {
          // left
          if (isMobileDisplay) {
            closeSidebar();
          }
        } else if (isMobileDisplay && xDown < window.innerWidth / 4) {
          // right
          openSidebar();
        }
      } else if (yDiff > 0) {
        // up
      } else {
        // down
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Header />
      <BarSide />
      <MessagesComponent />
      <div
        id="rootContainer"
        onClick={() => {
          if (isMobileDisplay) {
            closeSidebar();
          }
        }}
        style={{
          marginLeft: sidebar && !isMobileDisplay ? '152px' : '0px',
          width: sidebar && !isMobileDisplay ? 'calc(100% - 152px)' : '100%',
        }}
      >
        <PWAPrompt />
        <Container id="app-main-container" fluid>
          <Suspense fallback={<Loading isLoading />}>
            <Outlet />
          </Suspense>
        </Container>
        {siteStatus && isNotificationBarShown && (
          <NotificationBar
            notificationText={siteStatus.text}
            updateIsNotificationBarShown={updateIsNotificationBarShown}
          />
        )}
      </div>
    </>
  );
};

AppComponent.propTypes = {
  sidebar: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  openSidebar: PropTypes.func.isRequired,
  updateScreenWidth: PropTypes.func.isRequired,
  siteStatus: PropTypes.string,
};

AppComponent.defaultProps = {
  siteStatus: undefined,
};

const mapStateToProps = ({ general, init }) => ({
  ...general,
  siteStatus: init.siteStatus,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(generalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
