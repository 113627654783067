import { useSelector } from 'react-redux';

export const ROLES = {
  ROLE_REMUS_USER: 'ROLE_REMUS_USER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_GUEST_EN: 'ROLE_GUEST_EN',
  ROLE_GUEST_RP: 'ROLE_GUEST_RP',
  ROLE_USER_EN: 'ROLE_USER_EN',
  ROLE_USER_RP: 'ROLE_USER_RP',
  ROLE_EXPERT_EN: 'ROLE_EXPERT_EN',
  ROLE_EXPERT_RP: 'ROLE_EXPERT_RP',
  ROLE_VISUALIZE_TRENDS: 'ROLE_VISUALIZE_TRENDS',
};
export const EXPERT_ROLES = [ROLES.ROLE_EXPERT_EN, ROLES.ROLE_EXPERT_RP];
export const USER_ROLES = [ROLES.ROLE_USER_EN, ROLES.ROLE_USER_RP];

function getUsername({ username }) {
  return username;
}

function isAdmin(user) {
  return Boolean(user && user.authorities && user.authorities.includes(ROLES.ROLE_ADMIN));
}

function isExpert(user) {
  return Boolean(
    user && user.authorities && user.authorities.some(role => EXPERT_ROLES.includes(role))
  );
}

function hasRole(user, role) {
  return Boolean(user && user.authorities && user.authorities.includes(role));
}

function isServiceAccount(user) {
  return user.personId == null;
}

export function useIsAdminUser() {
  const user = useSelector(({ init }) => init.user);
  return isAdmin(user);
}

export default {
  getUsername,
  isAdmin,
  hasRole,
  isExpert,
  isServiceAccount,
  ROLES,
};
