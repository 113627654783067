import { toast } from 'react-toastify';
import types from './types';
import { getErrorMessage } from '../errorHandler';

export default ({ type, response, data }) => {
  switch (type) {
    case types.GET_AVAILABILITIES_FAILURE: {
      const message = {
        title: 'Error getting availabilities',
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
      return message;
    }
    default:
      return false;
  }
};
