import Keycloak from 'keycloak-js';

// eslint-disable-next-line import/no-mutable-exports
let keycloak: Keycloak.KeycloakInstance | null = null;

if (process.env.NODE_ENV === 'production') {
  keycloak = Keycloak(`${process.env.PUBLIC_URL}/keycloak.json`);
}

export const keycloakConfig = {
  keycloak,
  loginOptions: {
    scope: 'openid offline_access',
  },
};

export default keycloak;
