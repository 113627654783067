import moment from 'moment';
import { DataSource } from '../../../behavior/trends/types/DataSource';
import { DateMode } from '../../../behavior/trends/types/DateMode';
import { periodTypes } from '../../../behavior/trends/types/Period';

export const DEFAULT_STATISTICS_FORM_VALUES = {
  dateBegin: moment().subtract(1, 'day'),
  dateEnd: moment(),
  realTime: false,
  dataResolution: 'AUTO',
  dataSource: DataSource.ORACLE,
  periodNumber: 1,
  periodType: periodTypes.DAY,
  dateMode: DateMode.LAST_PERIOD_MODE,
  workingModes: [],
  alarmStatuses: [],
  faultStatuses: [],
  updated: false,
};

export const displayModes = {
  BOXPLOT_MODE: 'BOXPLOT_MODE',
  TABLE_MODE: 'TABLE_MODE',
};

export const tableColumns = [
  { name: 'Count', field: 'count' },
  { name: 'Outliers', field: 'outliersCount' },
  { name: 'Max', field: 'max' },
  { name: 'Min', field: 'min' },
  { name: 'Avg', field: 'mean' },
  { name: 'Median', field: 'median' },
  { name: 'Integral', field: 'integral' },
  { name: 'Std. Dev.', field: 'stddev' },
  { name: 'Variance', field: 'variance' },
  { name: 'Q1', field: 'quartile1' },
  { name: 'Q2', field: 'quartile2' },
  { name: 'Q3', field: 'quartile3' },
  { name: 'Q4', field: 'quartile4' },
  { name: 'IQR', field: 'iqr' },
];
