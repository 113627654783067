const START = 'app/init/start';
const START_SUCCESS = `${START}/SUCCESS`;
const START_FAILURE = `${START}/FAILURE`;

const GET_SITE_STATUS = 'app/init/GET_SITE_STATUS';
const GET_SITE_STATUS_SUCCESS = `${GET_SITE_STATUS}/SUCCESS`;
const GET_SITE_STATUS_FAILURE = `${GET_SITE_STATUS}/FAILURE`;

const GET_USER = 'app/init/GET_USER';
const GET_USER_SUCCESS = `${GET_USER}/SUCCESS`;
const GET_USER_FAILURE = `${GET_USER}/FAILURE`;

export default {
  START,
  START_SUCCESS,
  START_FAILURE,

  GET_SITE_STATUS,
  GET_SITE_STATUS_SUCCESS,
  GET_SITE_STATUS_FAILURE,

  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
};
