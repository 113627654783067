import Connector, { takeQuery } from '../../api/agent';
import types from './types';

const { getAPI } = Connector;

export const dataExportStatus = {
  getDataExportNxcals: () => getAPI().get(`dataExportStatus/nxcals`),
  getDataExportInflux: () => getAPI().get(`dataExportStatus/influx`),
  getDataByType: ({ dataTypeName, dataExportTarget }) =>
    getAPI().get(`dataExportStatus/getDataByType`, false, { dataTypeName, dataExportTarget }),
};

const dataExportStatusSegas = [
  takeQuery(types.GET_DATA_EXPORT_NXCALS, {
    api: dataExportStatus.getDataExportNxcals,
  }),
  takeQuery(types.GET_DATA_EXPORT_INFLUX, {
    api: dataExportStatus.getDataExportInflux,
  }),
  takeQuery(types.GET_DATA_BY_TYPE, {
    api: dataExportStatus.getDataByType,
  }),
];

export default dataExportStatusSegas;
