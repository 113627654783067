import { toast } from 'react-toastify';
import types from './types';
import { getErrorMessage } from '../errorHandler';

export default ({ type, request, response, data }) => {
  switch (type) {
    case types.NOTIFICATION_BY_ID_FAILURE: {
      const message = {
        title: `Error getting notification with id=${request.params.id}`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
      return message;
    }
    case types.NOTIFICATIONS_BY_IDS_FAILURE: {
      const message = {
        title: `Error getting notificationS with ids=${request.params.ids}`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
      return message;
    }
    case types.GET_NOTIFICATIONS_FAILURE:
      return {
        title: `Error getting notifications`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
    case types.UPDATE_NOTIFICATION_ENABLE_SUCCESS: {
      const { enable, notificationId } = request.params;
      return {
        title: `Notification with id=${notificationId} ${enable ? 'enabled' : 'disabled'}`,
        type: toast.TYPE.SUCCESS,
      };
    }
    case types.UPDATE_NOTIFICATION_ENABLE_FAILURE: {
      const { enable, notificationId } = request.params;
      return {
        title: `Error ${enable ? 'enabling' : 'disabling'} notification with id=${notificationId}`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
    }
    case types.UPDATE_NOTIFICATION_BY_ID_SUCCESS: {
      const { notification } = request.params;
      const { id } = notification || {};
      return {
        title: `Notification with id=${id} successfully updated`,
        type: toast.TYPE.SUCCESS,
      };
    }
    case types.UPDATE_NOTIFICATION_BY_ID_FAILURE: {
      const { notification } = request.params;
      const { id } = notification || {};
      return {
        title: `Error updating notification with id=${id}`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
    }
    case types.CREATE_NOTIFICATION_SUCCESS:
      return {
        title: `Notification with id=${data.id} successfully created`,
        type: toast.TYPE.SUCCESS,
      };
    case types.CREATE_NOTIFICATION_FAILURE:
      return {
        title: `Error creating notification`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };

    case types.DELETE_NOTIFICATION_SUCCESS:
      return {
        title: `Notification with id=${request.params.notificationId} successfully deleted`,
        type: toast.TYPE.SUCCESS,
      };

    case types.DELETE_NOTIFICATION_FAILURE:
      return {
        title: `Error deleting notification with id=${request.params.notificationId}`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };

    default:
      return false;
  }
};
