import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import {Button, Header, Icon, Modal} from 'semantic-ui-react';

class ErrorBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      scope.setExtra('componentStack', info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    window.location.href = '/'
  }

  render() {
    const navigateToHome = () => {
      window.location.href = '/'
    };

    const { eventId } = this.state;
    return (
      <Modal
        size="tiny"
        open
        onClose={() => {
          navigateToHome()
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <Header as="h1">Hrmm... something broke.</Header>
            <Icon name="frown outline" size="massive" disabled />
            <p>Please let us know what happened.</p>
            <Button
              primary
              onClick={e => {
                e.preventDefault();
                Sentry.showReportDialog({ eventId });
                navigateToHome()
              }}
            >
              Send Feedback
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ErrorBoundary;
