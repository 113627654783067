import { array, arrayOf, bool, number, object, oneOf, shape, string } from 'prop-types';

import { SORT_ASCENDING } from '../hooks/useSort';

export const scopeType = shape({
  id: number,
  scopeName: string,
});

export const sourceType = shape({
  sourceId: number,
  notificationScopeId: number,
  entitySourceTypeId: number,
  notificationScopeName: string,
  sourceName: string,
  entitySourceTypeName: string,
  zoneId: number,
  zoneName: string,
  typeId: number,
  typeName: string,
  sourceCategory: string,
  usageId: number,
  usageName: string,
  scope: scopeType,
});

export const sendToType = shape({
  destination: string,
  sendAsSms: bool,
  notificationId: number,
});

export const personType = shape({
  personId: number,
  cernId: number,
  lastName: string,
  firstName: string,
  sex: string,
  dateOfBirth: string,
  primary_experiment: string,
  phone: string,
  cellphone: string,
  genericEmail: string,
  preferredEmail: string,
  orgUnit: string,
  username: string,
});

export const userType = shape({
  username: string,
  person: personType,
  authorities: arrayOf(string),
});

export const notificationType = shape({
  id: number,
  name: string,
  owner: personType,
  sources: arrayOf(sourceType),
  sendTo: arrayOf(sendToType),
  reportAlarmIn: bool,
  reportAlarmOut: bool,
  reportFaultIn: bool,
  reportFaultOut: bool,
  reportFrom: string,
  reportMeasuresInterval: number,
  reportTo: string,
  reportMeasuresIf: number,
});
export const paginationType = shape({
  totalElements: number,
  totalPages: number,
  size: number,
  currentPage: number,
});

export const HighchartsType = array;

export const deviceTypeType = shape({
  id: number,
  name: string,
  maxNumberOfSubdevices: number,
  maxNumberOfChannels: number,
  scadaName: string,
  tmestamp: number,
});

export const deviceType = shape({
  id: number,
  devicePosition: number,
  deviceStockNumber: string,
  name: string,
  manufacturer: string,
  tmestamp: number,
  deviceType: deviceTypeType,
});

export const zoneType = shape({
  id: number,
  name: string,
  emailEnabled: bool,
  faultEmail: string,
  alarmEmail: string,
});

export const usageDomainType = shape({
  id: number,
  name: string,
});

export const channelType = shape({
  id: number,
  name: string,
  channelOrder: number,
  tmestamp: number,
  auto: bool,
  archived: bool,
  device: deviceType,
  zone: zoneType,
  usageDomain: usageDomainType,
});

export const msType = shape({
  id: number,
  name: string,
  location: string,
  address: string,
  deleted: bool,
  zone: zoneType,
  device: deviceType,
  usageDomain: usageDomainType,
});

export const unitType = shape({
  id: number,
  name: string,
  conversionFactor: number,
  baseUnitName: string,
  integralFactor: number,
  integralUnitName: string,
});

export const workingModeType = shape({
  id: number,
  name: string,
  measureFilterEnabled: bool,
});

export const favoriteType = shape({
  id: number,
  shared: bool,
  name: string,
  json: string,
  owner: string,
});

export const numericFieldAggregationType = shape({
  field: string,
  max: number,
  min: number,
  mean: number,
  variance: number,
  median: number,
  integral: number,
  stddev: number,
  count: number,
  quartile1: number,
  quartile2: number,
  quartile3: number,
  quartile4: number,
  iqr: number,
});

export const categoricalFieldAggregationType = shape({
  columns: object,
  count: number,
  percentage: number,
});

export const alarmTypeType = shape({
  usageDomain: usageDomainType,
  systemFault: bool,
});

export const monitoringGroupType = shape({
  id: number,
  name: string,
  descr: string,
  ispublic: bool,
  tmestamp: string,
});

export const sortType = shape({
  column: string,
  direction: oneOf([SORT_ASCENDING, SORT_ASCENDING]),
});

