import types from './types';

const initialState = {
  isLoading: true,
  user: undefined,
  siteStatus: undefined
};

export default function reducer(state = initialState, { type, data }) {
  switch (type) {
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: data
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case types.GET_SITE_STATUS_SUCCESS:
      return {
        ...state,
        siteStatus: data
      };
    default:
      return state;
  }
}
