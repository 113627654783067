import types from './types';
import { periodTypes } from '../../components/Common/RecentDataDatePicker/constants';
import { LAST_PERIOD_MODE } from '../../components/Trends/constants';
import {
  DataResolution,
  FLOW_RATE_UNITS,
  NO_INTEGRATION,
  PRECIPITATION_UNITS,
} from '../../components/Hyetogram/constants';
import calculateDateRangeLimits from '../../components/Measurements/MeasurementsForm/DateSelectionSegment/calculateDateRangeLimits';

const defaultState = {
  form: {
    hyetogramName: '',
    dateBegin: null,
    dateEnd: null,
    periodNumber: 1,
    periodType: periodTypes.DAY,
    dateMode: LAST_PERIOD_MODE,
    integrationType: NO_INTEGRATION,
    showRawData: false,
    dataResolution: DataResolution.HOUR,
    precipitationChannels: [],
    flowRateChannels: [],
  },
  chart: {
    autoResizeAxis: true,
    flowAxis: {
      min: 0,
      max: 2500,
    },
    precipitationAxis: {
      min: 0,
      max: 2500,
    },
    flowRateUnit: FLOW_RATE_UNITS[0],
    precipitationUnit: PRECIPITATION_UNITS[0],
    stacked: false,
  },
  currentRequestValues: undefined,
};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case types.UPDATE_FORM: {
      const { field, value } = action;
      return {
        ...state,
        form: {
          ...state.form,
          [field]: value,
        },
      };
    }
    case types.UPDATE_CHART_CONFIG: {
      const { field, value } = action;
      return {
        ...state,
        chart: {
          ...state.chart,
          [field]: value,
        },
      };
    }
    case types.SEND_FORM: {
      const { dateLowerLimit, dateUpperLimit } = calculateDateRangeLimits(state.form);
      return {
        ...state,
        currentRequestValues: {
          ...state.form,
          dateBegin: dateLowerLimit,
          dateEnd: dateUpperLimit,
        },
        chart: {
          ...state.chart,
          stacked: false,
          autoResizeAxis: true,
        },
      };
    }
    default:
      return state;
  }
};
