import moment, { DurationInputArg2 } from 'moment';
import { DateMode } from '../../../../behavior/trends/types/DateMode';
import { TrendsRequest } from '../../../../behavior/trends/types/TrendsRequest';

export default function calculateDateRangeLimits({
  dateBegin,
  dateEnd,
  periodType,
  periodNumber,
  dateMode,
  realTime,
}: TrendsRequest) {
  let dateLowerLimit;
  let dateUpperLimit;
  switch (dateMode) {
    case DateMode.DATE_PERIOD_MODE:
      dateLowerLimit = dateBegin;
      dateUpperLimit = dateEnd;
      break;
    case DateMode.LAST_PERIOD_MODE:
      dateLowerLimit = moment().subtract(periodNumber, periodType.momentName as DurationInputArg2);
      dateUpperLimit = !realTime ? moment() : undefined;
      break;
    default:
      dateLowerLimit = dateBegin;
      dateUpperLimit = dateEnd;
  }

  return { dateLowerLimit, dateUpperLimit };
}
