import { ROLES } from '../init/selectors';

export const DashboardPermissions = {
  NO_ACCESS: 'NO_ACCESS',
  READ_ONLY: 'READ_ONLY',
  EDITION: 'EDITION',
};

export const DashboardRoles = [
  { role: ROLES.ROLE_GUEST_EN, label: 'Env. Guests' },
  { role: ROLES.ROLE_GUEST_RP, label: 'RP Guests' },
  { role: ROLES.ROLE_USER_EN, label: 'Env. Users' },
  { role: ROLES.ROLE_USER_RP, label: 'RP Users' },
  { role: ROLES.ROLE_EXPERT_EN, label: 'Env. Experts' },
  { role: ROLES.ROLE_EXPERT_RP, label: 'RP Experts' },
];
