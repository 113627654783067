export class ReducerRegistry {
  constructor() {
    this._reducersEmitChange = null;
    this._sagasEmitChange = null;
    this._reducers = {};
    this._sagas = {};
  }

  getReducers() {
    return { ...this._reducers };
  }

  register(name, reducer, sagas) {
    this._reducers = { ...this._reducers, [name]: reducer };

    if (sagas) {
      this._sagas = { ...this._sagas, [name]: sagas };
      if (this._sagasEmitChange) {
        sagas.forEach(s => this._sagasEmitChange(s));
      }
    }
    if (this._reducersEmitChange) {
      this._reducersEmitChange(this.getReducers());
    }
  }

  unregister(name) {
    delete this._reducers[name];
    if (this._reducersEmitChange) {
      this._reducersEmitChange(this.getReducers());
    }

    delete this._sagas[name];
  }

  setReducerChangeListener(listener) {
    this._reducersEmitChange = listener;
  }

  setSagasChangeListener(listener) {
    this._sagasEmitChange = listener;
  }
}

const reducerRegistry = new ReducerRegistry();

export default reducerRegistry;
