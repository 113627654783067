import React, { Fragment } from "react";
import { Breadcrumb, BreadcrumbDivider, BreadcrumbSection } from "semantic-ui-react";
import { UIMatch, useMatches, useNavigate } from "react-router-dom";
import "./Breadcrumbs.css"
import { keyify } from "../utils/StringUtils";

export function Breadcrumbs() {
  const matches = useMatches();
  const navigate = useNavigate();

  const crumbs = matches
    .filter((match: UIMatch<any, any>) => Boolean(match.handle?.crumb))
    .map((match: UIMatch<any, any>) => ({
      text: match.handle.crumb instanceof Function ? match.handle.crumb(match.data) : match.handle.crumb,
      href: match.pathname
    }));

  return (
    <div id="layout-breadcrumbs">
      <Breadcrumb size='large'>
        {crumbs.map((crumb, index) =>
          <Fragment key={`breadcrumb-name-${keyify(crumb.text)}`}>
            <BreadcrumbSection
              onClick={() => navigate(crumb.href)}
              className="breadcrumb-section"
            >
              {crumb.text}
            </BreadcrumbSection>
            {index < crumbs.length - 1 &&
              <BreadcrumbDivider
                icon='right chevron'
                className="breadcrumb-divider"/>
            }
          </Fragment>
        )}
      </Breadcrumb>
    </div>
  );
}
