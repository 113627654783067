const HYETOGRAM_ACTION_PREFIX = '/app/hyetogram';
const UPDATE_FORM = `${HYETOGRAM_ACTION_PREFIX}/UPDATE_FORM`;
const UPDATE_CHART_CONFIG = `${HYETOGRAM_ACTION_PREFIX}/UPDATE_CHART_CONFIG`;
const SEND_FORM = `${HYETOGRAM_ACTION_PREFIX}/SEND_FORM`;

export default {
  UPDATE_FORM,
  UPDATE_CHART_CONFIG,
  SEND_FORM,
};
