import types from './types';

export const setSelectedSynopticId = selectedSynoptic => ({
  type: types.SET_SELECTED_SYNOPTIC_ID,
  selectedSynoptic,
});
export const setSelectedElement = selectedElement => ({
  type: types.SET_SELECTED_ELEMENT,
  selectedElement,
});
export const setSelectedWidget = (selectedWidget, ctrlCliked) => ({
  type: types.SET_SELECTED_WIDGET,
  selectedWidget,
  ctrlCliked,
});
export const setPropertiesPanelElement = element => ({
  type: types.SET_PROPERTIES_PANEL_ELEMENT,
  element,
});

export const setContextMenuState = (
  contextMenuState,
  coordinates,
  isSelectedInComponentsContained
) => ({
  type: types.SET_OPEN_CONTEXT_MENU,
  contextMenuState,
  coordinates,
  isSelectedInComponentsContained,
});
export const setApplication = application => ({
  type: types.SET_APPLICATION,
  application,
});
export const setApplicationMetadata = applicationMetadata => ({
  type: types.SET_APPLICATION_METADATA,
  applicationMetadata,
});
export const updateEquipmentField = (id, equipmentType, field, value) => ({
  type: types.UPDATE_EQUIPMENT_FIELD,
  id,
  equipmentType,
  field,
  value,
});

export const updateMultipleEquipmentField = (field, value, actionForEntity) => ({
  type: types.UPDATE_MULTIPLE_EQUIPMENT_FIELD,
  field,
  value,
  actionForEntity,
});

export const updateEntityField = (id, entityType, field, value) => ({
  type: types.UPDATE_ENTITY_FIELD,
  id,
  entityType,
  field,
  value,
});

export const resetState = () => ({
  type: types.RESET_STATE,
});
export const setFileEditionCode = fileEditionCode => ({
  type: types.SET_FILE_EDITION_CODE,
  fileEditionCode,
});
export const setEditionMode = editionMode => ({
  type: types.SET_EDITION_MODE,
  editionMode,
});
export const initializeTextEditionMode = () => ({
  type: types.INITIALIZE_TEXT_EDITION_MODE,
});
export const finishTextEditionMode = () => ({
  type: types.FINISH_TEXT_EDITION_MODE,
});

export const addButton = buttonCategory => ({
  type: types.ADD_BUTTON,
  buttonCategory,
});

export const addAlarm = () => ({
  type: types.ADD_ALARM,
});

export const addEquipmentToAnAlarm = equipment => ({
  type: types.ADD_EQUIPMENT_TO_ALARM,
  equipment,
});
export const removeEquipmentToAnAlarm = equipment => ({
  type: types.REMOVE_EQUIPMENT_TO_ALARM,
  equipment,
});

export const addLine = line => ({
  type: types.ADD_LINE,
  line,
});
export const addLabel = label => ({
  type: types.ADD_LABEL,
  label,
});

export const deleteButton = button => ({
  type: types.DELETE_BUTTON,
  button,
});
export const deleteLabel = label => ({
  type: types.DELETE_LABEL,
  label,
});
export const deleteLine = line => ({
  type: types.DELETE_LINE,
  line,
});

export const deleteWidget = deleteMode => ({
  type: types.DELETE_WIDGET,
  deleteMode,
});

export const setContextMenu = newSynopticContext => ({
  type: types.NEW_SYNOPTIC_CONTEXT_MENU,
  newSynopticContext,
});

export const cloneSynoptic = synoptic => ({
  type: types.CLONE_SYNOPTIC,
  synoptic,
});

export const deleteSynoptic = synopticToDelete => ({
  type: types.DELETE_SYNOPTIC,
  synopticToDelete,
});

export const newSynoptic = synoptic => ({
  type: types.NEW_SYNOPTIC_MENU,
  synoptic,
});

export const openModalWidgets = boolean => ({
  type: types.DISPLAY_MODAL_WIDGETS,
  boolean,
});

export const moveSynopticUpperItsParent = synoptic => ({
  type: types.MOVE_SYNOPTIC_UP_ITS_PARENT,
  synoptic,
});

export const setRenameSynoptic = (synoptic, value) => ({
  type: types.SET_RENAME_SYNOPTIC,
  synoptic,
  value,
});

export const setClipboardForWidgets = mode => ({
  type: types.SET_CLIPBOARD_FOR_WIDGETS,
  mode,
});

export const displaySynopticPosition = (positionX, positionY) => ({
  type: types.SET_CLICK_ON_CANVAS,
  positionX,
  positionY,
});

export const pasteSynoptic = (idParent, synoptic) => ({
  type: types.PASTE_SYNOPTIC,
  idParent,
  synoptic,
});

export const addEquipment = equipment => ({
  type: types.ADD_EQUIPMENT,
  equipment,
});

export const moveSynopticUp = synoptic => ({
  type: types.MOVE_SYNOPTIC_UP_INSIDE_PARENT,
  synoptic,
});

export const moveSynopticDown = synoptic => ({
  type: types.MOVE_SYNOPTIC_DOWN_INSIDE_PARENT,
  synoptic,
});

export const changeSynopticBackground = (background, synoptic) => ({
  type: types.CHANGE_SYNOPTIC_BACKGROUND,
  background,
  synoptic,
});

export const setCanvasColor = newColor => ({
  type: types.SET_CANVAS_COLOR,
  color: newColor,
});

export const setWidgetOutOfCanvas = widget => ({
  type: types.SET_WIDGET_OUT_OF_CANVAS,
  widget,
});

export const executeUndo = () => ({
  type: types.EXECUTE_UNDO,
});

export const executeRedo = () => ({
  type: types.EXECUTE_REDO,
});

export const alignVertically = direction => ({
  type: types.ALIGN_VERTICALLY,
  direction,
});
export const alignHorizontally = direction => ({
  type: types.ALIGN_HORIZONTALLY,
  direction,
});

export const copyMultipleWidgets = () => ({
  type: types.COPY_MULTIPLE_WIDGETS,
});
export const pasteMultipleWidgetsFromClipboard = parentSynoptic => ({
  type: types.PASTE_MULTIPLE_WIDGETS_FROM_CLIPBOARD,
  parentSynoptic,
});

export const setAllWidgetSelected = () => ({
  type: types.SET_ALL_WIDGETS_SELECTED,
});

export const setWidgetsSelectedByDrag = selectedElements => ({
  type: types.SET_WIDGETS_SELECTED_BY_DRAG,
  selectedElements,
});

export const moveMultipleWidgets = (positionX, positionY) => ({
  type: types.MOVE_MULTIPLE_WIDGETS,
  positionX,
  positionY,
});
export const relocateWidgetsInsideCanvas = field => ({
  type: types.RELOCATE_WIDGETS_INSIDE_CANVAS,
  field,
});

export const recolocateAfterMovingWithArrows = arrayWithPositions => ({
  type: types.RELOCATE_AFTER_MOVING_WITH_ARROWS,
  arrayWithPositions,
});
export const resetSelectedWidgets = () => ({
  type: types.RESET_SELECTED_WIDGETS,
});

export const setWidgetDimensions = (widget, dimensions) => ({
  type: types.SET_WIDGET_DIMENSIONS,
  widget,
  dimensions,
});

export const setRemusAppFreeText = freeText => ({
  type: types.SET_REMUS_APP_FREE_TEXT,
  freeText,
});
export const setTriedToDeleteLastMainSynoptic = triedToDeletelastMainSynoptic => ({
  type: types.SET_DELETE_LAST_MAIN_SYNOPTIC,
  triedToDeletelastMainSynoptic,
});

export const setSaveButtonAvailability = isSaveButtonActive => ({
  type: types.SET_SAVE_BUTTON_AVAILABILITY,
  isSaveButtonActive,
});

export const changeSynopticBackgroundModal = backgroundModalOptions => ({
  type: types.SET_CHANGE_SYNOPTIC_BACKGROUND_MODAL,
  backgroundModalOptions,
});
export const openDeleteWidgetConfirmationModal = deleteWidgetConfirmation => ({
  type: types.DELETE_WIDGET_CONFIRMATION_MODAL,
  deleteWidgetConfirmation,
});

export const deleteWidgetsInClipboard = () => ({
  type: types.DELETE_WIDGETS_IN_CLIPBOARD,
});

export const deleteWidgetsSelected = () => ({
  type: types.DELETE_ELEMENTS_SELECTED,
});

export const addRootNodeContextMenu = coordinates => ({
  type: types.ADD_ROOT_NODE_CONTEXT_MENU,
  coordinates,
});

export const addSynopticRootNode = () => ({
  type: types.ADD_SYNOPTIC_ROOT_NODE,
});
export const widgetsAreBeingDragged = areWidgetsBeingDragged => ({
  type: types.WIDGETS_ARE_BEING_DRAGGED,
  areWidgetsBeingDragged,
});

export const setChangesSinceSave = changesSinceSave => ({
  type: types.CHANGES_SINCE_SAVE,
  changesSinceSave,
});

export default {
  deleteWidgetsSelected,
  setSelectedSynopticId,
  setSelectedElement,
  setSelectedWidget,
  setPropertiesPanelElement,
  setContextMenuState,
  setApplication,
  setApplicationMetadata,
  updateEquipmentField,
  updateEntityField,
  resetState,
  setFileEditionCode,
  setEditionMode,
  initializeTextEditionMode,
  finishTextEditionMode,
  addButton,
  addAlarm,
  addEquipmentToAnAlarm,
  removeEquipmentToAnAlarm,
  addLabel,
  addLine,
  addEquipment,
  deleteButton,
  deleteLabel,
  deleteWidget,
  setContextMenu,
  cloneSynoptic,
  deleteSynoptic,
  newSynoptic,
  openModalWidgets,
  moveSynopticUpperItsParent,
  setClipboardForWidgets,
  pasteSynoptic,
  moveSynopticUp,
  moveSynopticDown,
  changeSynopticBackground,
  setCanvasColor,
  setWidgetOutOfCanvas,
  displaySynopticPosition,
  executeUndo,
  executeRedo,
  alignVertically,
  alignHorizontally,
  updateMultipleEquipmentField,
  copyMultipleWidgets,
  pasteMultipleWidgetsFromClipboard,
  setAllWidgetSelected,
  setRenameSynoptic,
  setWidgetsSelectedByDrag,
  moveMultipleWidgets,
  relocateWidgetsInsideCanvas,
  recolocateAfterMovingWithArrows,
  resetSelectedWidgets,
  setWidgetDimensions,
  setRemusAppFreeText,
  setSaveButtonAvailability,
  setTriedToDeleteLastMainSynoptic,
  changeSynopticBackgroundModal,
  openDeleteWidgetConfirmationModal,
  deleteWidgetsInClipboard,
  addRootNodeContextMenu,
  addSynopticRootNode,
  widgetsAreBeingDragged,
  setChangesSinceSave,
};
