export const defaultPositions = {
  startX: 483,
  startY: 587,
  endX: 700,
  endY: 587,
  posX: 483,
  posY: 587,
};

export const states = {
  NUM_OF_SAVED_UNDO_STATES: 30,
};

export const defaultLabel = {
  fontSize: 12,
};

export const widgetTypes = {
  alarmSummary: 'alarmSummary',
  equipments: 'equipments',
  documents: 'documents',
  synoptics: 'synoptics',
  buttons: 'buttons',
  labels: 'labels',
  lines: 'lines',
  maps: 'maps',
};
