import * as Agent from 'react-lib/dist/agent-es';
import {
  getFilteringSagas,
  filterTypes,
  filterActions,
  filterReducer,
  Config,
} from 'hse-filtering-library-frontend';
import { getAPI } from '../../api/agent';

Config.backendTimezone = 'UTC';
Config.convertDatesToClientTimezone = true;

const [filterSagas] = getFilteringSagas({
  getApi: getAPI,
  Agent,
});

export { filterTypes, filterActions, filterReducer, filterSagas };
