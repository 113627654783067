// @ts-ignore
import { createBrowserHistory as createHistory } from 'history';
import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { AnyAction } from 'redux';
import { reducers, rootSaga } from './behavior';
import reducerRegistry from './reducerRegistry';

export const history = createHistory();

const sagaMiddleware = createSagaMiddleware();

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'development') {
    return [sagaMiddleware, logger];
  }
  return [sagaMiddleware];
};

const storeCreator = () => {
  const reducer = reducers(history, reducerRegistry.getReducers());
  const newStore = configureStore({
    reducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: getMiddleware(),
  });
  sagaMiddleware.run(rootSaga);
  return newStore;
};

const store = storeCreator();

reducerRegistry.setReducerChangeListener(
  (dynamicReducers: Record<string, (state: RootState, action: AnyAction) => RootState>) => {
    store.replaceReducer(reducers(history, dynamicReducers));
  }
);

reducerRegistry.setSagasChangeListener((saga: Generator) => {
  function* mainSaga() {
    yield saga;
  }

  sagaMiddleware.run(mainSaga);
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
