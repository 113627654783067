import { toast } from 'react-toastify';
import types from './types';
import { getErrorMessage } from '../errorHandler';

export default ({ type, response, data }) => {
  switch (type) {
    case types.GET_NXCALS_BY_TYPE_SOURCES_FAILURE: {
      const message = {
        title: 'Error getting Nxcals Data By Type',
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
      return message;
    }
    case types.CREATE_NXCALS_BY_TYPE_SUCCESS: {
      return {
        title: `The new nxcals by type element is successfully created!`,
        type: toast.TYPE.SUCCESS,
      };
    }
    case types.CREATE_NXCALS_BY_TYPE_FAILURE:
      return {
        title: `Error creating nxcals by type, please fill with correct data!`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
    case types.DELETE_NXCALS_BY_TYPE_SUCCESS: {
      return {
        title: `The element nxcals by type successfully deleted!`,
        type: toast.TYPE.SUCCESS,
      };
    }
    case types.DELETE_NXCALS_BY_TYPE_FAILURE:
      return {
        title: `Error deleting nxcals by type`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
    case types.UPDATE_NXCALS_BY_TYPE_BY_ID_FAILURE:
      return {
        title: `Error updating nxcals by type`,
        message: getErrorMessage(data),
        type: response.status === 403 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
      };
    case types.UPDATE_NXCALS_BY_TYPE_BY_ID_SUCCESS:
      return {
        title: `The modified element nxcals by type is successfully update!`,
        type: toast.TYPE.SUCCESS,
      };
    default:
      return false;
  }
};
