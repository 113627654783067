import React from 'react';
import { AxiosError } from 'axios';
import { toast, TypeOptions } from 'react-toastify';
import { AnyAction } from 'redux';

const defaultState = {};

export type ToastMessage = {
  title?: string;
  message?: string;
  error?: AxiosError;
  type?: TypeOptions;
};

export function displayMessage({ title, message, type = 'success' }: ToastMessage) {
  if (title && message) {
    toast(
      <>
        <h1 className="toaster-title">{title}</h1>
        <p className="toaster-message">{message}</p>
      </>,
      { type }
    );
  } else {
    toast(message || title, { type });
  }
}

export function errorMessage({ title, message, error }: ToastMessage) {
  displayMessage({
    title,
    message: message ?? error?.response?.data ?? error?.message,
    type: 'error',
  });
}
export function successMessage({ title, message }: ToastMessage) {
  displayMessage({ title, message, type: 'success' });
}

export function warningMessage({ title, message }: ToastMessage) {
  displayMessage({ title, message, type: 'warning' });
}

export default (messagesHandler: any) =>
  (state = defaultState, action: AnyAction) => {
    let handler = null;
    let i = 0;
    const size = messagesHandler.length;
    // find the first message handler (see message declaration in ./index.js) then CONSUME it
    // remove the message from state to prevent multiple popup
    while (i < size && handler === false) {
      handler = messagesHandler[i](action);
      i += 1;
    }
    if (handler) {
      displayMessage(handler);
    }
    return {
      ...state,
    };
  };
