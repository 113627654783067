import React from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import { useActions } from '../../../../../hooks/useActions';
import { applicationEditorActions } from '../../../../../behavior/applicationEditor';

export function isTargetAndNull(property, value) {
  return (property === 'targetX' || property === 'targetY') && !value && value !== 0;
}

const NumericPropertiesTableRow = ({ node, field }) => {
  const actions = useActions(applicationEditorActions);
  return (
    <DebounceInput
      type="number"
      className="properties-table-input"
      debounceTimeout={0}
      onChange={e => {
        if (isTargetAndNull(field, e.target.value)) {
          actions.updateMultipleEquipmentField(field, undefined, ['equipment']);
        } else {
          actions.updateEntityField(node?.id, node?.type, field, Number(e.target.value));
        }
      }}
      placeholder={field}
      value={node[field] !== null ? Math.round(Number(node[field])) : null}
    />
  );
};
NumericPropertiesTableRow.propTypes = {
  node: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
};

export default NumericPropertiesTableRow;
