export type PeriodKey = 'SECOND' | 'MINUTE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export type Period = {
  name: PeriodKey;
  momentName: string;
  multiplier: number;
  toMillis: (val: number) => number;
};

export type Periods = Record<PeriodKey, Period>;

export const periodTypes: Periods = {
  SECOND: {
    name: 'SECOND',
    momentName: 'seconds',
    multiplier: 1 / 24 / 60 / 60,
    toMillis: val => val * 1000,
  },
  MINUTE: {
    name: 'MINUTE',
    momentName: 'minutes',
    multiplier: 1 / 24 / 60,
    toMillis: val => val * 60 * 1000,
  },
  HOUR: {
    name: 'HOUR',
    momentName: 'hours',
    multiplier: 1 / 24,
    toMillis: val => val * 60 * 60 * 1000,
  },
  DAY: {
    name: 'DAY',
    momentName: 'days',
    multiplier: 1,
    toMillis: val => val * 24 * 60 * 60 * 1000,
  },
  WEEK: {
    name: 'WEEK',
    momentName: 'weeks',
    multiplier: 7,
    toMillis: val => val * 7 * 24 * 60 * 60 * 1000,
  },
  MONTH: {
    name: 'MONTH',
    momentName: 'months',
    multiplier: 31, // We assume a month has 31 days
    toMillis: val => val * 31 * 24 * 60 * 60 * 1000,
  },
  YEAR: {
    name: 'YEAR',
    momentName: 'years',
    multiplier: 365, // We assume a year has 365 days
    toMillis: val => val * 365 * 24 * 60 * 60 * 1000,
  },
};
