import types from './types';

const defaultState = {
  notificationsIsLoading: false,
  allNotifications: [],
  selectedNotification: null,
  isLoadingNotificationFilters: false,
  isLoadingNotificationSources: false,
};

export default (
  state = defaultState,
  {
    type,

    /** takeQuery REQUEST action will provide this */
    params,

    /** takeQuery REQUEST_SUCCESS or REQUEST_FAILURE actions wil provide these */
    request /* using takeQuery: the initier action is set here */,
    response /* using takeQuery: query result will be set here */,
    data,
    /* other actions */
    ...action
  }
) => {
  switch (type) {
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notificationsIsLoading: false,
        allNotifications: data,
      };
    }
    case types.GET_NOTIFICATIONS_FAILURE: {
      return {
        ...defaultState,
        notificationsIsLoading: false,
      };
    }
    case types.GET_NOTIFICATIONS: {
      return {
        ...state,
        notificationsIsLoading: true,
      };
    }
    case types.NOTIFICATION_BY_ID: {
      return {
        ...state,
        notificationsIsLoading: true,
      };
    }
    case types.NOTIFICATION_BY_ID_SUCCESS: {
      return {
        ...state,
        notificationsIsLoading: false,
        selectedNotification: data,
      };
    }
    case types.NOTIFICATION_BY_ID_FAILURE: {
      return {
        ...state,
        notificationsIsLoading: false,
      };
    }
    case types.NOTIFICATIONS_BY_IDS: {
      return {
        ...state,
        notificationsIsLoading: true,
      };
    }
    case types.NOTIFICATIONS_BY_IDS_SUCCESS: {
      return {
        ...state,
        notificationsIsLoading: false,
        allNotifications: data,
      };
    }
    case types.NOTIFICATIONS_BY_IDS_FAILURE: {
      return {
        ...defaultState,
        notificationsIsLoading: false,
      };
    }
    case types.DELETE_NOTIFICATION_SUCCESS: {
      const { notificationId } = request.params;
      return {
        ...state,
        allNotifications: state.allNotifications.filter(n => n.id !== notificationId),
      };
    }
    case types.UPDATE_NOTIFICATION_ENABLE_SUCCESS: {
      const { notificationId, enable } = request.params;
      return {
        ...state,
        allNotifications: state.allNotifications.map(n =>
          n.id === notificationId ? { ...n, enabled: enable } : n
        ),
      };
    }
    case types.UPDATE_NOTIFICATION_BY_ID: {
      return {
        ...state,
        notificationsIsLoading: true,
      };
    }
    case types.UPDATE_NOTIFICATION_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedNotification: data,
        notificationsIsLoading: false,
      };
    }
    case types.UPDATE_NOTIFICATION_BY_ID_FAILURE: {
      return {
        ...state,
        notificationsIsLoading: false,
      };
    }
    case types.CREATE_NOTIFICATION: {
      return {
        ...state,
        notificationsIsLoading: true,
      };
    }
    case types.CREATE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        notificationsIsLoading: false,
      };
    }

    case types.SET_SELECTED_NOTIFICATION: {
      const { selectedNotification } = action;
      return {
        ...state,
        selectedNotification,
      };
    }

    default:
      return state;
  }
};
