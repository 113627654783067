export const keyCodes = {
  S_KEY: 83,
  Z_KEY: 90,
  Y_KEY: 89,
  F_KEY: 70,
  SUPR_KEY: 46,
  ADD_KEY: 107,
  ALL_KEY: 65,
  ARROW_LEFT: 37,
  ARROW_TOP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  SUPR: 46,
  ENTER: 13,
  C: 67,
  V: 86,
  X: 88,
};

export const fileEditionMode = {
  FILE: 'file',
  GRAPHIC: 'graphic',
};

export const defaultCanvasSize = {
  sizeX: 1004,
  sizeY: 803,
};

export const defaultGISScale = 16000;

export const pixelToMeter = 0.000264583333337192;

export const defaultWidgetsPosition = {
  posX: 0,
  posY: 0,
};

export const defaultLineEnd = {
  endX: 50,
  endY: 50,
};
