import { keyCodes } from '../../../../pages/RemusApplicationPage/constants';

export function removePixelUnits(elementToReformat, position) {
  return Number(elementToReformat?.style[position]?.split('px')[0]);
}

export function movePosition(objImage, pixels, direction) {
  objImage.style[direction] = `${parseInt(objImage.style[`${direction}`]) + pixels}px`;
}

export function validMovement(obj, elementDimensions, position, axis, selectedSynoptic) {
  if (axis === 'X') {
    if (position.x > 0) {
      if (obj.posX + position?.x + elementDimensions.width < selectedSynoptic.sizeX) {
        return true;
      }
    }
    if (position.x < 0) {
      if (obj.posX + position?.x > 0) {
        return true;
      }
    }
  } else if (axis === 'Y') {
    if (position.y > 0) {
      if (obj.posY + position?.y + elementDimensions.height < selectedSynoptic.sizeY) {
        return true;
      }
    }
    if (position.y < 0) {
      if (obj.posY + position?.y > 0) {
        return true;
      }
    }
  }

  return false;
}

export function getKeyAndMove(e, selectedWidgetsNotComplete, selectedSynoptic) {
  const keyCode = e.which || e.keyCode;
  selectedWidgetsNotComplete.forEach(widgetSelected => {
    const HTMLElement = document.getElementById(
      `id:*${widgetSelected?.id}*${widgetSelected?.type}*`
    );

    switch (keyCode) {
      case keyCodes.ARROW_LEFT:
        if (keyCode === keyCodes.ARROW_LEFT && removePixelUnits(HTMLElement, 'left') - 5 > 0) {
          movePosition(HTMLElement, -5, 'left');
        }
        break;
      case keyCodes.ARROW_TOP:
        if (keyCode === keyCodes.ARROW_TOP && removePixelUnits(HTMLElement, 'top') - 5 > 0) {
          movePosition(HTMLElement, -5, 'top');
        }
        break;
      case keyCodes.ARROW_RIGHT:
        if (
          removePixelUnits(HTMLElement, 'left') +
            (widgetSelected.width || HTMLElement.clientWidth) +
            5 <
          selectedSynoptic.sizeX
        ) {
          movePosition(HTMLElement, 5, 'left');
        }

        break;
      case keyCodes.ARROW_DOWN:
        if (
          removePixelUnits(HTMLElement, 'top') +
            (widgetSelected.height || HTMLElement.clientHeight) +
            5 <
          selectedSynoptic.sizeY
        ) {
          movePosition(HTMLElement, 5, 'top');
        }
        break;

      default:
        break;
    }
  });
}

export function onKeyUpEvent(event, selectedSynoptic, selectedWidgets, actions) {
  const keyCode = event.which || event.keyCode;
  if (
    (keyCode === keyCodes.ARROW_LEFT ||
      keyCode === keyCodes.ARROW_RIGHT ||
      keyCode === keyCodes.ARROW_TOP ||
      keyCode === keyCodes.ARROW_DOWN) &&
    selectedSynoptic?.id !== selectedWidgets[0]?.id &&
    selectedWidgets.length > 0
  ) {
    const arrayWithPositions = [];
    selectedWidgets.forEach(widgetSelected => {
      const HTMLElement = document.getElementById(
        `id:*${widgetSelected?.id}*${widgetSelected?.type}*`
      );
      arrayWithPositions.push({
        id: widgetSelected?.id,
        type: widgetSelected?.type,
        x: removePixelUnits(HTMLElement, 'left'),
        y: removePixelUnits(HTMLElement, 'top'),
      });
    });

    actions.recolocateAfterMovingWithArrows(arrayWithPositions);
  }
}

export function onKeyDownEvent(
  event,
  selectedSynoptic,
  selectedWidgets,
  setDeleteConfirmation,
  selectedWidgetsNotComplete
) {
  if (selectedWidgets.length > 0) {
    const { keyCode } = event;
    if (keyCode === keyCodes.SUPR) {
      setDeleteConfirmation(true);
    }

    if (
      (keyCode === keyCodes.ARROW_LEFT ||
        keyCode === keyCodes.ARROW_RIGHT ||
        keyCode === keyCodes.ARROW_TOP ||
        keyCode === keyCodes.ARROW_DOWN) &&
      selectedSynoptic?.id !== selectedWidgets[0]?.id
    ) {
      event.preventDefault();

      getKeyAndMove(event, selectedWidgetsNotComplete, selectedSynoptic);
    }
  }
}

export function isOutsideCanvas(widget, selectedSynoptic) {
  return (
    widget?.posX > selectedSynoptic?.sizeX ||
    widget?.posX < 0 ||
    widget?.posY > selectedSynoptic?.sizeY ||
    widget?.posY < 0 ||
    widget?.startX > selectedSynoptic?.sizeX ||
    widget?.startX < 0 ||
    widget?.startY > selectedSynoptic?.sizeY ||
    widget?.startY < 0 ||
    widget?.endX > selectedSynoptic?.sizeX ||
    widget?.endX < 0 ||
    widget?.endY > selectedSynoptic?.sizeY ||
    widget?.endY < 0 ||
    widget?.targetX > selectedSynoptic?.sizeX ||
    widget?.targetX < 0 ||
    widget?.targetY > selectedSynoptic?.sizeY ||
    widget?.targetY < 0
  );
}