export const UPDATE_SCREEN_WIDTH = 'app/general/UPDATE_SCREEN_WIDTH';

export const TOGGLE_SIDEBAR = 'app/general/TOGGLE_SIDEBAR';
export const OPEN_SIDEBAR = 'app/general/OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'app/general/CLOSE_SIDEBAR';

export default {
  UPDATE_SCREEN_WIDTH,
  TOGGLE_SIDEBAR,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR
};
