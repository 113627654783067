import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import {
  filteringENTrans,
  filteringFRTrans
} from 'hse-filtering-library-frontend';
import messagesEn from './translations/messages_en.json';

const resources = {
  en: {
    translation: messagesEn,
    filtering: filteringENTrans
  },
  fr: {
    filtering: filteringFRTrans
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export const t = key => i18n.t(key);

export default i18n;
