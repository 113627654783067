export const GET_RECENT_HEATMAP = 'app/heatmaps/GET_RECENT_HEATMAP';
export const GET_RECENT_HEATMAP_SUCCESS = `${GET_RECENT_HEATMAP}/SUCCESS`;
export const GET_RECENT_HEATMAP_FAILURE = `${GET_RECENT_HEATMAP}/FAILURE`;

export const GET_HISTORICAL_HEATMAP = 'app/heatmaps/GET_HISTORICAL_HEATMAP';
export const GET_HISTORICAL_HEATMAP_SUCCESS = `${GET_HISTORICAL_HEATMAP}/SUCCESS`;
export const GET_HISTORICAL_HEATMAP_FAILURE = `${GET_HISTORICAL_HEATMAP}/FAILURE`;

export default {
  GET_RECENT_HEATMAP,
  GET_RECENT_HEATMAP_SUCCESS,
  GET_RECENT_HEATMAP_FAILURE,

  GET_HISTORICAL_HEATMAP,
  GET_HISTORICAL_HEATMAP_SUCCESS,
  GET_HISTORICAL_HEATMAP_FAILURE
};
