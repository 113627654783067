import React from 'react';
import {Divider, Icon, Menu, Sidebar} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  AdminHome,
  Alarms,
  ApplicationEditorRoot,
  DashboardsRoot,
  MonitoringGroups,
  NotificationList,
  PlatformRoot,
  ReportingHome,
  StatisticsHome,
  Trends,
} from '../routes';
import {initSelectors} from '../behavior/init';
import usePlatform from '../hooks/usePlatform';
import useUser from '../hooks/useUser';
import generalActions from '../behavior/general/actions';
import useIsMobileDisplay from '../hooks/useIsMobileDisplay';
import {useActions} from '../hooks/useActions';
import useIsApplicationAdmin
  from '../behavior/applicationEditor/hooks/permissions/useIsApplicationAdmin';

const NavSidebar = () => {
  const platform = usePlatform();
  const user = useUser();
  const sidebar = useSelector(state => state.general.sidebar);
  const isMobileDisplay = useIsMobileDisplay();
  const actions = useActions(generalActions);
  const isApplicationAdmin = useIsApplicationAdmin();

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      vertical
      visible={sidebar}
      width="thin"
      id="app-main-sidebar"
    >
      {[
        {
          id: 'sidebar-element-home',
          key: 'sidebar-element-home',
          to: PlatformRoot.getLink({ platform }, null, false),
          icon: 'home',
          exact: true,
          text: 'Home',
        },
        {
          id: 'sidebar-element-trends',
          key: 'sidebar-element-trends',
          to: Trends.getLink({ platform }, null, false),
          icon: 'chart area',
          text: 'Trends',
        },
        {
          id: 'sidebar-element-alarms',
          key: 'sidebar-element-alarms',
          to: Alarms.getLink({ platform }, null, false),
          icon: 'exclamation triangle',
          text: 'Alarms',
        },
        {
          id: 'sidebar-element-dashboards',
          key: 'sidebar-element-dashboards',
          to: DashboardsRoot.getLink({ platform }, null, false),
          icon: 'dashboard',
          text: 'Dashboards',
        },
        {
          id: 'sidebar-element-statistics',
          key: 'sidebar-element-statistics',
          to: StatisticsHome.getLink({ platform }, null, false),
          icon: 'pie graph',
          text: 'Statistics',
        },
        {
          id: 'sidebar-element-reporting',
          key: 'sidebar-element-reporting',
          to: ReportingHome.getLink({ platform }, null, false),
          icon: 'file alternate',
          text: 'Reporting',
        },
        {
          id: 'sidebar-element-notifications',
          key: 'sidebar-element-notifications',
          to: NotificationList.getLink({ platform }, null, false),
          icon: 'bell',
          text: 'Notifications',
        },
        {
          id: 'sidebar-element-monitoring-groups',
          key: 'sidebar-element-monitoring-groups',
          to: MonitoringGroups.getLink({ platform }, null, false),
          icon: 'list',
          text: 'Monitoring Groups',
        },
        {
          id: 'sidebar-element-application-editor',
          key: 'sidebar-element-application-editor',
          to: ApplicationEditorRoot.getLink({ platform }, null, false),
          text: 'Application Editor',
          icon: 'pencil',
          visible: isApplicationAdmin,
        },
        {
          id: 'sidebar-element-admin',
          key: 'sidebar-element-admin',
          to: AdminHome.getLink({ platform }, null, false),
          icon: 'cogs',
          text: 'Admin',
          visible: initSelectors.isAdmin(user),
        },
        { divider: true, key: 'divider', text: 'External links' },
        {
          id: 'sidebar-element-grafana',
          key: 'sidebar-element-grafana',
          onClick: () =>
            window.open(
              `https://remus${platform === 'TEST' ? '-test' : ''}-grafana.web.cern.ch`,
              '_blank'
            ),
          text: 'Grafana',
          icon: 'google',
        },
        {
          id: 'sidebar-element-rog',
          key: 'sidebar-element-rog',
          onClick: () => {
            window.open('https://rog.cern.ch', '_blank');
          },
          text: 'ROG',
          icon: 'window maximize',
        },
        {
          id: 'sidebar-element-timber',
          key: 'sidebar-element-timber',
          onClick: () => {
            window.open('https://timber.cern.ch', '_blank');
          },
          text: 'Timber',
          icon: 'tm',
        },
        {
          id: 'sidebar-element-helpalarm',
          key: 'sidebar-element-helpalarm',
          onClick: () => {
            window.open('https://helpalarm.cern.ch', '_blank');
          },
          text: 'HelpAlarm',
          icon: 'h',
        },
      ].map(({
        divider, to, key, onClick = () => {
        }, exact, icon, text, visible, id
      }) => {
        if (divider) {
          return <Divider key={key} />;
        }
        if (visible !== undefined && !visible) {
          return null;
        }
        return (
          <Menu.Item
            id={id}
            {...(to
              ? {
                to,
                as: NavLink,
              }
              : {})}
            onClick={args => {
              if (isMobileDisplay) {
                actions.toggleSidebar();
              }
              onClick(args);
            }}
            key={key || to}
            end={exact}
          >
            <Icon name={icon} />
            {sidebar ? text : null}
          </Menu.Item>
        );
      })}
    </Sidebar>
  );
};

export default NavSidebar;
