import { getAPI } from '../../api/agent';

const RemusApplicationsAPI = {
  getAllRemusApplications: ({ page, size }) =>
    getAPI().get('remusApplications', false, { page, size }),
  getRemusApplicationById: id => getAPI().get(`remusApplications/${id}`),
  deleteRemusApplication: id => getAPI().delete(`remusApplications/delete/${id}`),

  saveRemusApplication: remusApplication =>
    getAPI().post('remusApplications/save/', remusApplication),

  updateRemusApplication: ({ remusApplication }) =>
    getAPI().patch(`remusApplications`, remusApplication),

  createRemusApplication: ({ remusApplication }) =>
    getAPI().post('remusApplications/create', remusApplication),

  getAllApplicationsById: ({ id, pagination }) =>
    getAPI().get('remusApplications/versions', false, { id, ...pagination }),

  getRemusApplicationByVersionId: id => getAPI().get(`remusApplications/version/${id}`),

  isApplicationAdmin: () => getAPI().get('remusApplicationPermissions/isApplicationAdmin'),

  canEditApplication: applicationId =>
    getAPI().get(`remusApplicationPermissions/edit/${applicationId}`),
};

export default RemusApplicationsAPI;
