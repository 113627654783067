import types from './types';
import { convertHeatmapDataToLocalDate } from './selectors';

const defaultState = {
  isLoading: false,
  isLoadingDT: false,
  timeSerie: [],
  timeSerieDT: [],
  managerSerie: [],
  managerSerieDT: [],
  lastDateEnd: null,
  lastDateEndDT: null,
  dataNxcals: [],
  dataInflux: [],
  dataByType: [],
};

export default (state = defaultState, { type, data: responseData }) => {
  switch (type) {
    case types.GET_DATA_EXPORT_NXCALS: {
      return {
        ...state,
        dataNxcals: [],
        isLoading: true,
      };
    }
    case types.GET_DATA_EXPORT_NXCALS_SUCCESS: {
      const parsedData = convertHeatmapDataToLocalDate(responseData);
      const [timeSerie, managerSerie, lastDateEnd, dataNxcals] = parsedData;
      return {
        ...state,
        isLoading: false,
        timeSerie,
        managerSerie,
        lastDateEnd,
        dataNxcals,
      };
    }

    case types.GET_DATA_EXPORT_INFLUX: {
      return {
        ...state,
        dataInflux: [],
        isLoading: true,
      };
    }
    case types.GET_DATA_EXPORT_INFLUX_SUCCESS: {
      const parsedData = convertHeatmapDataToLocalDate(responseData);
      const [timeSerie, managerSerie, lastDateEnd, dataInflux] = parsedData;
      return {
        ...state,
        isLoading: false,
        timeSerie,
        managerSerie,
        lastDateEnd,
        dataInflux,
      };
    }
    case types.GET_DATA_EXPORT_INFLUX_FAILURE: {
      return {
        ...defaultState,
      };
    }
    case types.GET_DATA_BY_TYPE: {
      return {
        ...state,
        dataByType: [],
        isLoadingDT: true,
      };
    }
    case types.GET_DATA_BY_TYPE_SUCCESS: {
      const parsedData = convertHeatmapDataToLocalDate(responseData);
      const [timeSerieDT, managerSerieDT, lastDateEndDT, dataByType] = parsedData;
      return {
        ...state,
        isLoadingDT: false,
        timeSerieDT,
        managerSerieDT,
        lastDateEndDT,
        dataByType,
      };
    }
    case types.GET_DATA_BY_TYPE_FAILURE: {
      return {
        ...defaultState,
      };
    }
    default:
      return state;
  }
};
