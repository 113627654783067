import { useQuery } from 'react-query';
import usePlatform from '../../../../hooks/usePlatform';
import RemusApplicationsAPI from '../../api';

function useIsApplicationAdmin() {
  const platform = usePlatform();

  const response = useQuery(['isApplicationAdmin', platform], () =>
    RemusApplicationsAPI.isApplicationAdmin().then(res => res.data)
  );

  return response.data ?? false;
}

export default useIsApplicationAdmin;
