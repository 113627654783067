import React from 'react';
import { Dimmer, Image } from 'semantic-ui-react';

const LoadingComponent = () => (
  <Dimmer active>
    <Image
      className="fx-pulse"
      src={`${process.env.PUBLIC_URL}/assets/imgs/remus_icon_256x256.png`}
      size="small"
    />
  </Dimmer>
);

export default LoadingComponent;
